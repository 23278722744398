import { ENTITY_LEVELS } from '../constants';
import { buildEntityFlatTree } from './entities';
import { resolveFormFieldValues } from './formFieldDefinition';
import { getPageFromUrl, cleanObject, getQueryStringFromUrl } from '../helpers';
import moment from 'moment';
/**
 * IEquipment interface.
 * @typedef {Object} IEquipment
 *
 * @property {String} id -  .
 * @property {string} tagId -
 * @property {string} inventoryNumber -
 * @property {string} comment -
 * @property {Boolean} archived -
 * @property {Boolean} hasControls -
 * @property {number} controlFrequency -
 * @property {array} values -
 * @property {import('./entities').IEntity} entity -
 * @property {import('./families').IEquipmentType} type -
 */

export function toEquipmentShape(baseInfo, custom, upload, templates = []) {
  return new Promise(function(resolve, reject) {
    const equipment = {
      ...cleanObject(baseInfo, [
        'controls',
        'lastControl',
        'canControl',
        'hasControl'
      ]),
      family: undefined,
      entity: { id: baseInfo.entity[ENTITY_LEVELS.LEVEL_3.value] },
      type: { id: baseInfo.type }
    };
    const values = resolveFormFieldValues(templates, custom, upload);

    Promise.all(values)
      .then(data => {
        resolve({ ...equipment, values: data });
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function resolveEquipmentBaseInfos(rawEquipment, entities) {
  const entityTree = buildEntityFlatTree(rawEquipment.entity, entities);
  return {
    ...rawEquipment,
    entity: entityTree
  };
}

/**
 * ISearchQuery interface.
 * @typedef {Object} ISearchQuery
 *
 * @property {number} page -  .
 * @property {number} offset -  .
 * @property {object} body -  .
 */

/**
 *
 * @param {import('../modules/initialState').IInitialState} state
 * @param {object} extra
 *
 * @returns {ISearchQuery}
 */
export function getSearchQuery(state, extra, limit) {
  const {
    ui,
    filters,
    router,
    auth: { user }
  } = state;
  const offset = ui.itemPerPage !== limit ? ui.itemPerPage : limit;

  const userFilters = filters[user.id];
  if (!userFilters.level3) userFilters.level3 = [];
  if (!userFilters.type) userFilters.type = [];

  return {
    body: {
      ...cleanObject(
        userFilters,
        ['type', 'archived', 'level3', 'from', 'to'],
        false
      ),
      ...extra,
      query: resovleWordQuery(userFilters),
      sort: state.filters.sort
    },
    page: getPageFromUrl(router.location),
    offset
  };
}

export function resovleWordQuery(filters) {
  return filters.query
    ? filters.query.reduce((query, tag) => {
        query.push({
          key: tag.attribute.value,
          word: tag.query,
          from: tag.from,
          to: tag.to,
          exact: tag.exact
        });
        return query;
      }, [])
    : [];
}

export function generatePdfFileName() {
  const currentDate = moment().format('YYYYMMDD_HH:mm:ss');

  return `Rapport_Biotrace_${currentDate}.pdf`;
}

export function generateXLSFileName() {
  const currentdate = moment().format('YYYYMMDD_HH:mm');

  return `Export_Biotrace_${currentdate}.xlsx`;
}

/**
 *
 * @param {string} pathname
 */
export function generateCloneEquipmentURL(pathname, equipment) {
  return `${pathname}?family=${equipment.family}&type=${equipment.type}&2=${
    equipment.entity['2']
  }&3=${equipment.entity['3']}&4=${equipment.entity['4']}`;
}

export function extractClonedEquipmentFromUrl(search) {
  const query = getQueryStringFromUrl(search);
  return {
    family: query.family,
    type: query.type,
    entity: {
      '2': query['2'],
      '3': query['3'],
      '4': query['4']
    }
  };
}
