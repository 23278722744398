import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchPermissions } from '../../modules/permissions/action';
import { isEmpty } from '../../helpers';

const withPermissions = WrappedComponent => {
  class withPermissionsHOC extends PureComponent {
    static displayName = `withPermissions(${WrappedComponent.displayName ||
      WrappedComponent.name})`;

    render() {
      const { permissions, fetchPermissions, ...rest } = this.props;
      if (isEmpty(Object.keys(permissions.data.raw))) {
        return null;
      }
      return (
        <WrappedComponent
          {...rest}
          fetchPermissions={fetchPermissions}
          permissions={permissions.data}
        />
      );
    }
  }
  const mapStateToProps = state => ({ permissions: state.permissions });
  const mapDispatchToProps = { fetchPermissions };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPermissionsHOC);
};

export default withPermissions;
