/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { SENTRY_DSN } from '../../../constants';
import * as Sentry from '@sentry/browser';
const isNotDev = process.env.NODE_ENV !== 'development';
if (isNotDev) {
  Sentry.init({
    dsn: SENTRY_DSN
  });
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    // window.location.href = '/fr/dashboard'
    if (isNotDev) {
      console.log('SHOULD REPORT THIS ERROR', error);
      console.log('INFO', errorInfo);
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
        Sentry && Sentry.showReportDialog();
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="five-zero-zero">
          <div className="five-zero-zero-container">
            <div className="error-code">500</div>
            <div className="error-message">Internal Server Error</div>

            <div className="button-place m-b-20">
              <a href="/" className="btn btn-default btn-lg waves-effect">
                GO TO HOMEPAGE
              </a>
            </div>
            <a
              className="m-t-50 col-red"
              style={{ cursor: 'pointer' }}
              onClick={() => Sentry && Sentry.showReportDialog()}
            >
              Report feedback
            </a>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
