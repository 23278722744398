import deepmerge from 'deepmerge';
import {
  MULTI_CHECKBOX,
  RADIO_BOX,
  SELECT_INPUT,
  TEXT_INPUT,
  FILE,
  TEXTAREA,
  DATE_INPUT,
  MULTIPLE_OPTION_BUILDER,
  INPUT_BUILDER,
  FILE_TYPES
} from '../components/builder/builderUtils';
import { t } from '../services/i18n';

/**
 *
 * @param {MULTI_CHECKBOX|RADIO_BOX|SELECT_INPUT|TEXT_INPUT|FILE|TEXTAREA} type
 * @param {Object} options
 *
 */
export const createFieldByType = (type, options) => {
  const field = FIELD_TYPES()[type];
  if (field.uiSchema && field.uiSchema.options) {
    field.uiSchema.options = [];
  }
  return deepmerge(field, options);
};

export const FIELD_TYPES = () => ({
  [TEXT_INPUT]: {
    fieldSchema: {
      type: INPUT_BUILDER,
      icon: 'MdShortText',
      label: t('form_builder.field_types.short_answer'),
      description: t('form_builder.field_types.short_answer')
    },
    uiSchema: {
      type: TEXT_INPUT,
      required: false,
      format: 'text',
      label: 'Question',
      fieldClassname: 'form-control',
      placeholder: t('form_builder.your_answer'),
      defaultValue: '',
      validator: {
        required: {
          error: t('form_validation.required_field')
        },
        email: {
          error: t('form_validation.invalid_mail')
        }
      }
    }
  },
  [TEXTAREA]: {
    fieldSchema: {
      type: INPUT_BUILDER,
      icon: 'MdDehaze',
      label: t('form_builder.field_types.long_answer'),
      description: t('form_builder.field_types.long_answer')
    },
    uiSchema: {
      type: TEXTAREA,
      required: false,
      label: 'Question',
      fieldClassname: 'form-control',
      placeholder: t('form_builder.your_answer'),
      validator: {
        required: {
          error: t('form_validation.required_field')
        }
        // wordLimit: {
        //   error: 'this field must be 100 words at most.'
        // }
      }
      // wordLimit: 100
    }
  },
  [MULTI_CHECKBOX]: {
    fieldSchema: {
      type: MULTIPLE_OPTION_BUILDER,
      icon: 'MdCheckBox',
      optionIcon: 'MdCheckBoxOutlineBlank',
      label: t('form_builder.field_types.checkboxes'),
      description: t('form_builder.field_types.checkboxes')
    },
    uiSchema: {
      type: MULTI_CHECKBOX,
      required: false,
      label: 'Question',
      options: [
        { value: 'Option1', key: 1 },
        { value: 'Option2', key: 2 },
        { value: 'Option3', key: 3 }
      ],
      validator: {
        required: {
          error: t('form_validation.required_field')
        }
      },
      metered: false,
      uniqueItems: true,
      multiple: true
    }
  },
  [RADIO_BOX]: {
    fieldSchema: {
      type: MULTIPLE_OPTION_BUILDER,
      label: t('form_builder.field_types.radioboxes'),
      description: t('form_builder.field_types.radioboxes'),
      icon: 'MdRadioButtonChecked',
      optionIcon: 'MdRadioButtonUnchecked'
    },
    uiSchema: {
      type: RADIO_BOX,
      required: false,
      label: 'Question',
      options: [
        { value: 'Option1', key: 1 },
        { value: 'Option2', key: 2 },
        { value: 'Option3', key: 3 }
      ],
      validator: {
        required: {
          error: t('form_validation.required_field')
        }
      }
    }
  },
  [SELECT_INPUT]: {
    fieldSchema: {
      type: MULTIPLE_OPTION_BUILDER,
      label: t('form_builder.field_types.dropdown'),
      description: t('form_builder.field_types.dropdown'),
      icon: 'MdArrowDropDownCircle',
      optionIcon: 'MdArrowDropDownCircle'
    },
    uiSchema: {
      type: SELECT_INPUT,
      required: false,
      multiple: false,
      fieldClassname: 'form-control show-tick',
      label: 'Question',
      defaultValue: '',
      options: [
        { value: 'Option1', key: 1 },
        { value: 'Option2', key: 2 },
        { value: 'Option3', key: 3 }
      ],
      validator: {
        required: {
          error: t('form_validation.required_field')
        }
      }
    }
  },
  [DATE_INPUT]: {
    fieldSchema: {
      label: t('form_builder.field_types.date'),
      description: t('form_builder.field_types.date'),
      icon: 'MdDateRange',
      format: 'date',
      type: INPUT_BUILDER
    },
    uiSchema: {
      type: DATE_INPUT,
      required: false,
      format: 'date',
      fieldClassname: 'form-control',
      label: 'Question',
      defaultValue: '',
      validator: {
        required: {
          error: t('form_validation.required_field')
        }
      }
    }
  },
  [FILE]: {
    fieldSchema: {
      type: INPUT_BUILDER,
      label: t('form_builder.field_types.file'),
      description: t('form_builder.field_types.file'),
      icon: 'MdFileUpload',
      format: 'file'
    },
    uiSchema: {
      type: FILE,
      required: false,
      format: 'file',
      fileType: FILE_TYPES()[0].id,
      fieldClassname: 'form-control',
      label: 'Question',
      validator: {
        required: {
          error: t('form_validation.required_field')
        }
      }
    }
  }
});
