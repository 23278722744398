import React from 'react';
import PropTypes from 'prop-types';
import Card, { CardBody } from '../shared/Card';
import ColorfulSpinner from '../shared/ColorfulSpinner';
import { t } from '../../services/i18n';
import LocalizedLink from '../shared/LocalizedLink';
import Input from '../shared/Input';
import Button from '../shared/Button';
import { Col, Row } from '../shared/Grid';
import classNames from 'classnames';
import { MdPerson } from 'react-icons/md';

class ResetPasswordForm extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool
  };

  constructor(...args) {
    super(...args);
    this.state = {
      credentials: { username: '' }
    };
  }

  onSubmit = event => {
    event.preventDefault();
    const { credentials } = this.state;
    this.props.onSubmit({
      ...credentials,
      username: credentials.username.toLowerCase()
    });
  };

  onChange = ({ target }) => {
    this.setState(prevState => ({
      credentials: { ...prevState.credentials, [target.name]: target.value }
    }));
  };

  get errorMessage() {
    const { message, hasError } = this.props;
    if (message) {
      return (
        <div
          className={classNames({
            'col-red': hasError,
            'col-green': !hasError
          })}
        >
          {message}
        </div>
      );
    }
    return null;
  }

  render() {
    const { isSubmitting } = this.props;
    return (
      <Card>
        <CardBody>
          <form onSubmit={this.onSubmit}>
            <div className="msg align-center">
              <div style={{ textTransform: 'uppercase' }}>
                {t('reset_page.title')}
              </div>
              {this.errorMessage}
            </div>
            <Input
              type="email"
              name="username"
              placeholder={t('reset_page.email_placeholder')}
              onChange={this.onChange}
              wrapperClass="input-group"
              autoComplete="username"
              icon={
                <span className="input-group-addon">
                  <MdPerson size={24} />
                </span>
              }
              hasError={!!this.errorMessage}
              required
              autoFocus
            />

            <Row>
              <Col>
                <Button
                  className="btn-block bg-pink"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t('reset_page.submit_btn_label')}
                </Button>
              </Col>
            </Row>
            {isSubmitting && (
              <Row className="text-center m-t-15">
                <ColorfulSpinner />
              </Row>
            )}
            <hr />
            <div className="align-center">
              <LocalizedLink to={'/login'}>
                {t('reset_page.login_link')}
              </LocalizedLink>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default ResetPasswordForm;
