import React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from '../../constants';
import classNames from 'classnames';

export class Card extends React.PureComponent {
  static Header = ({
    backgroundColor = CONSTANTS.THEME.LIGHT.BACKGROUND,
    fontColor = CONSTANTS.THEME.LIGHT.FONT,
    className,
    children,
    ...rest
  }) => {
    return (
      <div
        className={classNames(
          `header bg-${backgroundColor} col-${fontColor}`,
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  };

  static Body = ({
    backgroundColor = CONSTANTS.THEME.LIGHT.BACKGROUND,
    fontColor = CONSTANTS.THEME.LIGHT.FONT,
    className,
    children,
    ...rest
  }) => {
    return (
      <div
        className={classNames(
          `body bg-${backgroundColor} col-${fontColor}`,
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  };

  static Footer = ({
    backgroundColor = CONSTANTS.THEME.LIGHT.BACKGROUND,
    fontColor = CONSTANTS.THEME.LIGHT.FONT,
    children,
    ...rest
  }) => {
    return (
      <div
        className={`card-footer bg-${backgroundColor} col-${fontColor}`}
        {...rest}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      children,
      height,
      style,
      className,
      backgroundColor,
      fontColor,
      ...rest
    } = this.props;
    const cardStyle = {
      height,
      wordWrap: 'break-word',
      ...style
    };

    return (
      <div
        className={classNames(
          `card bg-${backgroundColor} col-${fontColor}`,
          className
        )}
        style={cardStyle}
        {...rest}
      >
        {children}
      </div>
    );
  }
}
Card.propTypes = {
  height: PropTypes.string,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string
};

Card.defaultProps = {
  backgroundColor: CONSTANTS.THEME.LIGHT.BACKGROUND,
  fontColor: CONSTANTS.THEME.LIGHT.FONT
};

export class CardHeader extends React.PureComponent {
  render() {
    const {
      backgroundColor,
      fontColor,
      className,
      children,
      ...rest
    } = this.props;
    return (
      <div
        className={classNames(
          `header bg-${backgroundColor} col-${fontColor}`,
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
}

CardHeader.propTypes = {
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string
};
CardHeader.defaultProps = {
  backgroundColor: CONSTANTS.THEME.LIGHT.BACKGROUND,
  fontColor: CONSTANTS.THEME.LIGHT.FONT
};

export class CardBody extends React.PureComponent {
  render() {
    const {
      backgroundColor,
      fontColor,
      className,
      children,
      ...rest
    } = this.props;
    return (
      <div
        className={classNames(
          `body bg-${backgroundColor} col-${fontColor}`,
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
}
CardBody.propTypes = {
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string
};

CardBody.defaultProps = {
  backgroundColor: CONSTANTS.THEME.LIGHT.BACKGROUND,
  fontColor: CONSTANTS.THEME.LIGHT.FONT
};

export class CardFooter extends React.PureComponent {
  render() {
    const { backgroundColor, fontColor, children, ...rest } = this.props;
    return (
      <div
        className={`card-footer bg-${backgroundColor} col-${fontColor}`}
        {...rest}
      >
        {children}
      </div>
    );
  }
}
CardFooter.propTypes = {
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string
};

CardFooter.defaultProps = {
  backgroundColor: CONSTANTS.THEME.LIGHT.BACKGROUND,
  fontColor: CONSTANTS.THEME.LIGHT.FONT
};

export default Card;
