import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SignUpForm from '../../components/auth/SignUpForm';
import AuthLayout from './AuthLayout';
import { Card, CardBody } from '../../components/shared/Card';
import { showNotification } from '../../helpers/notification';
import { register } from '../../modules/auth/action';
import { getCurrentToken } from '../../services/auth';
import { prefixPath } from '../../helpers';
import { LocalizerContext } from '../../components/hoc/Localizer';
import { createNewOwner } from '../../services/users';

class SignUp extends React.Component {
  static contextType = LocalizerContext;

  componentDidMount = () => {
    const isConnected = !!getCurrentToken();
    if (isConnected) {
      const locale = this.context;
      this.props.history.push(prefixPath('/dashboard', locale.lang));
    }
  };

  onSubmit = (credentials, captachaToken) => {
    const locale = this.context;
    const body = createNewOwner(credentials, captachaToken);
    return this.props.register(body).then(() => {
      const {
        auth: { message, error, authObj },
        history
      } = this.props;
      showNotification(message, { error });
      if (!error && authObj) {
        history.push(
          locale.localizePath(`/login?token=${authObj.refresh_token}`)
        );
      } else {
        throw new Error();
      }
    });
  };

  render() {
    const { auth } = this.props;
    return (
      <AuthLayout>
        <Card>
          <CardBody>
            <SignUpForm
              onSubmit={this.onSubmit}
              message={auth.message}
              isLoggingIn={auth.isLoggingIn}
            />
          </CardBody>
        </Card>
      </AuthLayout>
    );
  }
}

SignUp.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ register }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
