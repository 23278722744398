// import 'sanitize.css/sanitize.css/';
/* stylesheets Start */
// Bootstrap Core Css
import './assets/plugins/bootstrap/css/bootstrap.css';
// Waves Effect Css
import './assets/plugins/node-waves/waves.min.css';
// Animation Css
import './assets/plugins/animate-css/animate.min.css';

// Custom Css
import './assets/css/style.scss';
import('./assets/css/custom.scss');
import('./index.scss');

/* stylesheets end */
// import './assets/plugins/bootstrap/css/bootstrap.css';
import('./assets/css/themes/theme-black.min.css');
