import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';

export default (state = initialState.users, action) => {
  switch (action.type) {
    case types.CHECK_EMAIL_REQUEST:
      return {
        ...state,
        isCheckingEmail: true,
        canUseEmail: null
      };
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: false,
        message: null
      };
    case types.FETCH_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: null
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        error: false,
        message: action.message || t('default_success_message')
      };
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        meta: {
          ...state.meta,
          ...action.meta
        },
        data: {
          ...state.data,
          list: action.payload
        },
        message: action.message || t('default_success_message')
      };
    case types.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckingEmail: false,
        data: {
          ...state.data,
          canUseEmail: action.payload
        }
      };
    case types.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        isCheckingEmail: false,
        canUseEmail: false
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: true,
        isSaving: false,
        message: action.message || t('default_error_message')
      };
    case types.FETCH_USERS_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
        message: action.message || t('default_error_message')
      };
    case types.GET_USER_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          isLoading: true
        }
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          isLoading: false,
          data: action.payload
        }
      };
    case types.GET_USER_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          isLoading: false,
          error: true,
          message: action.message || t('default_error_message')
        }
      };

    default:
      return state;
  }
};
