import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';

export default (state = initialState.shared, action) => {
  switch (action.type) {
    case types.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: false,
        message: null
      };
    case types.READ_FILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: null
      };
    case types.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        data: action.payload,
        message: action.message || t('default_success_message')
      };
    case types.READ_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.message || t('default_success_message')
      };
    case types.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: true,
        message: action.message || t('default_error_message')
      };
    case types.READ_FILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: action.message || t('default_error_message')
      };
    case types.LOAD_COUNTRIES_REQUEST:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: true
        }
      };
    case types.LOAD_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          ...state.countries,
          data: action.payload,
          isLoading: false
        }
      };
    case types.LOAD_COUNTRIES_FAILURE:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false
        }
      };

    default:
      return state;
  }
};
