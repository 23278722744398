import * as types from '../../constants/actionTypes';

export const setTranslation = (lang, translations) => {
  return dispatch => {
    dispatch({
      type: types.SET_TRANSLATION,
      payload: { lang, translations }
    });
  };
};
