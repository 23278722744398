export const mapIntoObject = (arr, idProperty = 'id') => {
  return arr.reduce((acc, curr) => {
    acc[curr[idProperty]] = curr;
    return acc;
  }, {});
};

export const mapToPieChartDataset = (arr, labelProperty, dataProperty) => {
  return arr.reduce((acc, curr) => {
    if (typeof acc.labels === 'undefined') acc.labels = [];
    if (typeof acc.data === 'undefined') acc.data = [];

    acc.labels.push(curr[labelProperty]);
    acc.data.push(curr[dataProperty]);
    return acc;
  }, {});
};

/**
 *
 * @param {Array} arr
 * @param {String} labelProperty
 * @param {String} valueProperty
 * @param {String} keyProperty
 *
 * @returns {Array}
 */
export const mapIntoSelect = (
  arr,
  labelProperty = 'label',
  valueProperty = 'value',
  keyProperty = 'id',
  disabledOptions = []
) => {
  return arr.reduce((acc, curr) => {
    let option = {};

    option.label =
      typeof labelProperty === 'function'
        ? labelProperty(curr)
        : curr[labelProperty];
    option.value =
      typeof valueProperty === 'function'
        ? valueProperty(curr)
        : curr[valueProperty];
    option.key =
      typeof keyProperty === 'function' ? keyProperty(curr) : curr[keyProperty];
    if (disabledOptions.includes(curr[valueProperty])) {
      option.disabled = true;
    }
    acc.push(option);
    return acc;
  }, []);
};

export const mapIntoSelectForHash = arr => {
  return arr.map((item, index) => {
    return { label: item, value: item, key: index };
  });
};
