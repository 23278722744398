import moment from 'moment';
import * as _groupBy from 'lodash/groupBy';
import toPath from 'lodash/toPath';
import cloneDeep from 'lodash/cloneDeep';
import trim from 'lodash/trim';
import {
  DATES,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_DOCUMENT_FORMATS
} from '../constants';
import { t } from '../services/i18n';

export function prefixPath(path, prefix) {
  return `/${prefix}/${trim(path, '/')}`;
}

/**
 * @returns {String}
 */
export function getFirstBrowserLanguage() {
  try {
    let nav = window.navigator,
      browserLanguagePropertyKeys = [
        'language',
        'browserLanguage',
        'systemLanguage',
        'userLanguage'
      ],
      i,
      language,
      len,
      shortLanguage = null;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages) && nav.languages.length) {
      return getLocaleFromLanguage(nav.languages[0]);
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      len = language.length;
      if (!shortLanguage && len) {
        shortLanguage = language;
      }
      if (language && len > 2) {
        return getLocaleFromLanguage(language);
      }
    }

    return getLocaleFromLanguage(shortLanguage);
  } catch (error) {
    console.error('DETECTING LANGUAGE FAIL', error);
    return 'fr';
  }
}

/**
 *
 * @param {String} language
 */
export function getLocaleFromLanguage(language) {
  if (language.includes('fr')) {
    return 'fr';
  } else if (language.includes('en')) {
    return 'en';
  } else if (language.includes('de')) {
    return 'de';
  } else {
    return 'fr';
  }
}

export function substringText(text = '', max = 20, more = '...') {
  return text.length > max ? text.substring(0, max) + more : text;
}

/**
 *
 * @param {Object} obj
 * @param {Array} keys
 * @param {Boolean} blacklisted
 */
export function cleanObject(obj, keys, blacklisted = true) {
  if (blacklisted) {
    return keys.reduce(
      (cleanObj, blackListedKey) => {
        delete cleanObj[blackListedKey];
        return cleanObj;
      },
      { ...obj }
    );
  } else {
    return keys.reduce((cleanObj, whiteListedKey) => {
      if (obj[whiteListedKey] !== undefined) {
        cleanObj[whiteListedKey] = obj[whiteListedKey];
      }
      return cleanObj;
    }, {});
  }
}

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};

export const getRandomNumberByRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getQueryStringFromUrl = query => {
  if (!query) {
    return {};
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      let [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
};

export const getQueryStringByParam = (query, field) =>
  query
    .substr(1)
    .split(/&/g)
    .map(x => x.split(/^([^=]*)=/).slice(1))
    .filter(x => x[0] === field)[0][1];

export const capitalize = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @returns {Array}
 */
export const domNodesToArray = nodes => {
  return Array.prototype.slice
    .call(nodes)
    .filter(option => option.value !== '')
    .map(option => option.value);
};

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const precisionRound = (number, precision = 1) => {
  let factor = Math.pow(10, precision);
  const value = Math.round(number * factor) / factor;
  return isNaN(value) ? 0 : value;
};

/**
 *
 * @param {Date} date1
 * @param {Date} date2
 *
 * @returns {Boolean}
 */
export const isAfter = (date1, date2, unit = 'days') => {
  return moment(date1).isAfter(moment(date2), unit);
};

export const sortByDate = (date1, date2, format = DATES.fr.LONG) => {
  const dateA = moment(date1, format);
  const dateB = moment(date2, format);
  if (dateA.isAfter(dateB)) return 1;
  if (dateA.isBefore(dateB)) return -1;
  return 0;
};

export const groupBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

/**
 * Get all days for a period
 *
 * @param {Date} from
 * @param {Date} to
 * @param {String} format
 *
 * @returns {Array}
 */
export const getAllDaysBetween = (from, to, format = DATES.fr.LONG) => {
  const diffDays = moment(to).diff(from, 'days') + 1;
  const allDays = Array(diffDays)
    .fill(0)
    .map((_, index) =>
      moment(from)
        .add(index, 'days')
        .format(format)
    );
  return allDays;
};

export const groupDatesBy = (dates, period, format = DATES.fr.LONG) =>
  _groupBy(dates, date =>
    moment(date, format)
      .startOf(period)
      .format()
  );

export const formatDate = (date, format = DATES.fr.LONG, locale = 'fr') => {
  moment.locale(locale);
  return moment(date).format(format);
};

export const uploadFile = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    cb(reader.result);
  };
  reader.onerror = function(error) {
    console.log('Error on uploading file: ', error);
  };
};

export const isBase64 = str => {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const toFrFormat = (date, format = moment.defaultFormat) => {
  return moment(date, format).format(DATES.fr.LONG);
};

export const toDefaultFormat = (date, format = DATES.fr.LONG) => {
  return moment(date, format).format();
};

export function getFromObj(obj, key, def, p = 0) {
  const path = toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj === undefined ? def : obj;
}

export function setToObj(obj, name, value) {
  const path = toPath(name);
  let newState = {};
  let resValue = newState;
  let index = 0;
  for (; index < path.length - 1; index++) {
    const currentKey = path[index];
    const currentObj = getFromObj(obj, path.slice(0, index + 1));
    if (resValue[currentKey]) {
      resValue = resValue[currentKey];
    } else if (currentObj) {
      resValue = resValue[currentKey] = cloneDeep(currentObj);
    } else {
      const nextPath = path[index + 1];
      resValue = resValue[currentKey] =
        isNumeric(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  resValue[path[index]] = value;
  return { ...obj, ...newState };
}

export const initSelectPicker = (selector = 'select', val) => {
  if (window.$ && window.$(selector)) {
    window.$(selector).selectpicker({
      noneSelectedText: t('select_nothing_selected')
    });
    window.$(selector).selectpicker('refresh');
    window.$(selector).selectpicker('render');
    window.$(selector).selectpicker('val', val);
  }
};

export const initMaterialInput = (selector = '.form-control') => {
  //On focus event
  window.$(selector).focus(function() {
    window
      .$(this)
      .closest('.form-line')
      .addClass('focused');
  });

  //On focusout event
  window.$(selector).focusout(function() {
    var $this = window.$(this);
    if ($this.parents('.form-group').hasClass('form-float')) {
      if ($this.val() === '') {
        $this.parents('.form-line').removeClass('focused');
      }
    } else {
      $this.parents('.form-line').removeClass('focused');
    }
  });
};

/**
 *
 * @param {Array} oldArray
 * @param {Array} newArray
 */
export const areDifferentBy = (oldArray, newArray, by = 'id') => {
  try {
    if (oldArray.length !== newArray.length) {
      return true;
    }
    let idsA = oldArray.map(item => item[by]);
    let idsB = newArray.map(item => item[by]);
    return idsA.join(',') !== idsB.join(',');
  } catch (error) {
    return true;
  }
};

/**
 *
 * @param {Object} obj
 * @param {String} key
 * @param {*} memo
 *
 * @returns {Array}
 */
export const findNested = (obj, key, memo) => {
  var i,
    proto = Object.prototype,
    ts = proto.toString,
    hasOwn = proto.hasOwnProperty.bind(obj);

  if ('[object Array]' !== ts.call(memo)) memo = [];

  for (i in obj) {
    if (hasOwn(i)) {
      if (i === key) {
        memo.push(obj[i]);
      } else if (
        '[object Array]' === ts.call(obj[i]) ||
        '[object Object]' === ts.call(obj[i])
      ) {
        findNested(obj[i], key, memo);
      }
    }
  }

  return memo;
};

export const uniqArrayBy = (arrArg, key) => {
  return arrArg.reduce(
    (x, y) => (x.findIndex(e => e[key] === y[key]) < 0 ? [...x, y] : x),
    []
  );
};

/**
 * remove an element from array by id and return it
 * @param {Array} arr
 * @param {*} id
 *
 * @returns {Object}
 */
export const removeByKey = (arr, value, key = 'id') => {
  let index = arr
    .map(function(x) {
      return x[key];
    })
    .indexOf(value);

  return arr.splice(index, 1);
};

export const enumerateDaysBetweenDate = (
  startDate,
  endDate,
  format = DATES.fr.LONG,
  group
) => {
  var dates = [];
  var currDate = moment(startDate)
    .startOf(group)
    .subtract(1, 'days');
  var lastDate = moment(endDate).endOf(group);
  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().format(format));
  }

  return dates;
};

export const getTimeZone = () => {
  const rightNow = new Date();
  const jan1 = new Date(rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);
  let temp = jan1.toGMTString();
  const jan2 = new Date(temp.substring(0, temp.lastIndexOf(' ') - 1));
  const std_time_offset = (jan1 - jan2) / (1000 * 60 * 60);
  // detecting daylight saving time
  const june1 = new Date(rightNow.getFullYear(), 6, 1, 0, 0, 0, 0);
  temp = june1.toGMTString();
  const june2 = new Date(temp.substring(0, temp.lastIndexOf(' ') - 1));
  const daylight_time_offset = (june1 - june2) / (1000 * 60 * 60);
  let dst;
  if (std_time_offset === daylight_time_offset) {
    dst = '0'; // daylight savings time is NOT observed
  } else {
    dst = '1'; // daylight savings time is observed
  }
  return { dst, timezone: std_time_offset };
};

export const chunkArray = (array, size, cb = () => false) => {
  const chunked_arr = [];
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    const last = chunked_arr[chunked_arr.length - 1];
    if (!last || last.length === size || cb(element, last)) {
      chunked_arr.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunked_arr;
};

export function slugify(string) {
  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      // eslint-disable-next-line no-useless-escape
      // .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      // eslint-disable-next-line no-useless-escape
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function isNilValue(value) {
  return value === '' || value === null || value === undefined;
}

export function isEmpty(value) {
  return !value || !value.length;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getPageFromUrl(location, pageKey = 'page') {
  let page = parseFloat(getQueryStringFromUrl(location.search)[pageKey]) || 1;
  if (page < 1) {
    page = 1;
  }
  return page;
}

export function isFile(file) {
  return (
    file &&
    (file.constructor === File ||
      (typeof file === 'object' && file.hasOwnProperty('lastModified')))
  );
}

export function isPdf(file) {
  return typeof file === 'object' && file.mimeType === 'application/pdf';
}
export function isWord(file) {
  return (
    typeof file === 'object' && file.mimeType === 'application/octet-stream'
  );
}

export function isResource(resource) {
  return (
    typeof resource === 'object' &&
    resource.hasOwnProperty('id') &&
    resource.hasOwnProperty('mimeType')
  );
}

export function isImage(file) {
  return (
    (isFile(file) && SUPPORTED_IMAGE_FORMATS.includes(file.type)) ||
    (isResource(file) && SUPPORTED_IMAGE_FORMATS.includes(file.mimeType))
  );
}

export function isDocument(file) {
  return (
    (isFile(file) && SUPPORTED_DOCUMENT_FORMATS.includes(file.type)) ||
    (isResource(file) && SUPPORTED_DOCUMENT_FORMATS.includes(file.mimeType))
  );
}

/**
 *
 * @param {*} target
 * @param {Array} oldSelected
 */
export function getLastSelectOption(target, oldSelected) {
  const newSelectedOptions = domNodesToArray(target.selectedOptions);
  return newSelectedOptions.find(option => !oldSelected.includes(option));
}

/**
 *
 * @param {string} str1
 * @param {string} str2
 *
 * @returns {Boolean}
 */
export function equalString(str1 = '', str2 = '', caseSensitive = true) {
  if (caseSensitive) {
    return str1.toLowerCase().trim() === str2.toLowerCase().trim();
  }
  return str1.trim() === str2.trim();
}

/**
 *
 * @param {Array<string>} arr
 * @param {string} str
 *
 * @returns {Boolean}
 */
export function stringExist(arr, str) {
  return arr.some(item => equalString(item, str));
}

export function downloadFromBlob(downloaded, fileName) {
  const url = window.URL.createObjectURL(new Blob([downloaded]));
  const link = document.createElement('a');
  link.style.position = 'absolute';
  link.style.left = '-999px';
  link.href = url;
  link.setAttribute('download', fileName);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.appendChild(link);
}

export function isIE() {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
}
