import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { normalizePermissions } from '../../services/userAcl';

export default (state = initialState.permissions, action) => {
  switch (action.type) {
    case types.SET_RULES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: null
      };
    case types.SET_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        data: {
          ...state.data,
          raw: action.payload,
          byEntities: normalizePermissions(
            action.payload.rules,
            action.payload.user
          )
        }
      };
    case types.SET_RULES_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
};
