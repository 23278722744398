import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signInUser, loginWithToken, getMe } from '../../modules/auth/action';
import { getQueryStringFromUrl } from '../../helpers/index';
import SignInForm from '../../components/auth/SignInForm';
import AuthLayout from './AuthLayout';
import { getCurrentToken } from '../../services/auth';
import PropTypes from 'prop-types';
import { LocalizerContext } from '../../components/hoc/Localizer';

class SignIn extends React.Component {
  static contextType = LocalizerContext;
  state = {
    isLogging: false
  };

  componentDidMount = () => {
    this._isMounted = true;
    // redirect user if conneted
    const isConnected = !!getCurrentToken();

    if (isConnected) {
      const locale = this.context;
      const localizedPath = locale.localizePath('/dashboard');
      // this.props.history.push(localizedPath);
      window.location.href = localizedPath;
    } else {
      // check if there is a token
      const { location } = this.props;
      const token = getQueryStringFromUrl(location.search).token;
      if (token) {
        this.signInWithToken(token);
      }
    }
  };

  toggleLoading = () => {
    if (this._isMounted) {
      this.setState(prevState => ({
        ...prevState,
        isLogging: !prevState.isLogging
      }));
    }
  };

  onSubmit = credentials => {
    const { signInUser } = this.props;
    // const locale = this.context;
    this.toggleLoading();
    signInUser(credentials)
      .then(this.handleOnResolve) // check if login is correct or not
      .then(this.props.getMe) // if success : get User
      .then(this.getUserLocale)
      // .then(locale.setLocale) // update localization
      .then(this.handleRedirection) // handle redirection
      .catch(this.handleOnFailure);
  };

  handleRedirection = language => {
    const {
      location
      // , history
    } = this.props;
    const locale = this.context;
    const redirectTo = getQueryStringFromUrl(location.search).redirectTo;
    const localizedRedirect =
      redirectTo || locale.localizePath('/dashboard', language);
    this.toggleLoading();
    window.location.href = localizedRedirect;
    // history.push(localizedRedirect);
  };

  signInWithToken = token => {
    // const locale = this.context;
    this.toggleLoading();
    this.props
      .loginWithToken(token)
      .then(this.handleOnResolve) // check if login is correct or not
      .then(this.props.getMe) // if success : get User
      .then(this.getUserLocale)
      // .then(locale.setLocale) // update localization
      .then(this.handleRedirection) // handle redirection
      .catch(this.handleOnFailure);
  };
  handleOnResolve = () => {
    const { auth } = this.props;
    if (!auth.authenticated) {
      throw new Error('Failed Login');
    } else {
      return true;
    }
  };

  getUserLocale = () => {
    const { auth } = this.props;
    if (!auth.user) {
      throw new Error('Failed Login');
    }
    return auth.user.language;
  };

  handleOnFailure = error => {
    this.toggleLoading();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { auth } = this.props;
    const { isLogging } = this.state;
    return (
      <AuthLayout>
        <SignInForm
          onSubmit={this.onSubmit}
          message={auth.message}
          isLoggingIn={isLogging}
        />
      </AuthLayout>
    );
  }
}

SignIn.propTypes = {
  auth: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ signInUser, loginWithToken, getMe }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
