import i18next from 'i18next';
import { I18_DEBUG } from '../../constants/flags';
import axios from 'axios';

export const setUiLocale = locale => {
  return axios
    .get(`/translations/${locale}.json`)
    .then(
      ({ data: loadedResources }) =>
        new Promise((resolve, reject) => {
          initializeI18n(locale, loadedResources)
            .then(resolve)
            .catch(reject);
        })
    )
    .catch(err => Promise.reject(err));
};

export const t = (...args) => i18next.t(...args);

export function initializeI18n(locale, loadedResources) {
  return new Promise((resolve, reject) => {
    i18next.init(
      {
        lng: locale,
        debug: I18_DEBUG,
        resources: { [locale]: loadedResources }
        // interpolation: {
        //   format: function(value, format, locale) {
        //     if (value instanceof Date) {
        //       return formatDate(value, format, locale);
        //     }
        //     return value;
        //   }
        // }
      },
      (err, t) => {
        if (err) {
          reject(err);
          return;
        }
        resolve({ loadedResources, locale });
      }
    );
  });
}
