// AUTH
export const SIGN_IN_REQUEST = '@@auth/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = '@@auth/SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = '@@auth/SIGN_IN_FAILURE';

export const SIGN_UP_REQUEST = '@@auth/SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = '@@auth/SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = '@@auth/SIGN_UP_FAILURE';

export const LOG_OUT_SUCCESS = '@@auth/LOG_OUT_SUCCESS';

export const LOAD_USER_FROM_LOCAL = '@@auth/LOAD_USER_FROM_LOCAL';
export const LOAD_USER_FROM_CACHE = '@@auth/LOAD_USER_FROM_CACHE';

// ENTITY
export const SAVE_ENTITY_REQUEST = 'SAVE_ENTITY_REQUEST';
export const SAVE_ENTITY_SUCCESS = 'SAVE_ENTITY_SUCCESS';
export const SAVE_ENTITY_FAILURE = 'SAVE_ENTITY_FAILURE';
export const FETCH_ENTITIES_REQUEST = 'FETCH_ENTITIES_REQUEST';
export const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS';
export const FETCH_ENTITIES_FAILURE = 'FETCH_ENTITIES_FAILURE';

export const FETCH_CHILDREN_REQUEST = 'FETCH_CHILDREN_REQUEST';
export const FETCH_CHILDREN_SUCCESS = 'FETCH_CHILDREN_SUCCESS';
export const FETCH_CHILDREN_FAILURE = 'FETCH_CHILDREN_FAILURE';

export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_REQUEST = 'UPDATE_ENTITY_REQUEST';
export const UPDATE_ENTITY_FAILURE = 'UPDATE_ENTITY_FAILURE';

export const DELETE_ENTITY_REQUEST = 'DELETE_ENTITY_REQUEST';
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export const DELETE_ENTITY_FAILURE = 'DELETE_ENTITY_FAILURE';

//Families

export const SAVE_FAMILY_REQUEST = 'SAVE_FAMILY_REQUEST';
export const SAVE_FAMILY_SUCCESS = 'SAVE_FAMILY_SUCCESS';
export const SAVE_FAMILY_FAILURE = 'SAVE_FAMILY_FAILURE';

export const PAGINATE_FAMILIES_REQUEST = 'PAGINATE_FAMILIES_REQUEST';
export const PAGINATE_FAMILIES_SUCCESS = 'PAGINATE_FAMILIES_SUCCESS';
export const PAGINATE_FAMILIES_FAILURE = 'PAGINATE_FAMILIES_FAILURE';

export const FETCH_FAMILIES_REQUEST = 'FETCH_FAMILIES_REQUEST';
export const FETCH_FAMILIES_SUCCESS = 'FETCH_FAMILIES_SUCCESS';
export const FETCH_FAMILIES_FAILURE = 'FETCH_FAMILIES_FAILURE';

export const UPDATE_FAMILY_SUCCESS = 'UPDATE_FAMILY_SUCCESS';
export const UPDATE_FAMILY_REQUEST = 'UPDATE_FAMILY_REQUEST';
export const UPDATE_FAMILY_FAILURE = 'UPDATE_FAMILY_FAILURE';

export const DELETE_FAMILY_REQUEST = 'DELETE_FAMILY_REQUEST';
export const DELETE_FAMILY_SUCCESS = 'DELETE_FAMILY_SUCCESS';
export const DELETE_FAMILY_FAILURE = 'DELETE_FAMILY_FAILURE';

export const GET_FAMILY_REQUEST = 'GET_FAMILY_REQUEST';
export const GET_FAMILY_SUCCESS = 'GET_FAMILY_SUCCESS';
export const GET_FAMILY_FAILURE = 'GET_FAMILY_FAILURE';

// Me STUFF
export const FETCH_ME_REQUEST = 'FETCH_ME_REQUEST';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAILURE = 'FETCH_ME_FAILURE';

export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_FAILURE = 'UPDATE_ME_FAILURE';
export const UPDATE_ME_REQUEST = 'UPDATE_ME_REQUEST';

export const UPDATE_UI = 'UPDATE_UI';

// user
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';
export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST';

export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';

export const GET_INVITED_SUCCESS = 'GET_INVITED_SUCCESS';
export const GET_INVITED_FAILURE = 'GET_INVITED_FAILURE';
export const GET_INVITED_REQUEST = 'GET_INVITED_REQUEST';

export const PAGINATE_INVITED_SUCCESS = 'PAGINATE_INVITED_SUCCESS';
export const PAGINATE_INVITED_FAILURE = 'PAGINATE_INVITED_FAILURE';
export const PAGINATE_INVITED_REQUEST = 'PAGINATE_INVITED_REQUEST';

export const REMOVE_INVITATION_REQUEST = 'REMOVE_INVITATION_REQUEST';
export const REMOVE_INVITATION_SUCCESS = 'REMOVE_INVITATION_SUCCESS';
export const REMOVE_INVITATION_FAILURE = 'REMOVE_INVITATION_FAILURE';

export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAILURE = 'CHECK_EMAIL_FAILURE';
export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';

export const SET_RULES_SUCCESS = 'SET_RULES_SUCCESS';
export const SET_RULES_FAILURE = 'SET_RULES_FAILURE';
export const SET_RULES_REQUEST = 'SET_RULES_REQUEST';

//
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';

export const VALIDATE_ACCOUNT_REQUEST = 'VALIDATE_ACCOUNT_REQUEST';
export const VALIDATE_ACCOUNT_SUCCESS = 'VALIDATE_ACCOUNT_SUCCESS';
export const VALIDATE_ACCOUNT_FAILURE = 'VALIDATE_ACCOUNT_FAILURE';

// equipment type
export const SAVE_EQUIPMENT_TYPE_REQUEST = 'SAVE_EQUIPMENT_TYPE_REQUEST';
export const SAVE_EQUIPMENT_TYPE_SUCCESS = 'SAVE_EQUIPMENT_TYPE_SUCCESS';
export const SAVE_EQUIPMENT_TYPE_FAILURE = 'SAVE_EQUIPMENT_TYPE_FAILURE';

export const UPDATE_EQUIPMENT_TYPE_REQUEST = 'UPDATE_EQUIPMENT_TYPE_REQUEST';
export const UPDATE_EQUIPMENT_TYPE_SUCCESS = 'UPDATE_EQUIPMENT_TYPE_SUCCESS';
export const UPDATE_EQUIPMENT_TYPE_FAILURE = 'UPDATE_EQUIPMENT_TYPE_FAILURE';

export const DELETE_EQUIPMENT_TYPE_REQUEST = 'DELETE_EQUIPMENT_TYPE_REQUEST';
export const DELETE_EQUIPMENT_TYPE_SUCCESS = 'DELETE_EQUIPMENT_TYPE_SUCCESS';
export const DELETE_EQUIPMENT_TYPE_FAILURE = 'DELETE_EQUIPMENT_TYPE_FAILURE';

export const GET_EQUIPMENT_TYPE_REQUEST = 'GET_EQUIPMENT_TYPE_REQUEST';
export const GET_EQUIPMENT_TYPE_SUCCESS = 'GET_EQUIPMENT_TYPE_SUCCESS';
export const GET_EQUIPMENT_TYPE_FAILURE = 'GET_EQUIPMENT_TYPE_FAILURE';

export const FETCH_EQUIPMENT_TYPE_REQUEST = 'FETCH_EQUIPMENT_TYPE_REQUEST';
export const FETCH_EQUIPMENT_TYPE_SUCCESS = 'FETCH_EQUIPMENT_TYPE_SUCCESS';
export const FETCH_EQUIPMENT_TYPE_FAILURE = 'FETCH_EQUIPMENT_TYPE_FAILURE';

export const CHECK_ET_NAME_REQUEST = 'CHECK_ET_NAME_REQUEST';
export const CHECK_ET_NAME_SUCCESS = 'CHECK_ET_NAME_SUCCESS';
export const CHECK_ET_NAME_FAILURE = 'CHECK_ET_NAME_FAILURE';

// control type
export const SAVE_CONTROL_TYPE_REQUEST = 'SAVE_CONTROL_TYPE_REQUEST';
export const SAVE_CONTROL_TYPE_SUCCESS = 'SAVE_CONTROL_TYPE_SUCCESS';
export const SAVE_CONTROL_TYPE_FAILURE = 'SAVE_CONTROL_TYPE_FAILURE';

export const UPDATE_CONTROL_TYPE_REQUEST = 'UPDATE_CONTROL_TYPE_REQUEST';
export const UPDATE_CONTROL_TYPE_SUCCESS = 'UPDATE_CONTROL_TYPE_SUCCESS';
export const UPDATE_CONTROL_TYPE_FAILURE = 'UPDATE_CONTROL_TYPE_FAILURE';

export const DELETE_CONTROL_TYPE_REQUEST = 'DELETE_CONTROL_TYPE_REQUEST';
export const DELETE_CONTROL_TYPE_SUCCESS = 'DELETE_CONTROL_TYPE_SUCCESS';
export const DELETE_CONTROL_TYPE_FAILURE = 'DELETE_CONTROL_TYPE_FAILURE';

export const GET_CONTROL_TYPE_REQUEST = 'GET_CONTROL_TYPE_REQUEST';
export const GET_CONTROL_TYPE_SUCCESS = 'GET_CONTROL_TYPE_SUCCESS';
export const GET_CONTROL_TYPE_FAILURE = 'GET_CONTROL_TYPE_FAILURE';

export const CHECK_CT_NAME_REQUEST = 'CHECK_CT_NAME_REQUEST';
export const CHECK_CT_NAME_SUCCESS = 'CHECK_CT_NAME_SUCCESS';
export const CHECK_CT_NAME_FAILURE = 'CHECK_CT_NAME_FAILURE';

export const GET_CT_BY_EQUIPMENT_REQUEST = 'GET_CT_BY_EQUIPMENT_REQUEST';
export const GET_CT_BY_EQUIPMENT_SUCCESS = 'GET_CT_BY_EQUIPMENT_SUCCESS';
export const GET_CT_BY_EQUIPMENT_FAILURE = 'GET_CT_BY_EQUIPMENT_FAILURE';

export const FETCH_CONTROL_TYPE_REQUEST = 'FETCH_CONTROL_TYPE_REQUEST';
export const FETCH_CONTROL_TYPE_SUCCESS = 'FETCH_CONTROL_TYPE_SUCCESS';
export const FETCH_CONTROL_TYPE_FAILURE = 'FETCH_CONTROL_TYPE_FAILURE';

export const PAGINATE_CONTROL_TYPE_REQUEST = 'PAGINATE_CONTROL_TYPE_REQUEST';
export const PAGINATE_CONTROL_TYPE_SUCCESS = 'PAGINATE_CONTROL_TYPE_SUCCESS';
export const PAGINATE_CONTROL_TYPE_FAILURE = 'PAGINATE_CONTROL_TYPE_FAILURE';

// SHARD
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const READ_FILE_REQUEST = 'READ_FILE_REQUEST';
export const READ_FILE_SUCCESS = 'READ_FILE_SUCCESS';
export const READ_FILE_FAILURE = 'READ_FILE_FAILURE';

// Statuses/List
export const NEW_LIST_REQUEST = 'NEW_LIST_REQUEST';
export const NEW_LIST_SUCCESS = 'NEW_LIST_SUCCESS';
export const NEW_LIST_FAILURE = 'NEW_LIST_FAILURE';

export const EDIT_LIST_REQUEST = 'EDIT_LIST_REQUEST';
export const EDIT_LIST_SUCCESS = 'EDIT_LIST_SUCCESS';
export const EDIT_LIST_FAILURE = 'EDIT_LIST_FAILURE';

export const DELETE_LIST_REQUEST = 'DELETE_LIST_REQUEST';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_FAILURE = 'DELETE_LIST_FAILURE';

export const FETCH_LIST_REQUEST = 'FETCH_LIST_REQUEST';
export const FETCH_LIST_SUCCESS = 'FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'FETCH_LIST_FAILURE';

export const FETCH_STATUSES_REQUEST = 'FETCH_STATUSES_REQUEST';
export const FETCH_STATUSES_SUCCESS = 'FETCH_STATUSES_SUCCESS';
export const FETCH_STATUSES_FAILURE = 'FETCH_STATUSES_FAILURE';

export const DELETE_STATUS_REQUEST = 'DELETE_STATUS_REQUEST';
export const DELETE_STATUS_SUCCESS = 'DELETE_STATUS_SUCCESS';
export const DELETE_STATUS_FAILURE = 'DELETE_STATUS_FAILURE';

export const NEW_STATUSES_REQUEST = 'NEW_STATUSES_REQUEST';
export const NEW_STATUSES_SUCCESS = 'NEW_STATUSES_SUCCESS';
export const NEW_STATUSES_FAILURE = 'NEW_STATUSES_FAILURE';

export const LOAD_COUNTRIES_REQUEST = 'LOAD_COUNTRIES_REQUEST';
export const LOAD_COUNTRIES_SUCCESS = 'LOAD_COUNTRIES_SUCCESS';
export const LOAD_COUNTRIES_FAILURE = 'LOAD_COUNTRIES_FAILURE';

export const SET_TRANSLATION = 'SET_TRANSLATION';

//equipment

export const SAVE_EQUIPMENT_REQUEST = 'SAVE_EQUIPMENT_REQUEST';
export const SAVE_EQUIPMENT_SUCCESS = 'SAVE_EQUIPMENT_SUCCESS';
export const SAVE_EQUIPMENT_FAILURE = 'SAVE_EQUIPMENT_FAILURE';

export const UPDATE_EQUIPMENT_REQUEST = 'UPDATE_EQUIPMENT_REQUEST';
export const UPDATE_EQUIPMENT_SUCCESS = 'UPDATE_EQUIPMENT_SUCCESS';
export const UPDATE_EQUIPMENT_FAILURE = 'UPDATE_EQUIPMENT_FAILURE';

export const DELETE_EQUIPMENT_REQUEST = 'DELETE_EQUIPMENT_REQUEST';
export const DELETE_EQUIPMENT_SUCCESS = 'DELETE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_FAILURE = 'DELETE_EQUIPMENT_FAILURE';

export const GET_EQUIPMENT_REQUEST = 'GET_EQUIPMENT_REQUEST';
export const GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS';
export const GET_EQUIPMENT_FAILURE = 'GET_EQUIPMENT_FAILURE';

export const HAS_CONTROLS_REQUEST = 'HAS_CONTROLS_REQUEST';
export const HAS_CONTROLS_SUCCESS = 'HAS_CONTROLS_SUCCESS';
export const HAS_CONTROLS_FAILURE = 'HAS_CONTROLS_FAILURE';

export const FETCH_EQUIPMENT_REQUEST = 'FETCH_EQUIPMENT_REQUEST';
export const FETCH_EQUIPMENT_SUCCESS = 'FETCH_EQUIPMENT_SUCCESS';
export const FETCH_EQUIPMENT_FAILURE = 'FETCH_EQUIPMENT_FAILURE';

export const FILTER_EQUIPMENT_REQUEST = 'FILTER_EQUIPMENT_REQUEST';
export const FILTER_EQUIPMENT_SUCCESS = 'FILTER_EQUIPMENT_SUCCESS';
export const FILTER_EQUIPMENT_FAILURE = 'FILTER_EQUIPMENT_FAILURE';

export const CHECK_EQUIPMENT_REQUEST = 'CHECK_EQUIPMENT_REQUEST';
export const CHECK_EQUIPMENT_SUCCESS = 'CHECK_EQUIPMENT_SUCCESS';
export const CHECK_EQUIPMENT_FAILURE = 'CHECK_EQUIPMENT_FAILURE';

export const TOGGLE_ARCHIVE_REQUEST = 'TOGGLE_ARCHIVE_REQUEST';
export const TOGGLE_ARCHIVE_SUCCESS = 'TOGGLE_ARCHIVE_SUCCESS';
export const TOGGLE_ARCHIVE_FAILURE = 'TOGGLE_ARCHIVE_FAILURE';

//control
export const SAVE_CONTROL_REQUEST = 'SAVE_CONTROL_REQUEST';
export const SAVE_CONTROL_SUCCESS = 'SAVE_CONTROL_SUCCESS';
export const SAVE_CONTROL_FAILURE = 'SAVE_CONTROL_FAILURE';

export const UPDATE_CONTROL_REQUEST = 'UPDATE_CONTROL_REQUEST';
export const UPDATE_CONTROL_SUCCESS = 'UPDATE_CONTROL_SUCCESS';
export const UPDATE_CONTROL_FAILURE = 'UPDATE_CONTROL_FAILURE';

export const DELETE_CONTROL_REQUEST = 'DELETE_CONTROL_REQUEST';
export const DELETE_CONTROL_SUCCESS = 'DELETE_CONTROL_SUCCESS';
export const DELETE_CONTROL_FAILURE = 'DELETE_CONTROL_FAILURE';

export const GET_CONTROL_REQUEST = 'GET_CONTROL_REQUEST';
export const GET_CONTROL_SUCCESS = 'GET_CONTROL_SUCCESS';
export const GET_CONTROL_FAILURE = 'GET_CONTROL_FAILURE';

export const FETCH_CONTROL_REQUEST = 'FETCH_CONTROL_REQUEST';
export const FETCH_CONTROL_SUCCESS = 'FETCH_CONTROL_SUCCESS';
export const FETCH_CONTROL_FAILURE = 'FETCH_CONTROL_FAILURE';

//Dashboard
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const CHANGE_BAR_CHART_FILTER = 'CHANGE_BAR_CHART_FILTER';
export const CHANGE_SORT = 'CHANGE_SORT';
export const TOGGLE_IMPORT_MODAL = 'TOGGLE_IMPORT_MODAL';
export const TOGGLE_EXPORT_MODAL = 'TOGGLE_EXPORT_MODAL';
export const REFRESH_DASHBOARD = 'REFRESH_DASHBOARD';

export const INITIALIZE_FILTERS = 'INITIALIZE_FILTERS';
export const GET_STATUS_DATA_REQUEST = 'GET_STATUS_DATA_REQUEST';
export const GET_STATUS_DATA_SUCCESS = 'GET_STATUS_DATA_SUCCESS';
export const GET_STATUS_DATA_FAILURE = 'GET_STATUS_DATA_FAILURE';
export const GET_EQUIPMENTS_CHART_DATA_REQUEST =
  'GET_EQUIPMENTS_CHART_DATA_REQUEST';
export const GET_EQUIPMENTS_CHART_DATA_SUCCESS =
  'GET_EQUIPMENTS_CHART_DATA_SUCCESS';
export const GET_EQUIPMENTS_CHART_DATA_FAILURE =
  'GET_EQUIPMENTS_CHART_DATA_FAILURE';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
