import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';

export default (state = initialState.families, action) => {
  switch (action.type) {
    case types.SAVE_FAMILY_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: false,
        message: null
      };
    case types.FETCH_FAMILIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: null
      };
    case types.PAGINATE_FAMILIES_REQUEST:
    case types.UPDATE_FAMILY_REQUEST:
    case types.DELETE_FAMILY_REQUEST:
    case types.GET_FAMILY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: null
      };

    case types.PAGINATE_FAMILIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        meta: {
          ...state.meta,
          ...action.meta
        },
        data: {
          ...state.data,
          list: action.payload.byIndexes,
          normalized: action.payload.entities.families,
          ids: action.payload.result
        },
        message: action.message || t('default_success_message')
      };
    case types.FETCH_FAMILIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        data: {
          ...state.data,
          all: action.payload.byIndexes || [],
          allNormalized: action.payload.entities.families || {}
        },
        message: action.message || t('default_success_message')
      };
    case types.GET_FAMILY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        data: {
          ...state.data,
          family: action.payload
        },
        message: action.message || t('default_success_message')
      };

    case types.SAVE_FAMILY_SUCCESS:
      return {
        ...state,
        isSaving: false,
        error: false,
        message: action.message || t('default_success_message')
      };

    case types.UPDATE_FAMILY_SUCCESS:
    case types.DELETE_FAMILY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: action.message || t('default_success_message')
      };
    case types.SAVE_FAMILY_FAILURE:
      return {
        ...state,
        error: true,
        isSaving: false,
        message: action.message || t('default_error_message')
      };
    case types.PAGINATE_FAMILIES_FAILURE:
    case types.UPDATE_FAMILY_FAILURE:
    case types.DELETE_FAMILY_FAILURE:
    case types.GET_FAMILY_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
        message: action.message || t('default_error_message')
      };
    case types.FETCH_FAMILIES_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        message: action.message || t('default_error_message')
      };

    // EQUIPMENT_TYPES
    case types.CHECK_ET_NAME_REQUEST:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isChecking: true,
          canUseName: false,
          error: false,
          message: null
        }
      };
    case types.CHECK_ET_NAME_SUCCESS:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isChecking: false,
          canUseName: action.payload
        }
      };
    case types.CHECK_ET_NAME_FAILURE:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isChecking: false,
          canUseName: false
        }
      };
    case types.FETCH_EQUIPMENT_TYPE_REQUEST:
    case types.GET_EQUIPMENT_TYPE_REQUEST:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isLoading: true,
          error: false,
          message: null
        }
      };

    case types.SAVE_EQUIPMENT_TYPE_REQUEST:
    case types.UPDATE_EQUIPMENT_TYPE_REQUEST:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isSaving: true,
          error: false,
          message: null
        }
      };

    case types.DELETE_EQUIPMENT_TYPE_REQUEST:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isDeleting: true,
          error: false,
          message: null
        }
      };

    case types.SAVE_EQUIPMENT_TYPE_SUCCESS:
    case types.UPDATE_EQUIPMENT_TYPE_SUCCESS:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isSaving: false,
          error: false,
          data: action.payload,
          message: action.message || t('default_success_message')
        }
      };

    case types.GET_EQUIPMENT_TYPE_SUCCESS:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isLoading: false,
          byId: action.payload,
          message: action.message || t('default_success_message')
        }
      };
    case types.FETCH_EQUIPMENT_TYPE_SUCCESS:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isLoading: false,
          message: action.message || t('default_success_message'),
          data: {
            ...state.equipmentTypes.data,
            list: action.payload
          }
        }
      };
    case types.DELETE_EQUIPMENT_TYPE_SUCCESS:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isDeleting: false,
          error: false,
          message: action.message || t('default_success_message')
        }
      };
    case types.GET_EQUIPMENT_TYPE_FAILURE:
    case types.FETCH_EQUIPMENT_TYPE_FAILURE:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isLoading: false,
          error: true,
          message: action.message || t('default_error_message')
        }
      };
    case types.SAVE_EQUIPMENT_TYPE_FAILURE:
    case types.UPDATE_EQUIPMENT_TYPE_FAILURE:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          isSaving: false,
          error: true,
          message: action.message || t('default_error_message')
        }
      };
    case types.DELETE_EQUIPMENT_TYPE_FAILURE:
      return {
        ...state,
        equipmentTypes: {
          ...state.equipmentTypes,
          error: true,
          isDeleting: false,
          message: action.message || t('default_error_message')
        }
      };
    default:
      return state;
  }
};
