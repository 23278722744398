import * as types from '../../constants/actionTypes';
import axios from 'axios';
import { setToLS } from '../../helpers/localstorage';
import moment from 'moment';
import { BASE_API_URL, LOCAL_STORAGE } from '../../constants';
import { getAxiosInstance } from '../../helpers/interceptors';
import {
  getCurrentUser,
  canLoadUserFromLS,
  clearLocalstorage
} from '../../services/auth';
import { t } from '../../services/i18n';
import { changeFilter } from '../filters/action';
const enhancedAxios = getAxiosInstance();
// const BASE_AUTH_URL = `${BASE_API_URL}/auth/oauth/v2/token?grant_type=password&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&redirect_uri=${REDIRECT_URI}`;

/**
 *
 * @param {Object} credentials
 * @returns {Promise}
 */
export const signInUser = credentials => {
  return dispatch => {
    dispatch(signInRequest());
    return axios
      .post(`${BASE_API_URL}/user/login`, {
        ...credentials,
        username: credentials.email
      })
      .then(response => {
        const { data } = response;
        setToLS(LOCAL_STORAGE.AUTH, data);
        dispatch(signInSuccess(data));
      })
      .catch(error => {
        console.log(error.response);
        clearLocalstorage();
        dispatch(signInFailure(error.response));
      });
  };
};

/**
 *
 * @param {Object} body
 * @returns {Promise}
 */
export const register = body => {
  return dispatch => {
    dispatch(registerRequest());
    return axios
      .post(`${BASE_API_URL}/user/register`, body)
      .then(response => {
        const { data } = response;
        dispatch(registerSuccess(data));
      })
      .catch(error => {
        console.log(error);
        dispatch(registerFailure(error));
      });
  };
};

/**
 *
 * @returns {Promise}
 */
export const getMe = (force = false) => {
  return (dispatch, getState) => {
    // check if we can load user from localstorage
    if (!force && canLoadUserFromLS()) {
      dispatch(loadUserFromToken());
      return Promise.resolve();
    }
    dispatch(getMeRequest());
    return enhancedAxios
      .get(`${BASE_API_URL}/user/me`)
      .then(response => {
        const currentUser = response.data;
        setToLS(LOCAL_STORAGE.LAST_GET_ME, moment().format(), false);
        setToLS(LOCAL_STORAGE.USER, currentUser);
        dispatch(getMeSuccess(currentUser));
      })
      .catch(error => {
        clearLocalstorage();
        dispatch(getMeFailure(error.response));
      });
  };
};

function registerRequest() {
  return {
    type: types.SIGN_UP_REQUEST
  };
}

export function logout() {
  return function(dispatch, getState) {
    try {
      clearLocalstorage();
      dispatch(changeFilter({ query: [], to: moment().format() }, false));
      dispatch(logoutSuccess());
    } catch (e) {
      dispatch(logoutFailure());
    }
  };
}

/**
 *
 * @param {string} token
 * @returns {Promise}
 */
export const loginWithToken = token => {
  return (dispatch, getState) => {
    dispatch(loginWithTokenRequest());
    return axios
      .get(`${BASE_API_URL}/user/${token}/auto-login`)
      .then(response => {
        const { data } = response;
        setToLS(LOCAL_STORAGE.AUTH, data);
        dispatch(loginWithTokenSuccess(data));
      })
      .catch(error => {
        console.log(error.response);
        dispatch(loginWithTokenFailure(error));
      });
  };
};

/**
 *
 * @param {Object} credentials
 * @returns {Promise}
 */
export const verifyAccount = credentials => {
  return (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_PASSWORD_REQUEST
    });

    return axios
      .post(`${BASE_API_URL}/core/user/verify`, credentials)
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: types.UPDATE_PASSWORD_SUCCESS,
            message: 'Votre compte a été activé'
          });
        } else {
          dispatch({
            type: types.UPDATE_PASSWORD_FAILURE
          });
        }
      })
      .catch(error => {
        dispatch({
          type: types.UPDATE_PASSWORD_FAILURE,
          message: 'Vérifier vos coordonnées'
        });
      });
  };
};

/**
 *
 * @param {Object} credentials
 * @returns {Promise}
 */
export const updatePassword = credentials => {
  return enhancedAxios
    .post(`${BASE_API_URL}/user/update-password`, credentials)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw new Error(
        error.response && error.response.data && error.response.data.content
      );
    });
};

export const resetPassword = body => {
  return (dispatch, getState) => {
    dispatch(resetPasswordRequest());
    return axios
      .post(`${BASE_API_URL}/user/reset-password`, body)
      .then(response => {
        dispatch(resetPasswordSuccess(response.data, response.data.message));
      })
      .catch(error => {
        dispatch(resetPasswordFailure(error.response));
      });
  };
};

export const changePassword = body => {
  return (dispatch, getState) => {
    dispatch(changePasswordRequest());
    return axios
      .post(`${BASE_API_URL}/user/change-password`, body)
      .then(response => {
        dispatch(changePasswordSuccess(response.data, response.data.message));
      })
      .catch(error => {
        dispatch(changePasswordFailure(error.response));
      });
  };
};

export const authSuccess = user => {
  return (dispatch, getState) => {
    setToLS(LOCAL_STORAGE.USER, user);
    dispatch(authSuccessAction(user));
  };
};

export const loadUserFromToken = () => {
  return (dispatch, getState) => {
    const currentUser = getCurrentUser();
    dispatch(loadUserSuccess(currentUser));
  };
};

function logoutFailure() {
  return {
    type: types.LOG_OUT_SUCCESS
  };
}

function logoutSuccess() {
  return {
    type: types.LOG_OUT_SUCCESS
  };
}

function authSuccessAction(user) {
  return {
    type: types.LOAD_USER_FROM_LOCAL,
    payload: user
  };
}

function loginWithTokenRequest() {
  return {
    type: types.SIGN_IN_REQUEST
  };
}

function loginWithTokenFailure(error) {
  return {
    type: types.SIGN_IN_FAILURE,
    fullError: error.response.data,
    message: error.response.data.error_description
  };
}

function loginWithTokenSuccess(data) {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: data,
    redirectFrom: '/login'
  };
}

function loadUserSuccess(currentUser) {
  return {
    type: types.LOAD_USER_FROM_CACHE,
    payload: currentUser
  };
}

function getMeRequest() {
  return {
    type: types.FETCH_ME_REQUEST
  };
}

function getMeFailure(error) {
  return {
    type: types.FETCH_ME_FAILURE,
    message:
      error.data && error.data.error === 'access_denied'
        ? t('signin_page.invalid_request')
        : undefined
  };
}

function getMeSuccess(currentUser) {
  return {
    type: types.FETCH_ME_SUCCESS,
    payload: currentUser
  };
}

function resetPasswordRequest() {
  return {
    type: types.RESET_PASSWORD_REQUEST
  };
}

function resetPasswordFailure(error) {
  return {
    type: types.RESET_PASSWORD_FAILURE,
    message: t('reset_page.email_not_exist')
  };
}

function resetPasswordSuccess(data) {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    payload: data,
    message: t('reset_page.success')
  };
}

function changePasswordRequest() {
  return {
    type: types.CHANGE_PASSWORD_REQUEST
  };
}

function changePasswordFailure(error) {
  const message =
    error.data.response &&
    (error.data.response.plainPassword ===
      'error.validation.password_strength.weak' ||
      error.data.response.plainPasswordHistory ===
        'error.validation.password_reuse')
      ? t('forget_password_page.error')
      : undefined;
  return {
    type: types.CHANGE_PASSWORD_FAILURE,
    message
  };
}

function changePasswordSuccess(data, message) {
  return {
    type: types.CHANGE_PASSWORD_SUCCESS,
    payload: data.response,
    message: message
  };
}

function registerFailure(error) {
  return {
    type: types.SIGN_UP_FAILURE,
    fullError: error && error.response && error.response.data,
    message:
      error &&
      error.response &&
      error.response.data &&
      t(error.response.data.content)
  };
}

function registerSuccess(data) {
  return {
    type: types.SIGN_UP_SUCCESS,
    payload: data,
    redirectFrom: '/login'
  };
}

function signInFailure(response) {
  return {
    type: types.SIGN_IN_FAILURE,
    fullError: response.data,
    message: t(`signin_page.${response.data}`, { link: '/fr/reset' })
  };
}

function signInSuccess(data) {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: data,
    redirectFrom: '/login'
  };
}

function signInRequest() {
  return {
    type: types.SIGN_IN_REQUEST
  };
}
