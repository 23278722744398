import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changePassword } from '../../modules/auth/action';
import ForgetPwdForm from '../../components/auth/ForgetPwdForm';
import AuthLayout from './AuthLayout';
import { getQueryStringFromUrl } from '../../helpers';
import { showNotification } from '../../helpers/notification';
import { LocalizerContext } from '../../components/hoc/Localizer';

class ForgetPassword extends React.Component {
  state = {
    reset_token: null
  };

  static contextType = LocalizerContext;

  componentDidMount = () => {
    const { location, history } = this.props;
    const reset_token = getQueryStringFromUrl(location.search).token;
    if (reset_token) {
      this.setState({ reset_token });
    } else {
      history.push('/');
    }
  };

  onSubmit = credentials => {
    const body = { ...credentials, ...this.state };
    this.props.changePassword(body).then(this.handleOnResolve);
  };

  handleOnResolve = () => {
    const { auth, history } = this.props;
    showNotification(auth.message, { error: auth.error });
    if (!auth.error && auth.authObj) {
      const locale = this.context;
      history.push(
        locale.localizePath(`/login?token=${auth.authObj.refresh_token}`)
      );
    }
  };

  render() {
    const { auth } = this.props;
    return (
      <AuthLayout>
        <ForgetPwdForm
          onSubmit={this.onSubmit}
          message={auth.message}
          isSubmitting={auth.isUpdating}
        />
      </AuthLayout>
    );
  }
}

ForgetPassword.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changePassword }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPassword);
