import * as types from '../../constants/actionTypes';
import initialState from '../initialState';

export default (state = initialState.ui, action) => {
  switch (action.type) {
    case types.UPDATE_UI:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };

    default:
      return state;
  }
};
