import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './rootReducer';
import initialState from './initialState';
import normalizeMiddleware from './middlewares/normalizeData';
import localstorageMiddleware from './middlewares/localstorage';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'biotrace',
  storage,
  whitelist: ['shared', 'ui', 'translations', 'filters']
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

// Middlewares
const enhancers = [];
const middleware = [
  normalizeMiddleware(),
  localstorageMiddleware(),
  thunk,
  routerMiddleware(history)
];

//Devtools stuff
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}
if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./index', () => {
      store.replaceReducer(persistedReducer);
    });
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);
const store = createStore(persistedReducer, initialState, composedEnhancers);
const persistor = persistStore(store);

export default { store, persistor };
