import * as types from '../../constants/actionTypes';
import { getAxiosInstance } from '../../helpers/interceptors';
import { BASE_API_URL } from '../../constants';
const axios = getAxiosInstance();

export const fetchPermissions = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_RULES_REQUEST
    });
    return axios
      .get(`${BASE_API_URL}/user/entities`)
      .then(response => {
        dispatch({
          type: types.SET_RULES_SUCCESS,
          payload: { rules: response.data, user: getState().auth.user }
        });
      })
      .catch(error => {
        console.error(error);
        dispatch({
          type: types.SET_RULES_FAILURE
        });
      });
  };
};
