import { domNodesToArray, isEmpty, precisionRound } from '../helpers';
import { ENTITY_LEVELS } from '../constants';
import { canI } from './userAcl';
import { ALL_SELECTED } from '../components/layouts/HeaderFilters';
import { EQUIPMENT_CHART_METADATA } from '../constants/chartsMeta';
import { t } from './i18n';

/**
 * Filters interface.
 * @typedef {Object} IFilters
 *
 * @property {Array} family -
 * @property {Array} type -
 * @property {Array} level1 -
 * @property {Array} level2 -
 * @property {Array} level3 -
 */

export function getNewSelectedOptions(target, oldSelectedOptions) {
  const rawSelectedOptions = domNodesToArray(target.selectedOptions);
  const justSelectAll =
    rawSelectedOptions.some(val => val === ALL_SELECTED()) &&
    !oldSelectedOptions.some(val => val === ALL_SELECTED());
  const justUnSelectAll =
    !rawSelectedOptions.some(val => val === ALL_SELECTED()) &&
    oldSelectedOptions.some(val => val === ALL_SELECTED());
  if (justUnSelectAll) {
    return [];
  }
  const allOptions = domNodesToArray(target.options);
  let newSelectedOptions = justSelectAll
    ? allOptions //if select all => all options should be selected
    : rawSelectedOptions; //if unselect one => all should be unselected;

  // all options selected but ALL , so we select ALL automatically
  if (
    target.options.length - 1 === newSelectedOptions.length &&
    !newSelectedOptions.includes(ALL_SELECTED())
  ) {
    newSelectedOptions.push(ALL_SELECTED());
  }

  if (
    !justSelectAll &&
    newSelectedOptions.includes(ALL_SELECTED()) &&
    target.options.length > newSelectedOptions.length
  ) {
    newSelectedOptions = newSelectedOptions.filter(
      option => option !== ALL_SELECTED()
    );
  }

  return newSelectedOptions;
}

export function hasSelectedAll(target, oldSelectedOptions) {
  const rawSelectedOptions = domNodesToArray(target.selectedOptions);
  const rawOptions = domNodesToArray(target.options);
  const rawOptionsWithoutAll = rawOptions.filter(o => o !== ALL_SELECTED());
  const result =
    (rawSelectedOptions.some(val => val === ALL_SELECTED()) &&
      !oldSelectedOptions.some(val => val === ALL_SELECTED())) ||
    JSON.stringify(rawSelectedOptions) === JSON.stringify(rawOptionsWithoutAll);
  return result;

  // return (
  //   (rawSelectedOptions.some(val => val === ALL_SELECTED()) &&
  //     !oldSelectedOptions.some(val => val === ALL_SELECTED())) ||
  //   (target.options.length - 1 === target.selectedOptions.length &&
  //     rawOptions.some(val => val === ALL_SELECTED()))
  // );
}

export function isAllSelected(ids) {
  return ids.some(val => val === ALL_SELECTED());
}

/**
 *
 * @param {IFilters} filters
 */
export function isFilterDirty(filters = {}) {
  return Object.values(filters).some(filter => !isEmpty(filter));
}

/**
 *
 * @param {import('./users').ICompany} company
 *
 * @returns {Boolean}
 */
export function hasFinishedInitialization(company) {
  return (
    company &&
    company.hasFamilies &&
    company.hasControlTypes &&
    company.hasEquipmentTypes &&
    company.entity &&
    !company.entity.isLeaf
  );
}

/**
 *
 * @param {import('./families').IFamily[]} families
 * @param {import('./entities').IEntity[]} entities
 *
 */
export function setAllFilters(families, entities, permissions) {
  const filters = {
    query: []
  };
  if (!isEmpty(entities)) {
    const entitiesByLevel = entities.reduce((byLevels, entity) => {
      if (canI('canSee', entity, permissions)) {
        if (Array.isArray(byLevels[entity.level])) {
          byLevels[entity.level].push(entity.id);
        } else {
          byLevels[entity.level] = [entity.id];
        }
      }
      return byLevels;
    }, {});
    const { LEVEL_1, LEVEL_2, LEVEL_3 } = ENTITY_LEVELS;
    filters.level1 = entitiesByLevel[LEVEL_1.value].concat([ALL_SELECTED()]);
    filters.level2 = entitiesByLevel[LEVEL_2.value].concat([ALL_SELECTED()]);
    filters.level3 = entitiesByLevel[LEVEL_3.value].concat([ALL_SELECTED()]);
  }

  if (!isEmpty(families)) {
    filters.family = families.map(family => family.id).concat([ALL_SELECTED()]);
    filters.type = families
      .flatMap(family => family.types.map(type => type.id))
      .concat([ALL_SELECTED()]);
  }
  return filters;
}

/**
 *
 * @param {IFilters} filters
 *
 * @returns {IFilters}
 */
export function checkFilterPermissionPermission(
  filters,
  entitiesByIds,
  permissions
) {
  if (filters.level1 && filters.level2 && filters.level3) {
    console.log('Checking permissions...');
    return {
      ...filters,
      level1: filters.level1.filter(entityId =>
        canI('canSee', entitiesByIds[entityId], permissions)
      ),
      level2: filters.level2.filter(entityId =>
        canI('canSee', entitiesByIds[entityId], permissions)
      ),
      level3: filters.level3.filter(entityId =>
        canI('canSee', entitiesByIds[entityId], permissions)
      )
    };
  }
  return filters;
}

export const mapIntoStatusesChart = rawData => {
  const { total, ...datas } = rawData;

  return Object.values(datas).map(item => ({
    ...item,
    label: item.custom ? t(item.label) : item.label,
    id: item.label,
    value: precisionRound((item.equipmentsIds.length / total) * 100)
  }));
};

export const mapIntoEquipmentsChart = rawData => {
  const metaData = EQUIPMENT_CHART_METADATA();
  const { total, ...datas } = rawData;
  return Object.keys(metaData)
    .filter(key => datas[key])
    .map(key => ({
      equipmentsIds: datas[key],
      label: metaData[key].label,
      [key]: precisionRound((datas[key].length / total) * 100),
      [`${key}Color`]: metaData[key].backgroundColor
    }));
};

/**
 * @param {import('../modules/initialState').IInitialState} state
 */
export function getFiltersQuery(state, criteria) {
  const {
    permissions,
    filters,
    barChartFilters,
    auth: { user }
  } = state;
  const query = {
    ...filters[user.id],
    ...(barChartFilters[user.id]
      ? barChartFilters[user.id]
      : barChartFilters.initials),
    level3:
      filters[user.id] && filters[user.id].level3
        ? filters[user.id].level3.filter(entityId =>
            canI('canSee', { id: entityId }, permissions.data)
          )
        : [],
    ...criteria
  };
  return query;
}

export function getNotificationQuery(state) {
  const { permissions } = state;
  const families =
    state.families && state.families.data && state.families.data.all
      ? state.families.data.all
      : [];
  const entities =
    state.entities &&
    state.entities.byChildren &&
    state.entities.byChildren.flat
      ? state.entities.byChildren.flat
      : [];

  return {
    level3: entities
      .filter(
        entity =>
          entity.level === ENTITY_LEVELS.LEVEL_3.value &&
          canI('canSee', entity, permissions.data)
      )
      .map(entity => entity.id),
    type:
      families &&
      families
        .flatMap(family => family.types.map(type => type.id))
        .concat([ALL_SELECTED()])
  };
}
