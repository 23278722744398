import assign from 'lodash/assign';
import { normalize } from 'normalizr';

export default function normalizeMiddleware() {
  return store => next => action => {
    const schema = action.meta && action.meta.schema;
    if (schema && action.payload && !action.error) {
      const normalized = normalize(action.payload, schema);
      action = assign({}, action, {
        payload: {
          ...normalized,
          byIndexes: action.payload
        }
      });
    }
    return next(action);
  };
}
