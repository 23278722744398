import {
  LAST_ENTITY_LEVEL,
  ENTITY_LEVELS,
  FIRST_ENTITY_LEVEL
} from '../constants';
import { cleanObject, equalString } from '../helpers';
import { hasRole } from './users';

/**
 * IEntity interface.
 * @typedef {Object} IEntity
 *
 * @property {String} id -  .
 * @property {String} name -
 * @property {String} path -
 * @property {Number} level -
 * @property {Object} parent -
 * @property {Boolean} editable -
 * @property {Boolean} hasEquipments -
 */

/**
 *
 * @param {IEntity[]} org
 * @param {import('./users').IUser} currentUser
 *
 * @returns {IEntity[]}
 */
export function transformOrganization(org, currentUser) {
  if (!Array.isArray(org)) {
    return [];
  }

  const canManageEntities =
    hasRole(currentUser.userRoles, 'SUPER_MANAGER') ||
    hasRole(currentUser.userRoles, 'OWNER');
  // flat all entities into one-level array and add a new property for rowSpan
  let flatArr = flatEntities(org, canManageEntities);

  return flatArr;
}

/**
 *
 * @param {Array} items
 *
 * @returns {Array}
 */
export const flatEntities = (
  items,
  isSuperManager,
  parent = '',
  lastInParent = true
) => {
  const flatArray = [];
  items.forEach((item, index) => {
    const rowSpan = calculateRowspans(item);
    const hasChildren =
      Array.isArray(item.children) && item.children.length > 0;
    const isLast =
      item.level === FIRST_ENTITY_LEVEL ||
      (index === items.length - 1 && lastInParent);
    flatArray.push({
      ...item,
      rowSpan,
      parent,
      isSuperManager,
      lastOne: isLast
    });
    if (hasChildren) {
      flatArray.push(
        ...flatEntities(item.children, isSuperManager, item.id, isLast)
      );
    }
  });

  return flatArray;
};

function calculateRowspans(item) {
  const isLeaf = !item.children || item.children.length === 0;

  if (isLeaf) {
    return 1;
  }
  let rowSpan = 0;
  for (let index = 0; index < item.children.length; index++) {
    const element = item.children[index];
    rowSpan += calculateRowspans(element);
  }
  return rowSpan;
}

/**
 * Grouping entities by level
 *
 * @param {Object.<string,IEntity>} entitiesByIds
 */
export function groupByLevel(entitiesByIds) {
  return Object.keys(entitiesByIds).reduce((byLevel, entityId) => {
    const currentEntity = entitiesByIds[entityId];
    if (byLevel.hasOwnProperty(currentEntity.level)) {
      byLevel[currentEntity.level].push(currentEntity);
    } else {
      byLevel[currentEntity.level] = [currentEntity];
    }
    return byLevel;
  }, {});
}

export function getEntityToAdd(newEntities) {
  let leaf = null;
  Object.keys(newEntities).forEach(level => {
    const currentLevel = parseFloat(level);
    const parentLevel = parseFloat(currentLevel - 1);
    const entity = newEntities[currentLevel];
    const parentEntity = newEntities[parentLevel];
    if (entity.enabled && parentEntity) {
      entity.parent = parentEntity;
    }
    if (parentEntity && parentEntity.enabled) {
      leaf = parentEntity;
    }
    if (entity.enabled && entity.level === LAST_ENTITY_LEVEL) {
      leaf = entity;
    }
    newEntities[currentLevel] = entity;
  });
  return leaf;
}

export function getCleanEntity(entity) {
  return cleanObject(entity, [
    'children',
    'parent',
    'createdAt',
    'updatedAt',
    'path',
    'isLeaf',
    'rowSpan'
  ]);
}

export function getPathForHuman(path, separator = ' > ') {
  return path
    .split(/([-][^-]*[|])+/)
    .filter((_, index) => index % 2 === 0 && index !== 0)
    .filter(Boolean)
    .join(separator);
}

export function getEntityFromPath(path, level) {
  const entities = path
    .split(/([-][^-]*[|])+/)
    .filter((_, index) => index % 2 === 0 && index !== 0)
    .filter(Boolean);

  return entities[level - 1];
}

/**
 *
 * @param {string} path
 *
 *
 * @returns {Array}
 */
export function getPathForMachine(path) {
  return path
    .split(/([-][^-]*[|])+/)
    .filter((_, index) => index % 2 !== 0 && index !== 0)
    .map(id => {
      var regex = /\|/gi;
      var regex1 = /-/gi;
      id = id.replace(regex, '');
      id = id.replace(regex1, '');
      return id;
    });
}

export function allExceptRoot(level) {
  return level.value !== ENTITY_LEVELS.ROOT.value;
}

/**
 * @returns {Array}
 */
export function getSiblings(all, me, parent) {
  return all.filter(entity => entity.parent === parent && entity.id !== me);
}

/**
 *
 * @param {IEntity} entity
 * @param {IEntity[]} all
 *
 * @returns {Boolean}
 */
export function isUniqueEntityName(entity, all) {
  const siblings = getSiblings(all, entity.id, entity.parent);
  return siblings.every(
    sibling => !equalString(sibling.name, entity.name, false)
  );
}

/**
 *
 * @param {IEntity} entity
 * @param {IEntity[]} all
 *
 * @returns {Object} - {'2': 'grandParent','3': 'parent','4': 'child'}
 */
export function buildEntityFlatTree(entity, all, tree = {}) {
  if (!entity) {
    return tree;
  } else if (entity && !entity.parent) {
    tree[entity.level] = entity.id;
    return tree;
  } else {
    tree[entity.level] = entity.id;
    const parent = all.find(other => other.id === entity.parent.id);
    return buildEntityFlatTree(parent, all, tree);
  }
}

export function reinitializeEntitiesBelow(
  handleChange,
  namePrefix,
  newEntityLevelValue,
  key = 'label'
) {
  Object.values(ENTITY_LEVELS).forEach(entityLevel => {
    // only entities / exclude company
    if (entityLevel[key] > newEntityLevelValue) {
      handleChange({
        target: {
          name: `${namePrefix}.${entityLevel[key]}`,
          value: ''
        }
      });
    }
  });
}
