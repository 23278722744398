class FormFieldError extends Error {
  constructor(field = 'bar', ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FormFieldError);
    }

    this.name = 'CustomError';
    // Custom debugging information
    this.field = field;
  }
}

export default FormFieldError;
