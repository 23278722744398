import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
export default (state = initialState.translations, action) => {
  switch (action.type) {
    case types.SET_TRANSLATION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.lang]: JSON.stringify(action.payload.translations)
        },
        updatedAt: Date.now()
      };
    default:
      return state;
  }
};
