import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { prefixPath } from '../../helpers';
import { LocalizerContext } from '../hoc/Localizer';
import cx from 'classnames';

function LocalizedLink({
  to,
  className,
  children,
  onClick,
  disabled,
  asButton,
  localize = true,
  ...rest
}) {
  const locale = useContext(LocalizerContext);
  if (asButton) {
    return (
      <NavLink
        className={className}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
        to={'#'}
        {...rest}
      >
        {children}
      </NavLink>
    );
  }
  return (
    <NavLink
      className={cx(className, {
        'disabled-link': disabled
      })}
      onClick={disabled ? null : onClick}
      to={disabled ? '#' : localize ? prefixPath(to, locale.lang) : to}
      {...rest}
    >
      {children}
    </NavLink>
  );
}

export default React.memo(LocalizedLink);
