import { USER_ROLES, ENTITY_LEVELS } from '../constants';
import { filterCompanyManagers, getLastSelectedEntity } from './userAcl';
import { SIMPLE_USERS } from '../constants/componentRoles';
import { getPathForMachine } from './entities';

/**
 * transform { name: "MANAGER_LEVEL_2", 1 :"123456", 2:"147852"}
 * to        { name: "MANAGER_LEVEL_2", entity :{id:"147852"}}
 *
 * @param {import('./userAcl').IRole[]} roles
 *
 * @returns {import('./userAcl').IRole[]}
 */
export function toUserShape(roles, company) {
  return roles.reduce((userRoles, roleItem) => {
    const selectedRole = USER_ROLES()[roleItem.name];
    if (roleItem.name === USER_ROLES().SUPER_MANAGER.value) {
      userRoles.push({ name: roleItem.name, entity: { id: company.id } });
    } else if (SIMPLE_USERS.includes(roleItem.name)) {
      const selectedEntityId = getLastSelectedEntity(roleItem);
      userRoles.push({ name: roleItem.name, entity: { id: selectedEntityId } });
    } else {
      const id = roleItem[selectedRole.levelLabel];
      userRoles.push({ name: roleItem.name, entity: { id } });
    }
    return userRoles;
  }, []);
}

/**
 *
 * @param {import('./users').IUser} values
 * @param {import('./users').ICompany} company
 *
 * @returns {import('./users').IInvitation}
 */
export function normalizeInvitation(values, company, allEntities) {
  const newInvitation = {
    ...values,
    families: values.families.map(id => ({ id })),
    roles: toUserShape(values.userRoles, company, allEntities)
  };

  delete newInvitation.userRoles;
  return newInvitation;
}

/**
 *
 * @param {import('./userAcl').IRole[]} roles
 * @param {Object.<string,import('./entities').IEntity>} entitiesByIds
 *
 * @returns {Array}
 */
export function toUIShape(roles, entitiesByIds) {
  return roles
    .filter(filterCompanyManagers)
    .reduce((userRoles, role, index) => {
      let roleItem = {
        ...role,
        index,
        1: '',
        2: '',
        3: ''
      };
      roleItem = setEntityLevel(roleItem, role.entity, entitiesByIds);

      userRoles.push(roleItem);
      return userRoles;
    }, []);
}

/**
 *
 * @param {Object} roleItem
 * @param {Object} entity
 * @param {Object} entities
 */
function setEntityLevel(roleItem, entity, entities) {
  // get entity from normalized entities
  const currentEntity = entities[entity.id];
  // get entity level and label
  const currentEntityLevel = Object.values(ENTITY_LEVELS).find(
    level => currentEntity.level === level.value
  );
  // setting entity id to to its right property on roleItem
  roleItem[currentEntityLevel.label] = entity.id;

  //doing the same thing for parent and so on
  if (currentEntity.parent && entities[currentEntity.parent].parent) {
    return setEntityLevel(roleItem, entities[currentEntity.parent], entities);
  } else {
    return roleItem;
  }
}

/**
 * return all parents *******ID****** in the tree recursivly
 *
 * @param {string} entityId
 * @param {Object.<string,import('./entities').IEntity>} entities
 */
export function getParents(entityId, entities) {
  const parents = [];
  const currentEntity = entities[entityId];
  if (currentEntity && currentEntity.parent) {
    parents.push(currentEntity.parent);
    parents.push(...getParents(currentEntity.parent, entities));
    return parents;
  } else {
    return parents;
  }
}

/**
 *
 * @param {import('./entities').IEntity} entity
 * @param {import('./userAcl').IRoleDefinition} role
 *
 * @returns {Object}
 */
export function createUserToInvite(entity, role) {
  const pathAsIds = getPathForMachine(entity.path);
  pathAsIds.shift();
  const roleItem = pathAsIds.reduce(
    (acc, curr, index) => {
      acc[index + 1] = curr;
      return acc;
    },
    { name: role.value, index: 0 }
  );
  const user = {
    firstName: '',
    lastName: '',
    email: '',
    userRoles: [roleItem],
    families: []
  };
  return user;
}
