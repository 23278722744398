import { t } from '../services/i18n';

export const BASE_API_URL = `${process.env.REACT_APP_BASE_URL}/api`;
export const BASE_IMAGE_URL = `${process.env.REACT_APP_BASE_URL}/api/resource`;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const isTest = process.env.NODE_ENV === 'test';
// #272727 Biotrace Color
export const THEME = {
  MAIN_COLOR: 'black',
  MAIN_COLOR_CODE: '#455A64',
  SECOND_COLOR: 'black',
  THIRD_COLOR: 'teal',
  DARK: {
    BACKGROUND: 'dark',
    FONT: 'white'
  },
  LIGHT: {
    BACKGROUND: 'light',
    FONT: 'black'
  }
};

export const DEFAULT_IMAGES = {
  MEMBER: '/images/user.png',
  IMAGE: '/images/default-image.svg'
};

export const DATES = {
  FORMAT: 'YYYY-MM-DD[T]HH:mm:ssZZ',
  en: {
    LONG: 'YYYY-MM-DD',
    HUMAN: 'YYYY-MM-DD - HH:mm',
    SHORT: 'MMM-YYYY',
    HOURS: 'HH:mm'
  },
  fr: {
    LONG: 'DD/MM/YYYY',
    HUMAN: 'DD/MM/YYYY - HH:mm',
    SHORT: 'MMM-YYYY',
    HOURS: 'HH:mm'
  },
  de: {
    LONG: 'DD/MM/YYYY',
    HUMAN: 'DD/MM/YYYY - HH:mm',
    SHORT: 'MMM-YYYY',
    HOURS: 'HH:mm'
  },

  DEFAULT_TIMEZONE: 'Africa/Tunis'
};
export const PASSWORD_MIN_LENGTH = 6;
export const EXCEL_TYPE_ACCEPT =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const ITEMS_PER_PAGE = 10;
export const ENTITY_LEVELS = {
  ROOT: {
    label: 'Company',
    value: 1
  },
  LEVEL_1: {
    label: 1,
    value: 2
  },
  LEVEL_2: {
    label: 2,
    value: 3
  },
  LEVEL_3: {
    label: 3,
    value: 4
  }
};
export const LAST_ENTITY_LEVEL = ENTITY_LEVELS.LEVEL_3.value;
export const FIRST_ENTITY_LEVEL = ENTITY_LEVELS.LEVEL_1.value;

export const USER_ROLES = () => ({
  OWNER: {
    label: t('manage_users.roles.manager_company'),
    value: 'OWNER',
    level: ENTITY_LEVELS.ROOT.value,
    levelLabel: ENTITY_LEVELS.ROOT.label,
    hidden: true
  },
  SUPER_ADMIN: {
    label: 'Super admin',
    value: 'SUPER_ADMIN',
    level: ENTITY_LEVELS.ROOT.value,
    levelLabel: ENTITY_LEVELS.ROOT.label,
    hidden: true
  },
  SUPER_MANAGER: {
    label: t('manage_users.roles.manager_company'),
    value: 'SUPER_MANAGER',
    level: ENTITY_LEVELS.ROOT.value,
    levelLabel: ENTITY_LEVELS.ROOT.label
  },
  MANAGER_LEVEL_1: {
    label: t('manage_users.roles.manager_level1'),
    value: 'MANAGER_LEVEL_1',
    level: ENTITY_LEVELS.LEVEL_1.value,
    levelLabel: ENTITY_LEVELS.LEVEL_1.label
  },
  MANAGER_LEVEL_2: {
    label: t('manage_users.roles.manager_level2'),
    value: 'MANAGER_LEVEL_2',
    level: ENTITY_LEVELS.LEVEL_2.value,
    levelLabel: ENTITY_LEVELS.LEVEL_2.label
  },
  CONTROLLER: {
    label: t('manage_users.roles.controller'),
    value: 'CONTROLLER',
    level: ENTITY_LEVELS.LEVEL_3.value,
    levelLabel: ENTITY_LEVELS.LEVEL_3.label,
    anywhere: true
  },
  GUEST: {
    label: t('manage_users.roles.guest'),
    value: 'GUEST',
    level: ENTITY_LEVELS.LEVEL_3.value,
    levelLabel: ENTITY_LEVELS.LEVEL_3.label,
    anywhere: true
  }
});

export const ACCOUNT_TYPE = {
  ACTIVATED_ACCOUNT: 'ACTIVATED',
  BANNED_ACCOUNT: 'BANNED',
  ON_WAITING_ACCOUNT: 'ON_WAITING'
};
export const MESSAGES = {
  FR: {}
};
export const MEDIA_SIZE = {
  'col-12': {
    width: {
      big: 780,
      tiny: 250,
      medium: 550
    },
    height: {
      big: 500,
      tiny: 150,
      medium: 350
    }
  },
  'col-6': {
    width: {
      big: 390,
      tiny: 125,
      medium: 225
    },
    height: {
      big: 250,
      tiny: 75,
      medium: 175
    }
  }
};

export const LOCAL_STORAGE = {
  AUTH: 'biotrace_token',
  USER: 'biotrace_user',
  LAST_GET_ME: 'biotrace_last_get_me'
};

export const APP_VERSION = '1.0.0';

export const KEYS = {
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  ESCAPE: 27
};

export const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const SUPPORTED_DOCUMENT_FORMATS = [
  'application/pdf',
  'application/octet-stream'
];
export const SMOOTH = { top: 0, left: 0, behavior: 'smooth' };
export const MODALS = {
  EXPORT_PDF_MODAL: 'PDF_MODAL',
  EXPORT_XLSX_MODAL: 'XLSX_MODAL'
};

export default {
  BASE_API_URL,
  BASE_IMAGE_URL,
  THEME,
  DEFAULT_IMAGES,
  DATES,
  ITEMS_PER_PAGE,
  ACCOUNT_TYPE,
  MESSAGES,
  MEDIA_SIZE,
  LOCAL_STORAGE,
  APP_VERSION
};
