import React from 'react';
import LocalizedLink from './shared/LocalizedLink';

const NotFound = props => {
  return (
    <div className="four-zero-four">
      <div className="four-zero-four-container">
        <div className="error-code">404</div>
        <div className="error-message">This page doesn't exist</div>
        <div className="button-place">
          <LocalizedLink
            to={'/'}
            className="btn btn-default btn-lg waves-effect"
          >
            GO TO HOMEPAGE
          </LocalizedLink>
        </div>
      </div>
    </div>
  );
};

NotFound.propTypes = {};

export default NotFound;
