import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { setUiLocale, initializeI18n } from '../../services/i18n';
import { getLocaleFromPath, detectLocale } from '../../services/i18n/util';
import { defaultLocale, locales } from '../../constants/i18n';
import { prefixPath } from '../../helpers';
// import Spinner from '../shared/Spinner';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTranslation } from '../../modules/translations/action';
import moment from 'moment';

export const LocalizerContext = React.createContext({
  lang: defaultLocale,
  setLocale: () => {},
  isLocalized: () => {},
  localizePath: prefixPath
});

class Localizer extends PureComponent {
  constructor(...args) {
    super(...args);
    const languageToUse = detectLocale();
    this.state = {
      isLocalizing: false,
      lang: languageToUse,
      setLocale: this.setLocale,
      localizePath: this.localizePath,
      isLocalized: this.isLocalized
    };
    this._isUnmounted = false;
  }

  componentDidMount() {
    const { lang } = this.state;
    // first we set the language that we detect /user > browser default
    this.setLocale(lang, true);

    // then we use that one from the url
    this.props.history.listen(location => {
      this.setLocale(getLocaleFromPath(location.pathname));
    });
  }

  /**
   * Set the lang and dir attributes in the <html> DOM element, and
   * initialize our i18n UI library.
   *
   * @param {string} newLocale
   * @param {bool} force
   */
  setLocale = (newLocale, force = false) => {
    const { lang } = this.state;
    if (force || newLocale !== lang) {
      if (newLocale === 'auto') {
        newLocale = lang;
      }
      this.changeLocale(newLocale);
      this.flipActivityIndicator();
      const { translations } = this.props;
      const now = Date.now();
      const diff = moment(now).diff(moment(translations.updatedAt), 'days');
      // console.log('DIFF FROM LAST TRAD FILE IN DAYS', diff);
      if (
        translations.updatedAt === 'yoyo' && //todo remove this
        diff < 1 &&
        translations.data.hasOwnProperty(newLocale)
      ) {
        console.log('READ TRAD FROM CACHE ...');
        const translationJson =
          typeof translations.data[newLocale] === 'string'
            ? JSON.parse(translations.data[newLocale])
            : translations.data[newLocale];
        // read from cache
        initializeI18n(newLocale, translationJson)
          .then(this.flipActivityIndicator)
          .catch(this.flipActivityIndicator);
      } else {
        setUiLocale(newLocale)
          .then(this.handleOnResolve)
          .then(this.flipActivityIndicator)
          .catch(this.flipActivityIndicator);
      }

      // i make this TO load different package for different language (eg moment locale)
      this.importRequiredModulesForLocale(newLocale);
    }
  };

  /**
   * @todo
   * Cache trad
   */
  handleOnResolve = ({ loadedResources, locale }) => {
    this.props.setTranslation(locale, loadedResources);
  };

  importRequiredModulesForLocale = async locale => {
    const moment = await import('moment');
    if (locale === 'fr') {
      await import('moment/locale/fr');
      await moment.default.locale('fr');
    } else if (locale === 'de') {
      await import('moment/locale/de');
      await moment.default.locale('de');
    }
  };

  flipActivityIndicator = () => {
    this.safeSetState(state => {
      return { ...state, isLocalizing: !state.isLocalizing };
    });
  };

  changeLocale = newLocale => {
    this.safeSetState(state => {
      return { ...state, lang: newLocale };
    });
  };

  safeSetState = (...args) => {
    if (this._isUnmounted === false) {
      this.setState(...args);
    }
  };

  localizePath = (path, prefix = this.state.lang) => {
    return prefixPath(path, prefix);
  };

  /**
   * @param {String} path
   *
   * @returns {Boolean}
   */
  isLocalized = path => {
    const prefixes = locales.map(locale => `/${locale.code}/`);
    return prefixes.some(prefix => path.startsWith(prefix));
  };

  componentWillUnmount = () => {
    this._isUnmounted = true;
  };

  render() {
    // if (isLocalizing) {
    //   return null;
    // }
    return (
      <LocalizerContext.Provider value={this.state}>
        {this.props.children}
      </LocalizerContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translations
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setTranslation }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Localizer));
