import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
export default (state = initialState.barChartFilters, action) => {
  switch (action.type) {
    case types.CHANGE_BAR_CHART_FILTER:
      return {
        ...state,
        [action.user]: {
          ...state[action.user],
          ...action.payload
        }
      };
    default:
      return state;
  }
};
