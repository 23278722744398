import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';
import { transformOrganization, groupByLevel } from '../../services/entities';
import { getCurrentUser } from '../../services/auth';
import { mapIntoObject } from '../../helpers/transformer';

export default (state = initialState.entities, action) => {
  switch (action.type) {
    case types.FETCH_ENTITIES_REQUEST:
    case types.FETCH_CHILDREN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: null
      };
    case types.SAVE_ENTITY_REQUEST:
    case types.UPDATE_ENTITY_REQUEST:
    case types.DELETE_ENTITY_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: false,
        message: null
      };

    case types.FETCH_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        data: {
          list: action.payload.byIndexes,
          normalized: action.payload.entities.entities,
          ids: action.payload.result,
          byLevels: groupByLevel(action.payload.entities.entities)
        },
        message: action.message || t('default_success_message')
      };
    case types.FETCH_CHILDREN_SUCCESS:
      const currentUser = getCurrentUser();
      const flatEntities = transformOrganization(
        action.payload.byIndexes,
        currentUser
      );
      const normalizedEntitiesByIDs = mapIntoObject(flatEntities, 'id');
      return {
        ...state,
        isLoading: false,
        error: false,
        byChildren: {
          list: action.payload.byIndexes,
          normalized: normalizedEntitiesByIDs,
          ids: action.payload.result,
          byLevels: groupByLevel(normalizedEntitiesByIDs),
          flat: flatEntities
        },
        message: action.message || t('default_success_message')
      };

    case types.SAVE_ENTITY_SUCCESS:
    case types.UPDATE_ENTITY_SUCCESS:
    case types.DELETE_ENTITY_SUCCESS:
      return {
        ...state,
        isSaving: false,
        error: false,
        message: action.message || t('default_success_message')
      };
    case types.FETCH_ENTITIES_FAILURE:
    case types.FETCH_CHILDREN_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
        message: action.message || t('default_error_message')
      };
    case types.SAVE_ENTITY_FAILURE:
    case types.UPDATE_ENTITY_FAILURE:
    case types.DELETE_ENTITY_FAILURE:
      return {
        ...state,
        error: true,
        isSaving: false,
        message: action.message || t('default_error_message')
      };
    default:
      return state;
  }
};
