import React from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-recaptcha';
import ColorfulSpinner from '../shared/ColorfulSpinner';
import Input from '../shared/Input';
import { t } from '../../services/i18n';
import Button from '../shared/Button';
import { Row } from '../shared/Grid';
import { RECAPTCHA_SITE_KEY, isTest } from '../../constants';
import { Formik } from 'formik';
import { showNotification } from '../../helpers/notification';
import FormValidation from '../../services/FormValidation';
import LocalizedLink from '../shared/LocalizedLink';
import { LocalizerContext } from '../hoc/Localizer';
import { getFirstBrowserLanguage } from '../../helpers';
import { toUnicode } from 'punycode';

class SignUpForm extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isLoggingIn: PropTypes.bool
  };

  static contextType = LocalizerContext;

  constructor(...args) {
    super(...args);
    this.state = {
      credentials: {
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        plainPassword: '',
        language: getFirstBrowserLanguage(),
        confirmPlainPassword: '',
        terms: false,
        gdpr: false
      },
      isVerified: isTest
    };

    this.signUpSchema = new FormValidation('SignUpForm');
  }

  onSubmit = values => {
    const { isVerified } = this.state;
    if (isVerified) {
      this.props
        .onSubmit(
          { ...values, email: toUnicode(values.email.toLowerCase()) },
          isVerified
        )
        .catch(error => {
          this.recaptchaInstance.reset();
        });
    } else {
      showNotification(t('signup_page.invalide_captcha'), {
        warning: true
      });
    }
  };

  get errorMessage() {
    const { message } = this.props;
    if (message) {
      return <span className="col-red">{message}</span>;
    }
    return null;
  }

  verifyCallback = response => {
    this.setState((prevState, props) => {
      return {
        isVerified: response
      };
    });
  };

  render() {
    const { isLoggingIn } = this.props;
    const { credentials } = this.state;
    const { lang } = this.context;
    return (
      <Formik
        initialValues={credentials}
        validationSchema={this.signUpSchema.getValidationSchema}
        onSubmit={this.onSubmit}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <form id="sign_in_form" onSubmit={handleSubmit}>
            <div className="msg align-center">
              <div style={{ textTransform: 'uppercase' }}>
                {t('signup_page.title')}
              </div>
              {/* <p className="error-msg">{this.errorMessage}</p> */}
            </div>
            <Input
              name="firstName"
              value={values.firstName}
              message={
                errors.firstName && touched.firstName && errors.firstName
              }
              type="text"
              placeholder={`${t('signup_page.firstName_placeholder')}*`}
              onChange={handleChange}
              wrapperClass="input-group"
              autoFocus
            />
            <Input
              name="lastName"
              value={values.lastName}
              message={errors.lastName && touched.lastName && errors.lastName}
              type="text"
              placeholder={`${t('signup_page.lastName_placeholder')}*`}
              onChange={handleChange}
              wrapperClass="input-group"
            />
            <Input
              type="text"
              name="companyName"
              value={values.companyName}
              message={
                errors.companyName && touched.companyName && errors.companyName
              }
              placeholder={`${t('signup_page.company_name_placeholder')}*`}
              onChange={handleChange}
              wrapperClass="input-group"
            />
            <Input
              type="email"
              name="email"
              value={values.email}
              message={errors.email && touched.email && errors.email}
              placeholder={`${t('signup_page.email_placeholder')}*`}
              onChange={handleChange}
              wrapperClass="input-group"
            />
            <Input
              type="password"
              name="plainPassword"
              value={values.plainPassword}
              message={
                errors.plainPassword &&
                touched.plainPassword &&
                errors.plainPassword
              }
              autoComplete="new-password"
              placeholder={`${t('signup_page.password_placeholder')}*`}
              onChange={handleChange}
              wrapperClass="input-group"
            />
            <Input
              type="password"
              name="confirmPlainPassword"
              value={values.confirmPlainPassword}
              message={
                errors.confirmPlainPassword &&
                touched.confirmPlainPassword &&
                errors.confirmPlainPassword
              }
              autoComplete="new-password"
              placeholder={`${t('signup_page.confirm_password_placeholder')}*`}
              onChange={handleChange}
              wrapperClass="input-group"
            />
            <Input
              id="terms"
              type="checkbox"
              name="terms"
              checked={values.terms}
              onChange={handleChange}
              message={errors.terms && touched.terms && errors.terms}
              className="filled-in chk-col-pink"
              withLine={false}
              label={
                <LocalizedLink to={'/outside/cg'} target="_blank">
                  {t('signup_page.cg')}
                </LocalizedLink>
              }
            />
            <Input
              id="gdpr"
              name="gdpr"
              checked={values.gdpr}
              onChange={handleChange}
              message={errors.gdpr && touched.gdpr && errors.gdpr}
              type="checkbox"
              className="filled-in chk-col-pink"
              withLine={false}
              label={
                <LocalizedLink to={'/outside/policy'} target="_blank">
                  {t('signup_page.policy')}
                </LocalizedLink>
              }
            />
            <Recaptcha
              hl={lang}
              ref={e => (this.recaptchaInstance = e)}
              elementID="recaptchaId"
              sitekey={RECAPTCHA_SITE_KEY}
              render="explicit"
              verifyCallback={this.verifyCallback}
              theme="light"
            />
            <Button
              id="submitBtn"
              className="btn-block bg-pink m-t-20"
              type="submit"
              disabled={isLoggingIn}
            >
              {t('signup_page.sign_btn')}
            </Button>
            <Row className="m-t-15  align-center">
              {isLoggingIn && <ColorfulSpinner />}
            </Row>
            <Row>
              <p className="font-12 pull-right m-r-15">
                * {t('form_validation.required_field')}
              </p>
            </Row>
            <Row className="text-center">
              <LocalizedLink to="/login">
                {t('signup_page.login_link')}
              </LocalizedLink>
            </Row>
          </form>
        )}
      </Formik>
    );
  }
}

export default SignUpForm;
