import { defaultLocale, locales } from '../../constants/i18n';
import { prefixPath, getFirstBrowserLanguage } from '../../helpers';
import { getCurrentUser } from '../auth';

export function currentLocalizedRoute(pathname, locale) {
  const arr = pathname.split('/');
  arr[1] = locale;
  return arr.join('/');
}
export function localizeRoutes(routes) {
  return routes.map(route => {
    // we default to localizing
    if (route.localize !== false) {
      return {
        ...route,
        path: prefixPath(route.path, ':locale')
      };
    }

    return route;
  });
}

export function getLocaleFromPath(path) {
  if (path === '/') {
    return defaultLocale;
  }

  return path.split('/')[1];
}

/**
 * @return {String}
 */
export function detectLocale() {
  const currentUser = getCurrentUser();
  if (currentUser === undefined || currentUser.language === 'auto') {
    return getFirstBrowserLanguage();
  }
  return currentUser.language;
}

/**
 *
 * @param {String} code
 * @returns {String}
 */
export function getLanguageByCode(code) {
  const langCode = code === 'auto' ? defaultLocale : code;
  const lang = locales.find(local => local.code === langCode);
  return lang ? lang.name : locales[1].name;
}
