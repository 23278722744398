import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Button({
  children,
  noBtn = false,
  type = 'button',
  className,
  forwardedRef,
  ...rest
}) {
  return (
    <button
      ref={forwardedRef}
      type={type}
      className={classNames({ btn: !noBtn }, className, 'waves-effect')}
      {...rest}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
};

export default React.forwardRef((props, ref) => {
  return <Button {...props} forwardedRef={ref} />;
});
