import { getFormValue } from '../../helpers/form';
import { setToObj } from '../../helpers';
import { t } from '../../services/i18n';

// possible fields
export const MULTI_CHECKBOX = 'checkboxGroup';
export const TEXT_INPUT = 'text';
export const DATE_INPUT = 'date';
export const RADIO_BOX = 'choiceGroup';
export const SELECT_INPUT = 'selectBox';
export const TEXTAREA = 'textarea';
export const CHECKBOX = 'checkbox';
export const FILE = 'file';

// form builder type
export const MULTIPLE_OPTION_BUILDER = 'MultipleOptionBuilder';
export const INPUT_BUILDER = 'InputBuilder';

// all possible types for an input tag
export const SIMPLE_INPUT_TYPES = () => [
  { label: t('form_builder.input_types.text'), value: 'text' },
  { label: t('form_builder.input_types.number'), value: 'number' }
  // { label: t('form_builder.input_types.email'), value: 'email' },
  // { label: t('form_builder.input_types.url'), value: 'url' }
  // { label: 'color', value: 'color' }
];

// possible accepted file extensions
export const FILE_TYPES = () => [
  {
    label: t('form_builder.file_types.image'),
    id: 'Image',
    value: ['.jpg', '.jpeg', '.png']
  },
  {
    label: t('form_builder.file_types.document'),
    id: 'Document',
    value: [
      '.pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      '.docx',
      '.doc'
    ]
  }
];
// { label: 'PDF', value: ['.pdf'] },
// { label: 'Excel', value: ['.xlsx', '.xls'] },
// { label: 'Word', value: ['.docx', '.doc'] }

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  opacity: isDragging ? 0.8 : 1,
  background: isDragging ? 'white' : '',
  // styles we need to apply on draggables
  ...draggableStyle
});

export const getListStyle = isDraggingOver => ({
  background: 'white',
  border: isDraggingOver ? '1px dashed' : 'transparent'
  // background: isDraggingOver ? 'grey' : 'transparent',
});

export const SET_NEW_VALUE_ACTION = 'SET_VALUE';
export const ADD_OPTION_ACTION = 'ADD_OPTION';
export const REMOVE_OPTION_ACTION = 'REMOVE_OPTION';

export function useFieldReducer(action, prevState) {
  let newState = [...prevState];
  const oldField = prevState.find(
    field => field.fieldKey === action.payload.fieldKey
  );

  if (action.type === SET_NEW_VALUE_ACTION) {
    const value = getFormValue(action.target);
    const newField = setToObj(oldField, action.target.name, value);
    newState = prevState.map(field =>
      field.fieldKey !== action.payload.fieldKey ? field : newField
    );
  } else if (action.type === ADD_OPTION_ACTION) {
    const newOptionIndex = oldField.uiSchema.options.length;
    newState = prevState.map(field =>
      field.fieldKey !== action.payload.fieldKey
        ? field
        : {
            ...field,
            uiSchema: {
              ...field.uiSchema,
              options: [
                ...field.uiSchema.options,
                {
                  value: `Option ${newOptionIndex + 1}`,
                  key: newOptionIndex + 1
                }
              ]
            }
          }
    );
  } else if (action.type === REMOVE_OPTION_ACTION) {
    newState = prevState.map(field =>
      field.fieldKey !== action.payload.fieldKey
        ? field
        : {
            ...field,
            uiSchema: {
              ...field.uiSchema,
              options: field.uiSchema.options.filter(
                (_, index) => index !== action.payload.itemIndex
              )
            }
          }
    );
  }
  return newState;
}
