import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DEVICE_SIZES = ['xl', 'lg', 'md', 'sm', 'xs'];
const colSize = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.oneOf(['auto'])
]);

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

const column = PropTypes.oneOfType([
  colSize,
  PropTypes.shape({
    size: colSize,
    order: stringOrNumber,
    offset: stringOrNumber
  })
]);

export class Col extends React.PureComponent {
  static propTypes = {
    /**
     * @default 'col'
     */
    bsPrefix: PropTypes.string,

    /**
     * The number of columns to span on sxtra small devices (<576px)
     *
     * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
     */
    xs: column,

    /**
     * The number of columns to span on small devices (≥576px)
     *
     * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
     */
    sm: column,

    /**
     * The number of columns to span on medium devices (≥768px)
     *
     * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
     */
    md: column,

    /**
     * The number of columns to span on large devices (≥992px)
     *
     * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
     */
    lg: column,

    /**
     * The number of columns to span on extra large devices (≥1200px)
     *
     * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
     */
    xl: column
  };

  static defaultProps = {
    as: 'div',
    bsPrefix: 'col',
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
  };

  render() {
    const { bsPrefix, className, as: Component, all, ...props } = this.props;

    const spans = [];
    const classes = [];

    DEVICE_SIZES.forEach(brkPoint => {
      let propValue = props[brkPoint];
      delete props[brkPoint];
      if (all !== undefined) {
        propValue = all;
      }
      let span, offset, order;
      if (propValue != null && typeof propValue === 'object') {
        ({ span = true, offset, order } = propValue);
      } else {
        span = propValue;
      }

      let infix = `-${brkPoint}`;

      if (span != null) {
        spans.push(
          span === true ? `${bsPrefix}${infix}` : `${bsPrefix}${infix}-${span}`
        );
      }

      if (order != null) {
        classes.push(`order${infix}-${order}`);
      }
      if (offset != null) {
        classes.push(`col${infix}-offset-${offset}`);
      }
      if (brkPoint === 'sm') {
        spans.push(`col-${span}`);
      }
    });
    if (!spans.length) {
      spans.push(bsPrefix); // plain 'col'
    }

    return (
      <Component
        {...props}
        className={classNames(...spans, ...classes, className)}
      />
    );
  }
}

export class Row extends React.PureComponent {
  render() {
    const { className, ...rest } = this.props;

    return <div className={classNames('row clearfix', className)} {...rest} />;
  }
}
