import React from 'react';
import PropTypes from 'prop-types';
import Card, { CardBody } from '../shared/Card';
import ColorfulSpinner from '../shared/ColorfulSpinner';
import { t } from '../../services/i18n';
import LocalizedLink from '../shared/LocalizedLink';
import Input from '../shared/Input';
import Button from '../shared/Button';
import { Col, Row } from '../shared/Grid';
import { PASSWORD_MIN_LENGTH } from '../../constants';
import { MdLock } from 'react-icons/md';

class ForgetPwdForm extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool
  };

  constructor(...args) {
    super(...args);
    this.state = {
      credentials: { plain_password: '', confirmPlainPassword: '' },
      errors: { confirmPlainPassword: '' }
    };
  }

  onSubmit = event => {
    event.preventDefault();
    const { credentials } = this.state;
    if (credentials.confirmPlainPassword !== credentials.plain_password) {
      this.setError(t('form_validation.password_match'));
    } else if (
      credentials.plain_password.length < PASSWORD_MIN_LENGTH ||
      credentials.confirmPlainPassword.length < PASSWORD_MIN_LENGTH
    ) {
      this.setError(
        t('form_validation.min_length', { length: PASSWORD_MIN_LENGTH })
      );
    } else {
      this.setError();
      this.props.onSubmit(credentials);
    }
  };

  onChange = ({ target }) => {
    this.setState(prevState => ({
      credentials: { ...prevState.credentials, [target.name]: target.value }
    }));
  };

  setError(error = '') {
    this.setState({
      errors: { confirmPlainPassword: error }
    });
  }

  get errorMessage() {
    const { message } = this.props;
    if (message) {
      return <span className="col-red">{message}</span>;
    }
    return null;
  }

  render() {
    const { isSubmitting } = this.props;
    const { errors } = this.state;
    return (
      <Card>
        <CardBody>
          <form onSubmit={this.onSubmit}>
            <div className="msg align-center">
              <div style={{ textTransform: 'uppercase' }}>
                {t('forget_password_page.title')}
              </div>
              {/* <p className="m-t-10 text-center">{this.errorMessage}</p> */}
            </div>
            <Input
              type="password"
              autoComplete="new-password"
              name="plain_password"
              placeholder={t('forget_password_page.password_placeholder')}
              onChange={this.onChange}
              wrapperClass="input-group"
              icon={
                <span className="input-group-addon">
                  <MdLock size={24} />
                </span>
              }
              hasError={!!this.errorMessage || !!errors['confirmPlainPassword']}
              required
            />
            <Input
              type="password"
              autoComplete="new-password"
              name="confirmPlainPassword"
              placeholder={t(
                'forget_password_page.confirm_password_placeholder'
              )}
              onChange={this.onChange}
              wrapperClass="input-group"
              icon={
                <span className="input-group-addon">
                  <MdLock size={24} />
                </span>
              }
              hasError={!!this.errorMessage || !!errors['confirmPlainPassword']}
              message={errors['confirmPlainPassword']}
              required
            />
            <Row>
              <Col>
                <Button
                  className="btn-block bg-pink"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t('forget_password_page.reset_btn')}
                </Button>
              </Col>
            </Row>
            {isSubmitting && (
              <Row className="text-center m-t-15">
                <ColorfulSpinner />
              </Row>
            )}
            <hr />
            <div className="align-center">
              <LocalizedLink to={'/login'}>
                {t('forget_password_page.login_link')}
              </LocalizedLink>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default ForgetPwdForm;
