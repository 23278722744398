import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
export default (state = initialState.filters, action) => {
  switch (action.type) {
    case types.CHANGE_FILTER:
    case types.INITIALIZE_FILTERS:
      return {
        ...state,
        [action.user]: {
          ...state[action.user],
          ...action.payload
        }
      };
    case types.CHANGE_SORT:
      return {
        ...state,
        sort: {
          ...state.sort,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
