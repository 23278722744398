import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../modules/auth/action';

class Logout extends React.Component {
  componentDidMount() {
    this.props.logout();
    // window.location.href = '/fr/login'; //TODO change this
    this.props.history.push('/fr/login');
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
