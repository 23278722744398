import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';

export default (state = initialState.auth, action) => {
  switch (action.type) {
    case types.SIGN_IN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        redirectFrom: null,
        error: false,
        message: null
      };
    case types.SIGN_UP_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        authObj: null,
        error: false,
        message: null
      };
    case types.FETCH_ME_REQUEST:
      return {
        ...state,
        isGettingMe: true,
        error: false,
        message: null
      };
    case types.RESET_PASSWORD_REQUEST:
    case types.UPDATE_PASSWORD_REQUEST:
    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isUpdating: true,
        authObj: null,
        error: false,
        message: null
      };
    case types.FETCH_ME_SUCCESS:
      return {
        ...state,
        isGettingMe: false,
        user: action.payload
      };
    case types.SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        message: action.message || t('default_success_message'),
        authObj: action.payload
      };
    case types.VALIDATE_ACCOUNT_SUCCESS:
    case types.SIGN_IN_SUCCESS:
    case types.LOAD_USER_FROM_LOCAL:
      return {
        ...state,
        isLoggingIn: false,
        authenticated: true,
        user: action.payload,
        redirectFrom: action.redirectFrom
      };
    case types.RESET_PASSWORD_SUCCESS:
    case types.UPDATE_PASSWORD_SUCCESS:
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        authObj: action.payload,
        message: action.message || t('default_success_message')
      };
    case types.FETCH_ME_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        user: null,
        error: true,
        authenticated: false,
        message: action.message || t('default_error_message')
      };
    case types.SIGN_UP_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        error: true,
        message: action.message || t('default_error_message')
      };
    case types.UPDATE_PASSWORD_FAILURE:
    case types.CHANGE_PASSWORD_FAILURE:
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: true,
        message: action.message || t('default_error_message')
      };
    case types.SIGN_IN_FAILURE:
      return {
        ...state,
        error: true,
        isLoggingIn: false,
        user: {},
        authenticated: false,
        message: action.message || t('default_error_message')
      };
    case types.LOG_OUT_SUCCESS:
      return {
        ...state,
        error: true,
        isLoggingIn: false,
        user: {},
        authenticated: false
      };
    case '@@router/LOCATION_CHANGE':
      return { ...state, error: false, message: null };
    default:
      return state;
  }
};
