import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';

export default (state = initialState.controlTypes, action) => {
  switch (action.type) {
    // CONTROL_TYPES
    case types.CHECK_CT_NAME_REQUEST:
      return {
        ...state,
        isChecking: true,
        canUseName: false,
        error: false,
        message: null
      };
    case types.CHECK_CT_NAME_SUCCESS:
      return {
        ...state,
        isChecking: false,
        canUseName: action.payload
      };
    case types.CHECK_CT_NAME_FAILURE:
      return {
        ...state,
        isChecking: false,
        canUseName: false
      };
    case types.PAGINATE_CONTROL_TYPE_REQUEST:
    case types.FETCH_CONTROL_TYPE_REQUEST:
    case types.GET_CONTROL_TYPE_REQUEST:
    case types.GET_CT_BY_EQUIPMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: null
      };
    case types.SAVE_CONTROL_TYPE_REQUEST:
    case types.UPDATE_CONTROL_TYPE_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: false,
        message: null
      };

    case types.DELETE_CONTROL_TYPE_REQUEST:
      return {
        ...state,
        isDeleting: true,
        error: false,
        message: null
      };
    case types.SAVE_CONTROL_TYPE_SUCCESS:
    case types.UPDATE_CONTROL_TYPE_SUCCESS:
      return saveControlTypeSuccess(state, action);
    case types.GET_CONTROL_TYPE_SUCCESS:
      return getControlTypeSuccess(state, action);
    case types.PAGINATE_CONTROL_TYPE_SUCCESS:
      return paginateControlTypeSuccess(state, action);
    case types.FETCH_CONTROL_TYPE_SUCCESS:
      return fetchControlTypesSuccess(state, action);
    case types.GET_CT_BY_EQUIPMENT_SUCCESS:
      return getControlTypesByEquipmentSuccess(state, action);
    case types.DELETE_CONTROL_TYPE_SUCCESS:
      return deleteControlTypeSuccess(state, action);
    case types.GET_CONTROL_TYPE_FAILURE:
    case types.PAGINATE_CONTROL_TYPE_FAILURE:
    case types.FETCH_CONTROL_TYPE_FAILURE:
    case types.GET_CT_BY_EQUIPMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: action.message || t('default_error_message')
      };
    case types.SAVE_CONTROL_TYPE_FAILURE:
    case types.UPDATE_CONTROL_TYPE_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: true,
        message: action.message || t('default_error_message')
      };
    case types.DELETE_CONTROL_TYPE_FAILURE:
      return {
        ...state,
        error: true,
        isDeleting: false,
        message: action.message || t('default_error_message')
      };
    default:
      return state;
  }
};
function fetchControlTypesSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    data: {
      ...state.data,
      normalized: action.payload.entities.controlTypes,
      all: action.payload.byIndexes
    }
  };
}
function getControlTypesByEquipmentSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    data: {
      ...state.data,
      byEquipments: action.payload
    }
  };
}

function saveControlTypeSuccess(state, action) {
  return {
    ...state,
    isSaving: false,
    error: false,
    data: {
      ...state.data,
      success: action.payload
    },
    message: action.message || t('default_success_message')
  };
}

function getControlTypeSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    data: {
      ...state.data,
      byId: action.payload
    },
    message: action.message || t('default_success_message')
  };
}

function paginateControlTypeSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    message: action.message || t('default_success_message'),
    meta: {
      ...state.meta,
      ...action.meta
    },
    data: {
      ...state.data,
      list: action.payload
    }
  };
}

function deleteControlTypeSuccess(state, action) {
  return {
    ...state,
    isDeleting: false,
    error: false,
    message: action.message || t('default_success_message'),
    data: {
      ...state.data,
      list: state.data.list.filter(cType => cType.id !== action.payload)
    },
    meta: {
      ...state.meta,
      total: state.meta.total - 1
    }
  };
}
