import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';
import { sortByDate, isEmpty } from '../../helpers';
import { DATES } from '../../constants';
export default (state = initialState.equipments, action) => {
  switch (action.type) {
    case types.GET_EQUIPMENT_REQUEST:
    case types.FETCH_EQUIPMENT_REQUEST:
      return getEquipmentRequest(state);
    case types.SAVE_EQUIPMENT_REQUEST:
    case types.UPDATE_EQUIPMENT_REQUEST:
    case types.TOGGLE_ARCHIVE_REQUEST:
      return saveEquipmentRequest(state);
    case types.DELETE_EQUIPMENT_REQUEST:
      return deleteEquipmentRequest(state, action);
    case types.HAS_CONTROLS_REQUEST:
      return {
        ...state,
        isChecking: true
      };
    case types.CHECK_EQUIPMENT_REQUEST:
      return {
        ...state,
        isChecking: true,
        data: {
          ...state.data,
          canUse: {
            ...state.data.canUse,
            [action.payload.attribute]: null
          }
        }
      };
    case types.SAVE_EQUIPMENT_SUCCESS:
      return saveEquipmentSuccess(state, action);
    case types.UPDATE_EQUIPMENT_SUCCESS:
      return updateEquipmentSuccess(state, action);
    case types.TOGGLE_ARCHIVE_SUCCESS:
      return toggleArchivedSuccess(state, action);
    case types.DELETE_EQUIPMENT_SUCCESS:
      return deleteEquipmentSuccess(state, action);
    case types.GET_EQUIPMENT_SUCCESS:
      return getEquipmentSuccess(state, action);
    case types.FETCH_EQUIPMENT_SUCCESS:
      return fetchEquipmentSuccess(state, action);
    case types.CHECK_EQUIPMENT_SUCCESS:
      return checkEquipmentUnicitySuccess(state, action);
    case types.HAS_CONTROLS_SUCCESS:
      return {
        ...state,
        isChecking: false,
        hasControl: action.payload
      };
    case types.HAS_CONTROLS_FAILURE:
      return {
        ...state,
        isChecking: false,
        hasControl: false
      };
    case types.GET_EQUIPMENT_FAILURE:
      return getEquipmentFailure(state, action);
    case types.UPDATE_EQUIPMENT_FAILURE:
    case types.DELETE_EQUIPMENT_FAILURE:
    case types.TOGGLE_ARCHIVE_FAILURE:
    case types.SAVE_EQUIPMENT_FAILURE:
    case types.FETCH_EQUIPMENT_FAILURE:
      return equipmentFailure(state, action);
    case types.CHECK_EQUIPMENT_FAILURE:
      return {
        ...state,
        isChecking: false,
        data: {
          ...state.data,
          canUse: {
            tagId: null,
            inventoryNumber: null
          }
        }
      };
    case types.REFRESH_DASHBOARD:
      return {
        ...state,
        refresh: true
      };
    default:
      return state;
  }
};

function checkEquipmentUnicitySuccess(state, action) {
  return {
    ...state,
    isChecking: false,
    data: {
      ...state.data,
      canUse: {
        ...state.data.canUse,
        [action.payload.attribute]: action.payload.canUse
      }
    }
  };
}

function saveEquipmentRequest(state) {
  return {
    ...state,
    ...initState(),
    isSaving: true
  };
}

function getEquipmentRequest(state) {
  return {
    ...state,
    refresh: false,
    // ...initState(),
    isLoading: true
  };
}

function equipmentFailure(state, action) {
  return {
    ...state,
    error: true,
    isLoading: false,
    isSaving: false,
    isDeleting: false,
    message: action.message || t('default_error_message')
  };
}

function saveEquipmentSuccess(state, action) {
  return {
    ...state,
    isSaving: false,
    error: false,
    data: {
      ...state.data,
      newEquipmentId: action.payload
    },
    message: action.message || t('default_success_message')
  };
}
function updateEquipmentSuccess(state, action) {
  return {
    ...state,
    isSaving: false,
    error: false,
    message: action.message || t('default_success_message')
  };
}

function toggleArchivedSuccess(state, action) {
  const updatedEquipments = {};
  action.payload.ids.forEach(id => {
    const equipment = state.data.byId[id];
    if (equipment) {
      updatedEquipments[id] = { ...equipment, archived: !equipment.archived };
    }
  });
  return {
    ...state,
    data: {
      ...state.data,
      list: state.data.list.map(equipment => {
        if (action.payload.ids.includes(equipment.id)) {
          return { ...equipment, archived: !equipment.archived };
        }
        return equipment;
      }),
      byId: {
        ...state.data.byId,
        ...updatedEquipments
      }
    },
    isSaving: false,
    message: action.message || t('default_success_message')
  };
}

function deleteEquipmentRequest(state, action) {
  return {
    ...state,
    ...initState(),
    isDeleting: action.payload
  };
}

function deleteEquipmentSuccess(state, action) {
  delete state.data.normalized[action.payload];
  return {
    ...state,
    isDeleting: false,
    error: false,
    message: action.message || t('default_success_message'),
    data: {
      ...state.data,
      list: state.data.list.filter(eq => eq.id !== action.payload)
    },
    meta: {
      ...state.meta,
      total: state.meta.total - 1
    }
  };
}

function getEquipmentSuccess(state, action) {
  const equipment = action.payload.entities.equipments[action.payload.result];
  const sortedControlsByDate = equipment.controls.sort((aControl, bControl) =>
    sortByDate(bControl.createdAt, aControl.createdAt, DATES.FORMAT)
  );
  return {
    ...state,
    isLoading: false,
    error: false,
    data: {
      ...state.data,
      byId: {
        ...state.data.byId,
        [action.payload.result]: {
          ...equipment,
          controls: sortedControlsByDate,
          lastControl: !isEmpty(sortedControlsByDate)
            ? sortedControlsByDate[0]
            : null
        }
      },
      referencedTypes: {
        ...state.data.referencedTypes,
        ...action.payload.entities.equipmentTypes
      }
    },
    message: action.message || t('default_success_message')
  };
}

function fetchEquipmentSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    error: false,
    meta: {
      ...state.meta,
      ...action.meta
    },
    data: {
      ...state.data,
      list: action.payload.byIndexes,
      normalized: action.payload.entities.equipments,
      referencedTypes: {
        ...state.data.referencedTypes,
        ...action.payload.entities.equipmentTypes
      }
    },
    message: action.message || t('default_success_message')
  };
}

function getEquipmentFailure(state, action) {
  return {
    ...state,
    isLoading: false,
    error: true,
    message: action.message || t('default_error_message')
  };
}

function initState() {
  return {
    error: false,
    message: null
  };
}
