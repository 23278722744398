import * as types from '../../constants/actionTypes';
import { getAxiosInstance } from '../../helpers/interceptors';
import { BASE_API_URL } from '../../constants';
import { t } from '../../services/i18n';
import {
  getFiltersQuery,
  getNotificationQuery
} from '../../services/dashboard';
const axios = getAxiosInstance();

export const getStatusChartData = (criteria = {}) => (dispatch, getState) => {
  dispatch(getStatusChartDataRequest());
  const query = getFiltersQuery(getState(), criteria);
  return axios
    .post(`${BASE_API_URL}/equipment/chart-status`, query)
    .then(response => {
      dispatch(getStatusChartDataSuccess(response));
    })
    .catch(error => {
      console.error(error);
      dispatch(getStatusChartDataFailure(error));
    });
};
export const getEquipmentsChartData = (
  criteria = {
    archived: false
  }
) => (dispatch, getState) => {
  dispatch(getEquipmentsChartDataRequest());
  const query = getFiltersQuery(getState(), criteria);
  return axios
    .post(`${BASE_API_URL}/equipment/chart-equipment`, query)
    .then(response => {
      dispatch(getEquipmentsChartDataSuccess(response));
      dispatch(getStatusChartDataRequest());
      const query = getFiltersQuery(getState(), criteria);
      return axios
        .post(`${BASE_API_URL}/equipment/chart-status`, query)
        .then(response => {
          dispatch(getStatusChartDataSuccess(response));
        })
        .catch(error => {
          console.error(error);
          dispatch(getStatusChartDataFailure(error));
        });
    })
    .catch(error => {
      console.error(error);
      dispatch(getEquipmentsChartDataFailure(error));
    });
};

export const getNotifications = () => (dispatch, getState) => {
  dispatch(getNotificationsRequest());
  const query = getNotificationQuery(getState());
  return axios
    .post(`${BASE_API_URL}/equipment/notification`, query)
    .then(response => {
      dispatch(getNotificationsSuccess(response));
      return response.data;
    })
    .catch(error => {
      console.error(error);
      dispatch(getNotificationsFailure(error));
    });
};

export const intializeFilters = newFilters => {
  return (dispatch, getState) => {
    const userID = getState().auth.user.id;
    dispatch({
      type: types.INITIALIZE_FILTERS,
      payload: newFilters,
      user: userID
    });
  };
};

export const toggleImportModal = (onSuccess = () => {}, company) => {
  return dispatch => {
    dispatch({
      type: types.TOGGLE_IMPORT_MODAL,
      payload: {
        onSuccess,
        company
      }
    });
  };
};

export const toggleExportModal = (onSuccess = () => {}, exportType) => {
  return dispatch => {
    dispatch({
      type: types.TOGGLE_EXPORT_MODAL,
      payload: {
        onSuccess,
        exportType
      }
    });
  };
};

export const refreshDashboard = () => {
  return dispatch => {
    dispatch({
      type: types.REFRESH_DASHBOARD
    });
  };
};

function getStatusChartDataRequest() {
  return {
    type: types.GET_STATUS_DATA_REQUEST
  };
}

function getStatusChartDataSuccess(response) {
  return {
    type: types.GET_STATUS_DATA_SUCCESS,
    payload: response.data
  };
}

function getStatusChartDataFailure(error) {
  return {
    type: types.GET_STATUS_DATA_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}
function getEquipmentsChartDataRequest() {
  return {
    type: types.GET_EQUIPMENTS_CHART_DATA_REQUEST
  };
}

function getEquipmentsChartDataSuccess(response) {
  return {
    type: types.GET_EQUIPMENTS_CHART_DATA_SUCCESS,
    payload: response.data
  };
}

function getEquipmentsChartDataFailure(error) {
  return {
    type: types.GET_EQUIPMENTS_CHART_DATA_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}

function getNotificationsRequest() {
  return {
    type: types.GET_NOTIFICATIONS_REQUEST
  };
}

function getNotificationsSuccess(response) {
  return {
    type: types.GET_NOTIFICATIONS_SUCCESS,
    payload: response.data
  };
}

function getNotificationsFailure(error) {
  return {
    type: types.GET_NOTIFICATIONS_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}
