import './helpers/polyfills/index';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter as Router } from 'connected-react-router';
import store, { history } from './modules/store';
import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';

/* Template assets start */
import './vendor.styles';
import('./vendor.scripts');
/* Template assets end */

const target = document.querySelector('#root');

const renderApp = App => {
  // const unlisten = history.listen(function(location, action) {
  //   console.log({ action, location });
  //   // window.scrollTo(0, 0);
  // });
  const element = (
    <AppContainer>
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <Router history={history}>
            <App />
          </Router>
        </PersistGate>
      </Provider>
    </AppContainer>
  );
  render(element, target);
};

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}

renderApp(App);
