import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectInput from '../shared/SelectInput';
import { intializeFilters } from '../../modules/dashboard/action';
import { changeFilter } from '../../modules/filters/action';
import {
  isEmpty
  // areDifferentBy
} from '../../helpers';
import { mapIntoSelect } from '../../helpers/transformer';
import {
  getNewSelectedOptions,
  isFilterDirty,
  setAllFilters,
  hasSelectedAll,
  checkFilterPermissionPermission,
  isAllSelected
} from '../../services/dashboard';
import { ENTITY_LEVELS } from '../../constants';
import withPermissions from '../hoc/withPermissions';
import { canI } from '../../services/userAcl';
import WithFamiliesAndEntities from '../hoc/WithFamiliesAndEntities';
import { t } from '../../services/i18n';
import withCurrentUser from '../hoc/WithCurrentUser';
import { allExceptRoot } from '../../services/entities';
export const ALL_SELECTED = () => t('all');

class HeaderFilters extends PureComponent {
  static propTypes = {
    //redux
    changeFilter: PropTypes.func.isRequired,
    fetchFamilies: PropTypes.func.isRequired,
    fetchChildrenByEntity: PropTypes.func.isRequired,
    intializeFilters: PropTypes.func.isRequired,
    filters: PropTypes.shape({
      family: PropTypes.array,
      level1: PropTypes.array,
      level2: PropTypes.array,
      type: PropTypes.array,
      level3: PropTypes.array
    }),
    families: PropTypes.array,
    entities: PropTypes.array
  };

  state = {
    initialized: false,
    families: [],
    equipmentTypes: [],
    level1: [],
    level2: [],
    level3: []
  };

  /**
   * @return {import('../../services/families').IFamily[]}
   */
  get allFamilies() {
    return this.props.families;
  }
  /**
   * @return {import('../../services/entities').IEntity[]}
   */
  get allEntities() {
    return this.props.entities;
  }

  get allEntitiesByLevels() {
    return this.props.entitiesByLevels;
  }

  get currentFamilies() {
    return this.props.filters.family;
  }
  get currentTypes() {
    return this.props.filters.type;
  }
  getKey = level => {
    return `level${level}`;
  };

  componentDidMount = () => {
    let { filters, initialFilters, entitiesByIds, permissions } = this.props;
    if (Boolean(isFilterDirty(filters)) === false) {
      // first time
      filters = {
        ...initialFilters,
        ...setAllFilters(this.allFamilies, this.allEntities, permissions)
      };
    } else {
      filters = checkFilterPermissionPermission(
        filters,
        entitiesByIds,
        permissions
      );
    }

    this.setState({
      initialized: true,
      families: mapIntoSelect(this.allFamilies, 'name', 'id', 'id')
    });
    this.props.intializeFilters(filters);
  };

  async componentDidUpdate(prevProps) {
    const { familyIds, filters, entities } = this.props;
    if (prevProps.familyIds !== familyIds) {
      await this.props.fetchFamilies();
      this.setState({
        families: mapIntoSelect(this.allFamilies, 'name', 'id', 'id')
      });
      if (filters.family.includes(ALL_SELECTED())) {
        const newFilters = setAllFilters(this.allFamilies);
        this.props.intializeFilters(newFilters);
      }
    }

    if (entities.length !== prevProps.entities.length) {
      await this.props.fetchPermissions();
      const newFilters = {
        ...filters
      };
      Object.values(ENTITY_LEVELS)
        .filter(allExceptRoot)
        .forEach(level => {
          newFilters[this.getKey(level.label)] = this.getEntityFiltersByLevel(
            level,
            isAllSelected(filters[this.getKey(level.label)]),
            newFilters[this.getKey(level.label - 1)],
            filters[this.getKey(level.label)]
          );
        });

      this.props.changeFilter(newFilters, false);
    }
  }

  handleOnChangeFamily = ({ target }) => {
    const { filters } = this.props;
    const newSelectedFamilies = getNewSelectedOptions(target, filters.family);
    const isSelectingAll = hasSelectedAll(target, filters.family);

    const newSelectedTypes = this.getDerivedTypeFromFamily(
      newSelectedFamilies,
      isSelectingAll
    );
    this.props.changeFilter({
      family: newSelectedFamilies,
      type: newSelectedTypes
    });
  };

  /**
   * @param {Array} selectedFamilies
   */
  getDerivedTypeFromFamily = (selectedFamilies, selectAll) => {
    return this.allFamilies
      .filter(family => selectedFamilies.includes(family.id))
      .flatMap(family => family.types.map(type => type.id))
      .concat(selectAll ? [ALL_SELECTED()] : []);
  };

  handleOnChangeEntity = (level, { target }) => {
    const { filters } = this.props;
    const oldSelected = filters[this.getKey(level.label)];
    const newSelectedEntities = getNewSelectedOptions(target, oldSelected);
    const isSelectingAll = hasSelectedAll(target, oldSelected);
    const newFilters = this.getDerivedEntityFromParent(
      level,
      newSelectedEntities,
      isSelectingAll
    );
    this.props.changeFilter(newFilters);
  };

  getDerivedEntityFromParent = (level, newSelectedEntities, selectAll) => {
    const newFilters = {
      [this.getKey(level.label)]: newSelectedEntities
    };
    Object.values(ENTITY_LEVELS)
      .filter(abstractLevel => abstractLevel.value > level.value)
      .forEach(abstractLevel => {
        const selectedParents =
          newFilters[this.getKey(abstractLevel.label - 1)];
        newFilters[
          this.getKey(abstractLevel.label)
        ] = this.getEntityFiltersByLevel(
          abstractLevel,
          selectAll,
          selectedParents
        );
      });
    return newFilters;
  };

  getEntityFiltersByLevel = (
    level,
    selectAll,
    parentsIds = [],
    prevFilters = []
  ) => {
    const { permissions } = this.props;
    const allByLevel = this.allEntitiesByLevels[level.value];
    let filteredEntities = null;
    if (level.value === ENTITY_LEVELS.LEVEL_1.value) {
      filteredEntities = allByLevel.filter(
        entity =>
          canI('canSee', entity, permissions) &&
          (selectAll || prevFilters.includes(entity.id))
      );
    } else {
      filteredEntities = allByLevel.filter(entity => {
        return (
          canI('canSee', entity, permissions) &&
          parentsIds.includes(entity.parent)
        );
      });
    }

    return filteredEntities
      .map(entity => entity.id)
      .concat(selectAll ? [ALL_SELECTED()] : []);
  };

  handleOnChangeFilter = ({ target }) => {
    const { filters } = this.props;
    const newSelected = getNewSelectedOptions(target, filters[target.name]);
    this.props.changeFilter({
      [target.name]: newSelected
    });
  };

  /**
   * @param {Array} selectedFamiliesIds
   */
  getTypeOptions = selectedFamiliesIds => {
    const { families, initialized } = this.state;
    if (!initialized || isEmpty(families) || isEmpty(selectedFamiliesIds)) {
      return [];
    }

    const selectedFamilies = this.allFamilies.filter(family =>
      selectedFamiliesIds.includes(family.id)
    );
    const newTypesToShow = selectedFamilies.flatMap(family => family.types);
    const result = mapIntoSelect(newTypesToShow, 'name', 'id', 'id');
    return result;
  };

  getEntityOptions = level => {
    const { initialized } = this.state;
    if (!initialized) {
      return [];
    }
    const { filters, permissions } = this.props;
    const selectedParents = filters[this.getKey(level.value - 2)];
    const result = mapIntoSelect(
      this.allEntities.filter(
        entity =>
          canI('canSee', entity, permissions) &&
          (!selectedParents
            ? entity.level === level.value
            : entity.level === level.value &&
              selectedParents.includes(entity.parent))
      ),
      'name',
      'id',
      'id'
    );
    return result;
  };

  render() {
    const { filters } = this.props;
    const { families, initialized } = this.state;
    if (!initialized) {
      return null;
    }
    return (
      <React.Fragment>
        <li className="filter-wrapper">
          <SelectInput
            labelClass="text-white"
            label={t('family_header_filter')}
            wrapperClass="filter-select"
            allLabel={ALL_SELECTED()}
            withLine={false}
            onChange={this.handleOnChangeFamily}
            name={'family'}
            value={filters.family}
            options={families}
            multiple
          />
        </li>
        <li className="filter-wrapper">
          <SelectInput
            labelClass="text-white"
            label={t('type_header_filter')}
            wrapperClass="filter-select"
            allLabel={ALL_SELECTED()}
            withLine={false}
            onChange={this.handleOnChangeFilter}
            name={'type'}
            value={filters.type}
            options={this.getTypeOptions(filters.family)}
            multiple
          />
        </li>
        <li className="filter-wrapper">
          <SelectInput
            labelClass="text-white"
            label={t('level1_label')}
            wrapperClass="filter-select"
            allLabel={ALL_SELECTED()}
            withLine={false}
            onChange={this.handleOnChangeEntity.bind(
              this,
              ENTITY_LEVELS.LEVEL_1
            )}
            name={'level1'}
            value={filters.level1}
            options={this.getEntityOptions(ENTITY_LEVELS.LEVEL_1)}
            multiple
          />
        </li>
        <li className="filter-wrapper">
          <SelectInput
            labelClass="text-white"
            label={t('level2_label')}
            wrapperClass="filter-select"
            allLabel={ALL_SELECTED()}
            withLine={false}
            onChange={this.handleOnChangeEntity.bind(
              this,
              ENTITY_LEVELS.LEVEL_2
            )}
            name={'level2'}
            value={filters.level2}
            options={this.getEntityOptions(ENTITY_LEVELS.LEVEL_2)}
            multiple
          />
        </li>
        <li className="filter-wrapper">
          <SelectInput
            labelClass="text-white"
            label={t('level3_label')}
            wrapperClass="filter-select"
            allLabel={ALL_SELECTED()}
            withLine={false}
            onChange={this.handleOnChangeEntity.bind(
              this,
              ENTITY_LEVELS.LEVEL_3
            )}
            name={'level3'}
            value={filters.level3}
            options={this.getEntityOptions(ENTITY_LEVELS.LEVEL_3)}
            multiple
          />
        </li>
      </React.Fragment>
    );
  }
}

export const UnconnectedHeaderFilters = HeaderFilters;
const mapStateToProps = (state, ownProps) => {
  return {
    filters: state.filters[ownProps.currentUser.id],
    initialFilters: state.filters.initials,
    familyIds: state.families.data.ids,
    permissions: state.permissions.data,
    families: state.families.data.all,
    entities: state.entities.byChildren.flat,
    entitiesByIds: state.entities.byChildren.normalized,
    entitiesByLevels: state.entities.byChildren.byLevels
  };
};

const mapDispatchToProps = {
  changeFilter,
  intializeFilters
};

const ConnectedComponent = withCurrentUser(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPermissions(HeaderFilters))
);

export default WithFamiliesAndEntities(ConnectedComponent);
