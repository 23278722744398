export default class FileService {
  /**
   *
   * @param {File} file
   *
   * @returns {FormData}
   */
  setInForm(file) {
    var fd = new FormData();
    fd.append('file', file);
    return fd;
  }
}
