import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { initMaterialInput } from '../../helpers';
import ValidationError from './ValidationError';

class Input extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    pattern: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    width: PropTypes.number
  };

  static defaultProps = {
    withLine: true,
    withValidation: false,
    focused: false,
    hasError: false,
    autoComplete: 'off'
  };

  componentDidMount = () => {
    // Template needs
    initMaterialInput();
  };

  render() {
    const {
      name,
      forwardedRef,
      id,
      label,
      disabled,
      type = 'text',
      icon = '',
      wrapperClass = 'form-group',
      wrapperStyle = {},
      subWrapperStyle = {},
      className = '',
      hasError,
      hint,
      withLine,
      focused,
      isReadOnly,
      message,
      withValidation,
      ...rest
    } = this.props;
    const inputId = id || name;
    const isCheckboxOrRadio = type === 'checkbox' || type === 'radio';
    const inputClass = classNames(
      {
        'form-control': isCheckboxOrRadio === false,
        'disabled-input': disabled
      },
      className
    );
    const subWrapperClass = classNames({
      'form-line': withLine,
      error: hasError,
      focused: hasError || focused
    });
    const content = isReadOnly ? (
      <p>{Array.isArray(rest.value) ? rest.value.join(',') : rest.value}</p>
    ) : (
      <input
        ref={forwardedRef}
        id={inputId}
        className={inputClass}
        name={name}
        type={type}
        disabled={disabled}
        {...rest}
        autoComplete={'off'}
      />
    );

    const labelContent =
      isCheckboxOrRadio && label === undefined ? (
        <label id={`label-${inputId}`} htmlFor={inputId}>
          {'  '}
        </label>
      ) : label ? (
        <label id={`label-${inputId}`} htmlFor={inputId}>
          {label}
        </label>
      ) : null;

    return (
      <div className={wrapperClass} style={wrapperStyle}>
        {icon}
        <div className={subWrapperClass} style={subWrapperStyle}>
          {isCheckboxOrRadio ? (
            <React.Fragment>
              {content}
              {labelContent}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {labelContent}
              {content}
            </React.Fragment>
          )}
        </div>
        {message && (
          <label id={`${inputId}-error`} className="error" htmlFor={inputId}>
            {message}
          </label>
        )}
        {hint && (
          <label
            id={`${this.state.id}-warning`}
            className="warning"
            htmlFor={this.state.id}
          >
            {hint}.
          </label>
        )}
        {withValidation && (
          <label id={`${inputId}-error`} className="error" htmlFor={inputId}>
            <ValidationError name={name} />
          </label>
        )}
      </div>
    );
  }
}

/**
 * Input prps interface.
 * @typedef {Object} InputProps
 *
 * @property {String} name -
 * @property {Function} onChange -
 */

/**
 * @returns {InputProps}
 */
export default React.forwardRef((props, ref) => {
  return <Input {...props} forwardedRef={ref} />;
});
