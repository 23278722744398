import { t } from '../services/i18n';

export const EQUIPMENT_CHART_METADATA = () => ({
  controlled: {
    label: t('home_page.charts.controlled'),
    index: 0,
    backgroundColor: '#3bbcd4',
    key: 'controlled'
  },
  late: {
    label: t('home_page.charts.late'),
    index: 1,
    backgroundColor: '#F14537',
    key: 'late'
  },
  withoutControlFrequency: {
    label: t('home_page.charts.withoutControlFrequency'),
    index: 2,
    backgroundColor: '#95a5a6',
    key: 'withoutControlFrequency'
  }
});
