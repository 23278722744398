import React, { Component } from 'react';
import LocalizedLink from '../../components/shared/LocalizedLink';

class AuthLayout extends Component {
  static propTypes = {};
  componentDidMount() {
    document.body.style.backgroundColor = '#222f3e';
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = '#e9e9e9';
  }

  render() {
    const { children } = this.props;
    return (
      <div className="login-page">
        <div className="login-box">
          <div className="logo">
            <LocalizedLink to={'/'}>
              <img
                src="/images/logo.png"
                width="250"
                // height="50"
                alt="Biotrace"
              />
            </LocalizedLink>
          </div>

          {children}
        </div>
      </div>
    );
  }
}

export default AuthLayout;
