import * as Yup from 'yup';
import { t } from '../services/i18n';
import { USER_ROLES, SUPPORTED_IMAGE_FORMATS, PASSWORD_MIN_LENGTH } from '.';

const FILE_SIZE = 50 * 1024 * 1024;

/**
 * Add this to helpers
 *
 * @param {} ref
 * @param {*} msg
 */
function equalTo(ref, msg) {
  /* eslint-disable no-template-curly-in-string */
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path
    },
    test: function(value) {
      return value === this.resolve(ref);
    }
  });
}

function uniqueProperty(propertyName, message) {
  return this.test('unique', message, function(value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);

    const subOptions = options.slice(0, currentIndex);

    if (
      subOptions.some(option => option[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        index: path,
        message
      });
    }

    return true;
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);
Yup.addMethod(Yup.object, 'uniqueProperty', uniqueProperty);

export const VALIDATION_SCHEMAS = () => ({
  SignInForm: {
    email: Yup.string()
      .email(t('form_validation.invalid_mail'))
      .required(t('form_validation.required_field')),
    password: Yup.string().required(t('form_validation.required_field'))
  },
  SignUpForm: {
    firstName: Yup.string().required(t('form_validation.required_field')),
    lastName: Yup.string().required(t('form_validation.required_field')),
    companyName: Yup.string().required(t('form_validation.required_field')),
    plainPassword: Yup.string()
      .required(t('form_validation.required_field'))
      .min(
        PASSWORD_MIN_LENGTH,
        t('form_validation.min_length', { length: PASSWORD_MIN_LENGTH })
      ),
    confirmPlainPassword: Yup.string()
      .equalTo(Yup.ref('plainPassword'), t('form_validation.password_match'))
      .required(t('form_validation.required_field')),
    email: Yup.string()
      .email(t('form_validation.invalid_mail'))
      .required(t('form_validation.required_field')),
    gdpr: Yup.boolean().oneOf([true], t('form_validation.gdpr_required')),
    terms: Yup.boolean().oneOf([true], t('form_validation.terms_required'))
  },
  editPassword: {
    oldPassword: Yup.string().required(t('form_validation.required_field')),
    plainPassword: Yup.string()
      .required(t('form_validation.required_field'))
      .min(
        PASSWORD_MIN_LENGTH,
        t('form_validation.min_length', { length: PASSWORD_MIN_LENGTH })
      ),
    confirmPlainPassword: Yup.string()
      .equalTo(Yup.ref('plainPassword'), t('form_validation.password_match'))
      .required(t('form_validation.required_field'))
  },
  editProfil: {
    firstName: Yup.string().required(t('form_validation.required_field')),
    lastName: Yup.string().required(t('form_validation.required_field')),
    email: Yup.string()
      .email(t('form_validation.invalid_mail'))
      .required(t('form_validation.required_field')),
    streetAdress: Yup.string().required(t('form_validation.required_field')),
    extendedAddress: Yup.string(),
    postalCode: Yup.string().required(t('form_validation.required_field')),
    locality: Yup.string().required(t('form_validation.required_field')),
    country: Yup.string().required(t('form_validation.required_field')),
    timezone: Yup.string(),
    language: Yup.string(),
    phone: Yup.string().required(t('form_validation.required_field'))
  },
  baseEquipmentTypeForm: {
    name: Yup.string()
      .required(t('form_validation.required_field'))
      .max(31, t('form_validation.max_length', { max: 31 }))
      .matches(/^((?![[\]*?//\\]).)*$/, {
        excludeEmptyString: true,
        message: t('form_validation.only_letter_number')
      }),
    family: Yup.string().required(t('form_validation.required_field')),
    image: Yup.mixed()
      .notRequired()
      .test('fileSize', 'File too large', value => {
        if (value && value.id) {
          return true;
        }
        return !!value ? value.size <= FILE_SIZE : true;
      })
      .test('fileFormat', 'Unsupported Format', value => {
        if (value && value.id) {
          return true;
        }
        return !!value ? SUPPORTED_IMAGE_FORMATS.includes(value.type) : true;
      })
  },
  baseControlTypeForm: {
    name: Yup.string().required(t('form_validation.required_field')),
    types: Yup.array()
  },
  formDefinitionsForm: Yup.array().of(
    Yup.object()
      .shape({
        label: Yup.string(),
        required: Yup.boolean(),
        type: Yup.string(),
        format: Yup.string(),
        options: Yup.array().of(
          Yup.object()
            .shape({
              value: Yup.string()
            })
            .uniqueProperty('value', t('form_validation.unique_option'))
        )
      })
      .uniqueProperty('label', t('form_validation.unique_question'))
  ),
  _inviteUserForm: {
    email: Yup.string()
      .email(t('form_validation.invalid_mail'))
      .required(t('form_validation.required_field')),
    firstName: Yup.string().required(t('form_validation.required_field')),
    lastName: Yup.string().required(t('form_validation.required_field')),
    families: Yup.array().min(1, t('form_validation.required_field'))
  },
  _editRoleForm: {
    userRoles: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(t('form_validation.required_field')),
          '1': Yup.string().when('name', (name, schema) => {
            return schema.required(t('form_validation.required_field'));
          }),
          '2': Yup.string().when('name', (name, schema) => {
            if (name === USER_ROLES().MANAGER_LEVEL_2.value)
              return schema.required(t('form_validation.required_field'));
            return schema;
          })
          // '3': Yup.string().when('name', (name, schema) => {
          //   if (name === USER_ROLES().GUEST.value)
          //     return schema.required(t('form_validation.required_field'));
          //   return schema;
          // })
        })
      )
      .min(1)
  },
  equipmentForm: {
    family: Yup.string().required(t('form_validation.required_field')),
    type: Yup.string().required(t('form_validation.required_field')),
    controlFrequency: Yup.number().min(
      0,
      t('form_validation.min_value', { min: 0 })
    ),
    entity: Yup.object().shape({
      2: Yup.string().required(t('form_validation.required_field')),
      3: Yup.string().required(t('form_validation.required_field')),
      4: Yup.string().required(t('form_validation.required_field'))
    }),
    tagId: Yup.string().required(t('form_validation.required_field')),
    inventoryNumber: Yup.string()
  },
  editEquipmentForm: {
    controlFrequency: Yup.number().min(
      0,
      t('form_validation.min_value', { min: 0 })
    ),
    entity: Yup.object().shape({
      2: Yup.string().required(t('form_validation.required_field')),
      3: Yup.string().required(t('form_validation.required_field')),
      4: Yup.string().required(t('form_validation.required_field'))
    }),
    tagId: Yup.string().required(t('form_validation.required_field')),
    inventoryNumber: Yup.string()
  },
  familyForm: {
    name: Yup.string().required(t('form_validation.required_field')),
    image: Yup.mixed()
      .notRequired()
      .test('fileSize', 'File too large', value => {
        if (value && value.id) {
          return true;
        }
        return !!value ? value.size <= FILE_SIZE : true;
      })
      .test('fileFormat', 'Unsupported Format', value => {
        if (value && value.id) {
          return true;
        }
        return !!value ? SUPPORTED_IMAGE_FORMATS.includes(value.type) : true;
      }),
    statusList: Yup.string().required(t('form_validation.required_field'))
  },
  listForm: {
    name: Yup.string().required(t('form_validation.required_field')),
    statuses: Yup.array().min(1, t('form_validation.required_field'))
  },
  get inviteUserForm() {
    return { ...this._inviteUserForm, ...this._editRoleForm };
  },
  set inviteUserForm(value) {
    this._inviteUserForm = value;
  },
  get editRoleForm() {
    return this._editRoleForm;
  },
  set editRoleForm(value) {
    this._editRoleForm = value;
  }
});
