import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';

export default (state = initialState.statuses, action) => {
  switch (action.type) {
    // statuses
    // ********Fetch*******
    case types.FETCH_STATUSES_REQUEST:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: true,
          error: false,
          message: null
        }
      };
    case types.FETCH_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: false,
          error: false,
          data: {
            ...state.statuses.data,
            list: action.payload
          },
          message: action.message || t('default_success_message')
        }
      };
    case types.FETCH_STATUSES_FAILURE:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          error: true,
          isLoading: false
        }
      };
    // *******NEW STATUS******
    case types.NEW_STATUSES_REQUEST:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isSaving: true,
          error: false,
          message: action.message || t('default_success_message')
        }
      };
    case types.NEW_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isSaving: false
          // data: {
          //   ...state.statuses.data,
          //   list: [...state.statuses.data.list, action.payload]
          // }
        }
      };
    case types.NEW_STATUSES_FAILURE:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isSaving: false,
          error: true,
          message: action.message || t('default_error_message')
        }
      };

    case types.DELETE_STATUS_REQUEST:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isDeleting: action.payload,
          error: false,
          message: null
        }
      };
    case types.DELETE_STATUS_SUCCESS:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isDeleting: false,
          error: false,
          data: {
            ...state.statuses.data,
            list: state.statuses.data.list.filter(
              list => list.id !== action.payload
            )
          },
          message: action.message || t('default_success_message')
        }
      };
    case types.DELETE_STATUS_FAILURE:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          error: true,
          isDeleting: false,
          message: action.message || t('default_error_message')
        }
      };

    // MANAGE LISTS
    // fetch
    case types.FETCH_LIST_REQUEST:
      return {
        ...state,
        lists: {
          ...state.lists,
          isLoading: true,
          error: false,
          message: null
        }
      };
    case types.FETCH_LIST_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          isLoading: false,
          error: false,
          data: {
            ...state.lists.data,
            list: action.payload
          },
          message: action.message || t('default_success_message')
        }
      };
    case types.FETCH_LIST_FAILURE:
      return {
        ...state,
        lists: {
          ...state.lists,
          error: true,
          isLoading: false
        }
      };

    case types.DELETE_LIST_REQUEST:
      return {
        ...state,
        lists: {
          ...state.lists,
          isDeleting: action.payload,
          error: false,
          message: null
        }
      };
    case types.DELETE_LIST_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          isDeleting: false,
          error: false,
          data: {
            ...state.lists.data,
            list: state.lists.data.list.filter(
              list => list.id !== action.payload
            )
          },
          message: action.message || t('default_success_message')
        }
      };
    case types.DELETE_LIST_FAILURE:
      return {
        ...state,
        lists: {
          ...state.lists,
          error: true,
          message: action.message || t('default_error_message'),
          isDeleting: false
        }
      };
    // new
    case types.NEW_LIST_REQUEST:
    case types.EDIT_LIST_REQUEST:
      return {
        ...state,
        lists: {
          ...state.lists,
          isSaving: true,
          error: false,
          message: action.message || t('default_success_message')
        }
      };
    case types.NEW_LIST_SUCCESS:
    case types.EDIT_LIST_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          isSaving: false
          // data: {
          //   ...state.lists.data,
          //   list: [...state.lists.data.list, action.payload]
          // }
        }
      };
    case types.NEW_LIST_FAILURE:
    case types.EDIT_LIST_FAILURE:
      return {
        ...state,
        lists: {
          ...state.lists,
          isSaving: false,
          error: true,
          message: action.message || t('default_error_message')
        }
      };
    default:
      return state;
  }
};
