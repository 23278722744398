import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import SignInPage from './containers/auth/SignIn';
import SignUpPage from './containers/auth/SignUp';
import ForgetPasswordPage from './containers/auth/ForgetPassword';
import ResetPasswordPage from './containers/auth/ResetPassword';
import LogoutPage from './components/auth/Logout';
import NotFound404 from './components/404';
import { prefixPath } from './helpers';
import useLocale from './hooks/useLocale';

const Dashboard = Loadable({
  loading: () => null,
  loader: () => import('./components/Dashboard')
});
Dashboard.displayName = 'Dashboard';

const NoDashboard = Loadable({
  loading: () => null,
  loader: () => import('./components/NoDashboard')
});
NoDashboard.displayName = 'NoDashboard';

const BackOffice = Loadable({
  loading: () => null,
  loader: () => import('./components/BackOffice')
});
BackOffice.displayName = 'BackOffice';

const paths = {
  dashboard: prefixPath('/dashboard', ':locale'),
  signup: prefixPath('/signup', ':locale'),
  login: prefixPath('/login', ':locale'),
  forget: prefixPath('/forget', ':locale'),
  reset: prefixPath('/reset', ':locale'),
  logout: prefixPath('/logout', ':locale'),
  outside: prefixPath('/outside', ':locale'),
  backoffice: prefixPath('/backoffice', ':locale')
};

function Routes() {
  const { lang } = useLocale();
  return (
    <Switch>
      <Route
        path={'/:locale?'}
        exact={true}
        component={() => <Redirect to={`/${lang}/dashboard`} />}
      />

      <Route path={paths.dashboard} component={Dashboard} />
      <Route path={paths.login} component={SignInPage} />
      <Route path={paths.signup} component={SignUpPage} exact={true} />
      <Route path={paths.forget} component={ForgetPasswordPage} exact={true} />
      <Route path={paths.reset} component={ResetPasswordPage} exact={true} />
      <Route path={paths.logout} component={LogoutPage} exact={true} />
      <Route path={paths.outside} component={NoDashboard} />
      <Route path={paths.backoffice} component={BackOffice} />
      <Route path={'*'} component={NotFound404} />
    </Switch>
  );
}

export default React.memo(Routes);
