import axios from 'axios';
import { getFromLS } from './localstorage';
import { LOCAL_STORAGE } from '../constants';
import { clearLocalstorage } from '../services/auth';
import { detectLocale } from '../services/i18n/util';

export const getAxiosInstance = () => {
  let axiosInstance = axios.create({
    timeout: 30000
  });

  axiosInstance.interceptors.request.use(
    config => {
      if (config.dontIntercept === true) {
        return config;
      }
      const info = getFromLS(LOCAL_STORAGE.AUTH);
      if (info) {
        config.headers.authorization = `Bearer ${info.access_token}`;
      }
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      console.log('Status Code :', error.response && error.response.status);
      if (error.response && [401, 403].includes(error.response.status)) {
        // clear LS
        clearLocalstorage();
        if (!window.location.pathname.includes('login')) {
          const lang = detectLocale();
          window.location.href = `/${lang}/login?redirectTo=${
            window.location.pathname
          }`;
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
