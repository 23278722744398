import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';

export default (state = initialState.invitations, action) => {
  switch (action.type) {
    case types.PAGINATE_INVITED_REQUEST:
    case types.GET_INVITED_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: null
      };
    case types.REMOVE_INVITATION_REQUEST:
      return {
        ...state,
        isSaving: action.payload,
        error: false,
        message: null
      };
    case types.GET_INVITED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          user: action.payload
        }
      };
    case types.PAGINATE_INVITED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        meta: {
          ...state.meta,
          ...action.meta
        },
        data: {
          ...state.data,
          list: action.payload
        }
      };
    case types.REMOVE_INVITATION_SUCCESS:
      return {
        ...state,
        isSaving: false,
        message: action.message || t('default_success_message'),
        data: {
          ...state.data,
          list: state.data.list.filter(
            invitation => invitation.id !== action.payload
          )
        }
      };
    case types.VALIDATE_ACCOUNT_REQUEST:
    case types.INVITE_USER_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: false,
        message: null
      };
    case types.INVITE_USER_SUCCESS:
    case types.VALIDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        error: false,
        message: action.message || t('default_success_message')
      };
    case types.REMOVE_INVITATION_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: false,
        message: action.message || t('default_error_message')
      };
    case types.GET_INVITED_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: action.message || t('default_error_message')
      };
    case types.INVITE_USER_FAILURE:
    case types.PAGINATE_INVITED_FAILURE:
    case types.VALIDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: true,
        isSaving: false,
        message: action.message || t('default_error_message')
      };
    default:
      return state;
  }
};
