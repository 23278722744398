import * as types from '../../constants/actionTypes';
import { getAxiosInstance } from '../../helpers/interceptors';
import { BASE_API_URL, ITEMS_PER_PAGE } from '../../constants';
import { Schemas } from '../../constants/schema';
import { t } from '../../services/i18n';
const axios = getAxiosInstance();

export const createFamily = family => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SAVE_FAMILY_REQUEST
    });
    return axios
      .post(`${BASE_API_URL}/familyequipmenttype/new`, family)
      .then(response => {
        dispatch(createFamilySuccess(response));
      })
      .catch(error => {
        dispatch(createFamilyFailure(error));
      });
  };
};

export const paginateFamilies = (skip = 1, limit = ITEMS_PER_PAGE) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.PAGINATE_FAMILIES_REQUEST
    });
    return axios
      .get(`${BASE_API_URL}/familyequipmenttype/paginate/${skip}/${limit}`)
      .then(response => {
        const { data, ...meta } = response.data;
        dispatch({
          type: types.PAGINATE_FAMILIES_SUCCESS,
          payload: data,
          meta: {
            schema: Schemas.FAMILIES,
            ...meta
          }
        });
      })
      .catch(error => {
        dispatch({
          type: types.PAGINATE_FAMILIES_FAILURE
        });
      });
  };
};

export const fetchFamilies = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.FETCH_FAMILIES_REQUEST
    });
    return axios
      .get(`${BASE_API_URL}/familyequipmenttype/list`)
      .then(response => {
        dispatch({
          type: types.FETCH_FAMILIES_SUCCESS,
          payload: response.data,
          meta: {
            schema: Schemas.FAMILIES
          }
        });
        return response.data;
      })
      .catch(error => {
        dispatch({
          type: types.FETCH_FAMILIES_FAILURE
        });
      });
  };
};

export const updateFamily = family => {
  return (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_FAMILY_REQUEST
    });
    return axios
      .put(`${BASE_API_URL}/familyequipmenttype/${family.id}/update`, family)
      .then(response => {
        dispatch(updateFamilySuccess(response));
      })
      .catch(error => {
        dispatch(updateFamilyFailure(error));
      });
  };
};

export const deleteFamily = id => {
  return (dispatch, getState) => {
    dispatch({
      type: types.DELETE_FAMILY_REQUEST
    });
    return axios
      .delete(`${BASE_API_URL}/familyequipmenttype/${id}/delete`)
      .then(response => {
        dispatch(deleteFamilySuccess(id));
      })
      .catch(error => {
        dispatch(deleteFamilyFailure(error));
      });
  };
};

export const getFamily = id => {
  return (dispatch, getState) => {
    dispatch({
      type: types.GET_FAMILY_REQUEST
    });
    return axios
      .get(`${BASE_API_URL}/familyequipmenttype/${id}/get`)
      .then(response => {
        dispatch(getFamilySuccess(response));
      })
      .catch(error => {
        dispatch(getFamilyFailure(error));
      });
  };
};
export const cannotChangeFamilyList = id => {
  return (dispatch, getState) => {
    // dispatch({
    //   type: types.GET_FAMILY_REQUEST
    // });
    return axios
      .get(`${BASE_API_URL}/familyequipmenttype/${id}/has-control`)
      .then(response => {
        // dispatch(getFamilySuccess(response));
        return response.data;
      })
      .catch(error => {
        // dispatch(getFamilyFailure(error));
        return true;
      });
  };
};

function getFamilySuccess(response) {
  return {
    type: types.GET_FAMILY_SUCCESS,
    payload: response.data
  };
}

function getFamilyFailure(error) {
  return {
    type: types.GET_FAMILY_FAILURE,
    fullError: error.response.data,
    message: t(error.response.data.content)
  };
}

function createFamilySuccess(response) {
  return {
    type: types.SAVE_FAMILY_SUCCESS,
    payload: response.data
  };
}

function createFamilyFailure(error) {
  return {
    type: types.SAVE_FAMILY_FAILURE,
    fullError: error.response.data,
    message: t(error.response.data.content)
  };
}

function updateFamilySuccess(response) {
  return {
    type: types.UPDATE_FAMILY_SUCCESS,
    payload: response.data
  };
}

function updateFamilyFailure(error) {
  return {
    type: types.UPDATE_FAMILY_FAILURE,
    fullError: error.response.data,
    message: t(error.response.data.content)
  };
}

function deleteFamilyFailure(error) {
  return {
    type: types.DELETE_FAMILY_FAILURE,
    fullError: error.response.data,
    message: t(error.response.data.content)
  };
}

function deleteFamilySuccess(id) {
  return {
    type: types.DELETE_FAMILY_SUCCESS,
    payload: id
  };
}
