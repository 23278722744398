import * as types from '../../constants/actionTypes';
import { fetchEquipments } from '../equipments/action';
import {
  getEquipmentsChartData,
  getStatusChartData
} from '../dashboard/action';

export const changeFilter = (newFilters, doFetch = true) => {
  return (dispatch, getState) => {
    const userID = getState().auth.user.id;

    dispatch(changeFiltersSuccess(newFilters, userID));
    if (doFetch === false) {
      return;
    }
    if (!newFilters.from) {
      dispatch(getStatusChartData());
      dispatch(fetchEquipments());
    }
    dispatch(getEquipmentsChartData());
  };
};

/**
 * The complete sort object shape.
 * @typedef {Object} ISortDefinition
 *
 * @property {String} property
 * @property {String} order
 */

/**
 * The complete state object shape.
 * @typedef {Object} ISorts
 *
 * @property {ISortDefinition} sort
 */

/**
 *
 * @param {ISorts} newSorts
 * @returns {Function}
 */
export const changeSorts = newSorts => {
  return (dispatch, getState) => {
    const userID = getState().auth.user.id;

    dispatch(changeSortsSuccess(newSorts, userID));
    dispatch(fetchEquipments());
  };
};

function changeSortsSuccess(newSorts, userID) {
  return {
    type: types.CHANGE_SORT,
    payload: newSorts,
    user: userID
  };
}

function changeFiltersSuccess(newFilters, userID) {
  return {
    type: types.CHANGE_FILTER,
    payload: newFilters,
    user: userID
  };
}
