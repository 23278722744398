const SUCCESS_COLOR = '#36B37E';
const ERROR_COLOR = '#e74c3c';
const WARNING_COLOR = '#f1c40f';

const SUCCESS_ICON = 'check_circle';
const ERROR_ICON = 'error';
const WARNING_ICON = 'warning';

const DEFAULT_OPTIONS = {
  colorName: 'bg-white',
  icon: SUCCESS_ICON,
  iconColor: SUCCESS_COLOR,
  fontColor: '#333',
  allow_dismiss: true,
  placementFrom: 'top',
  placementAlign: 'right',
  animateEnter: 'animated bounceInRight', //flipInX
  animateExit: 'animated bounceOutRight'
};

export const showNotification = async (text, customOptions = {}) => {
  let icon = DEFAULT_OPTIONS.icon;
  let iconColor = DEFAULT_OPTIONS.iconColor;
  if (customOptions.error) {
    icon = ERROR_ICON;
    iconColor = ERROR_COLOR;
  } else if (customOptions.warning) {
    icon = WARNING_ICON;
    iconColor = WARNING_COLOR;
  }
  const options = {
    ...DEFAULT_OPTIONS,
    ...{ icon, iconColor },
    ...customOptions
  };

  await import('../assets/plugins/bootstrap-notify/bootstrap-notify');
  window.jQuery.notify(
    {
      message: text
    },
    {
      type: options.colorName,
      allow_dismiss: options.allow_dismiss,
      newest_on_top: true,
      // showProgressbar: true,
      timer: 7000,
      z_index: 10001,
      placement: {
        from: options.placementFrom,
        align: options.placementAlign
      },
      animate: {
        enter: options.animateEnter,
        exit: options.animateExit
      },
      template: `
              <div data-notify="container" class="bootstrap-notify-container alert-dismissible {0}
        (${options.allow_dismiss} ? 'p-r-35' : '')"
            role="alert" style="box-shadow: 0 3px 6px rgba(0,0,0,0.2);
            margin-bottom: 20px;
            border-top-width: 1px;
            border-right-width: 1px;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-radius: 3px;
        		background: #fff;
            border-radius: 0;
            padding: 15px;
            border: none;
            color: #fff !important;
            color: #333;">
            <button type="button" aria-hidden="true" class="close" data-notify="dismiss"
                    style="color: ${options.fontColor}">×</button>
            <span data-notify="title" >{1}</span>
            <div class="col-sm-12 flex-center m-l--10">
              <div>
                <i class="material-icons pull-left" style="color: ${
                  options.iconColor
                };font-size:xx-large">
                ${options.icon}
                </i>
              </div>
              <div class="col-sm-12">
                <span data-notify="message"  style="font-family: Poppins;color:${
                  options.fontColor
                } ">
                {2} </span>
              </div>
            </div>
            <div class="progress" data-notify="progressbar">
            <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>
            </div>
            <a href="{3}" target="{4}" data-notify="url"></a>
        </div>


      `
    }
  );
};

export const hideSnackBar = () => {
  document.querySelector('.snackbar').classList.remove('active');
};

export const showSnackBar = () => {
  document.getElementsByClassName('snackbar').classList.add('active');
  setTimeout(() => {
    hideSnackBar();
  }, 2000);
};
