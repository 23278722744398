import { schema } from 'normalizr';

// Define a members schema
const memberSchema = new schema.Entity('members');
const entitySchema = new schema.Entity('entities');
const familySchema = new schema.Entity('families');
entitySchema.define({ parent: entitySchema });
const equipmentTypeSchema = new schema.Entity('equipmentTypes');
const controlTypeSchema = new schema.Entity('controlTypes');
const equipmentSchema = new schema.Entity('equipments', {
  type: equipmentTypeSchema
});
const controlSchema = new schema.Entity('controls');

export const Schemas = {
  MEMBER: memberSchema,
  MEMBERS: [memberSchema],
  ENTITY: entitySchema,
  ENTITIES: [entitySchema],
  FAMILY: familySchema,
  FAMILIES: [familySchema],
  EQUIPMENT: equipmentSchema,
  EQUIPMENTS: [equipmentSchema],
  CONTROL_TYPE: controlTypeSchema,
  CONTROL_TYPES: [controlTypeSchema],
  CONTROL: controlSchema,
  CONTROLS: [controlSchema]
};
