import React from 'react';
import PropTypes from 'prop-types';
import Card, { CardBody } from '../shared/Card';
import ColorfulSpinner from '../shared/ColorfulSpinner';
import { t } from '../../services/i18n';
import LocalizedLink from '../shared/LocalizedLink';
import Input from '../shared/Input';
import Button from '../shared/Button';
import { Col, Row } from '../shared/Grid';
import { Formik } from 'formik';
import FormValidation from '../../services/FormValidation';
import { MdPerson, MdLock } from 'react-icons/md';

class SignInForm extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isLoggingIn: PropTypes.bool
  };

  constructor(...args) {
    super(...args);
    this.state = {
      credentials: { email: '', password: '' }
    };
    this.SignInSchema = new FormValidation('SignInForm');
  }

  onChange = ({ target }) => {
    this.setState(prevState => ({
      credentials: { ...prevState.credentials, [target.name]: target.value }
    }));
  };

  get errorMessage() {
    const { message } = this.props;
    if (message) {
      return (
        <div
          className="col-red"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      );
    }
    return null;
  }

  handleSubmit = values => {
    this.props.onSubmit({ ...values, email: values.email.toLowerCase() });
  };

  render() {
    const { isLoggingIn } = this.props;
    const { credentials } = this.state;
    return (
      <Card>
        <CardBody>
          <Formik
            initialValues={credentials}
            validationSchema={this.SignInSchema.getValidationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ handleChange, handleSubmit, values, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <div className="msg align-center">
                  <div style={{ textTransform: 'uppercase' }}>
                    {t('signin_page.title')}
                  </div>
                  {this.errorMessage}
                </div>
                <Input
                  name="email"
                  placeholder={t('signin_page.email_placeholder')}
                  onChange={handleChange}
                  value={values.email}
                  wrapperClass="input-group"
                  autoComplete="username"
                  hasError={
                    errors.password && touched.password && errors.password
                  }
                  message={errors.email && touched.email && errors.email}
                  icon={
                    <span className="input-group-addon">
                      <MdPerson size={24} />
                    </span>
                  }
                  autoFocus
                />
                <Input
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  placeholder={t('signin_page.password_placeholder')}
                  onChange={handleChange}
                  value={values.password}
                  wrapperClass="input-group"
                  hasError={
                    errors.password && touched.password && errors.password
                  }
                  message={
                    errors.password && touched.password && errors.password
                  }
                  icon={
                    <span className="input-group-addon">
                      <MdLock size={24} />
                    </span>
                  }
                />
                <Row>
                  <Col>
                    <Button
                      className="btn-block bg-pink"
                      type="submit"
                      disabled={isLoggingIn}
                    >
                      {t('signin_page.login_btn')}
                    </Button>
                    <p className="align-right m-t-20">
                      <LocalizedLink to={'/reset'}>
                        {t('signin_page.forget_password_link')}
                      </LocalizedLink>
                    </p>
                  </Col>
                </Row>
                {isLoggingIn && (
                  <Row className="text-center m-t-15">
                    <ColorfulSpinner />
                  </Row>
                )}
                <hr />
                <div className="align-center">
                  {t('signin_page.no_account')}
                  <p className="text-center">
                    <LocalizedLink to={'/signup'}>
                      {t('signin_page.signup_link')}
                    </LocalizedLink>
                  </p>
                </div>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
    );
  }
}

export default SignInForm;
