import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import { t } from '../../services/i18n';
export default (state = initialState.controls, action) => {
  switch (action.type) {
    case types.GET_CONTROL_REQUEST:
    case types.FETCH_CONTROL_REQUEST:
      return getControlRequest(state);
    case types.SAVE_CONTROL_REQUEST:
    case types.UPDATE_CONTROL_REQUEST:
      return saveControlRequest(state);
    case types.DELETE_CONTROL_REQUEST:
      return deleteControlRequest(state, action);
    case types.SAVE_CONTROL_SUCCESS:
    case types.UPDATE_CONTROL_SUCCESS:
      return saveControlSuccess(state, action);
    case types.DELETE_CONTROL_SUCCESS:
      return deleteControlSuccess(state, action);
    case types.GET_CONTROL_SUCCESS:
      return getControlSuccess(state, action);
    case types.FETCH_CONTROL_SUCCESS:
      return fetchControlSuccess(state, action);
    case types.GET_CONTROL_FAILURE:
      return getControlFailure(state, action);
    case types.UPDATE_CONTROL_FAILURE:
    case types.DELETE_CONTROL_FAILURE:
    case types.SAVE_CONTROL_FAILURE:
    case types.FETCH_CONTROL_FAILURE:
      return controlFailure(state, action);
    default:
      return state;
  }
};

function saveControlRequest(state) {
  return {
    ...state,
    ...initState(),
    isSaving: true
  };
}

function getControlRequest(state) {
  return {
    ...state,
    ...initState(),
    isLoading: true
  };
}

function controlFailure(state, action) {
  return {
    ...state,
    error: true,
    isLoading: false,
    message: action.message || t('default_error_message')
  };
}

function saveControlSuccess(state, action) {
  return {
    ...state,
    isSaving: false,
    error: false,
    message: action.message || t('default_success_message')
  };
}

function deleteControlRequest(state, action) {
  return {
    ...state,
    ...initState(),
    isDeleting: action.payload
  };
}

function deleteControlSuccess(state, action) {
  delete state.data.normalized[action.payload];
  return {
    ...state,
    isDeleting: false,
    error: false,
    message: action.message || t('default_success_message'),
    data: {
      ...state.data,
      list: state.data.list.filter(eq => eq.id !== action.payload)
    }
  };
}

function getControlSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    error: false,
    data: {
      ...state.data,
      byId: {
        ...state.data.byId,
        [action.payload.result]:
          action.payload.entities.controls[action.payload.result]
      },
      referencedTypes: {
        ...state.data.referencedTypes,
        ...action.payload.entities.equipmentTypes
      }
    },
    message: action.message || t('default_success_message')
  };
}

function fetchControlSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    error: false,
    meta: {
      ...state.meta,
      ...action.meta
    },
    data: {
      ...state.data,
      list: action.payload.byIndexes,
      normalized: action.payload.entities.controls,
      referencedTypes: {
        ...state.data.referencedTypes,
        ...action.payload.entities.equipmentTypes
      }
    },
    message: action.message || t('default_success_message')
  };
}

function getControlFailure(state, action) {
  return {
    ...state,
    isLoading: false,
    error: true,
    message: action.message || t('default_error_message')
  };
}

function initState() {
  return {
    error: false,
    message: null
  };
}
