import React from 'react';
import Localizer from './components/hoc/Localizer';

import { hot } from 'react-hot-loader';
import ErrorBoundary from './components/shared/Boundaries/ErrorBoundary';
import Routes from './Routes';
import { Route } from 'react-router-dom';
import Spinner from './components/shared/Spinner';

class App extends React.PureComponent {
  state = {
    ready: false
  };
  componentDidMount() {
    this.verifyScript = setInterval(() => {
      if (window.scriptLoaded) {
        clearInterval(this.verifyScript);
        this.setState({ ready: true });
      }
    }, 10);
  }

  render() {
    return (
      <ErrorBoundary>
        <Localizer>
          {!this.state.ready ? (
            <Route path="*" component={Spinner} />
          ) : (
            <Routes />
          )}
        </Localizer>
      </ErrorBoundary>
    );
  }
}
export default hot(module)(App);
