import * as Yup from 'yup';
import { VALIDATION_SCHEMAS } from '../constants/validationSchemas';
const _validationSchema = new Map();

export default class FormValidation {
  constructor(schemaKey, isObject = true) {
    this.schemaKey = schemaKey;
    this.schema = VALIDATION_SCHEMAS()[schemaKey];
    this._isObj = isObject;
    this.initSchema(isObject);
  }

  initSchema = isObject => {
    if (_validationSchema.has(this.schema) === false) {
      if (isObject) {
        _validationSchema.set(this.schema, Yup.object().shape(this.schema));
      } else {
        _validationSchema.set(this.schema, this.schema);
      }
    }
  };

  removeAttribute = keys => {
    const validations = VALIDATION_SCHEMAS()[this.schemaKey];

    if (Array.isArray(keys)) {
      keys.forEach(key => {
        delete validations[key];
      });
    } else {
      delete validations[keys];
    }
    this.schema = validations;
    this.initSchema(this._isObj);
  };

  /**
   * @returns {Yup.Schema}
   */
  get getValidationSchema() {
    return _validationSchema.get(this.schema);
  }
}
