import { setToLS } from '../../helpers/localstorage';

export default function localstorageMiddleware() {
  return store => next => action => {
    const withLocalstorage =
      action.meta && action.meta.localstorage && action.meta.key;
    if (withLocalstorage && !action.error) {
      const { key } = action.meta;
      const pathToData = key.split('.');

      let initalState = store.getState();
      pathToData.forEach(objKey => {
        initalState = initalState[objKey];
      });
      const newState = { ...initalState, ...action.payload };
      setToLS(
        action.meta.key,
        JSON.stringify({
          data: newState,
          createdAt: new Date().getTime()
        })
      );
    } else {
      return next(action);
    }
  };
}
