import { getFromLS, removeFromLS } from '../helpers/localstorage';
import CONSTANTS, { LOCAL_STORAGE } from '../constants';
import moment from 'moment';
/**
 *
 * @param {Array} requiredRole
 * @param {Array} myRoles
 *
 * @returns {Boolean}
 */
export const shouldHaveAll = (requiredRole = [], myRoles = []) => {
  return requiredRole.every(role => myRoles.indexOf(role) >= 0);
};

/**
 *
 * @param {Array} requiredRole
 * @param {Array} myRoles
 *
 * @returns {Boolean}
 */
export const shouldHaveOne = (requiredRole = [], myRoles = []) => {
  return requiredRole.some(role => myRoles.indexOf(role) >= 0);
};

/**
 * @returns {Object}
 */
export function getCurrentToken() {
  try {
    return getFromLS(CONSTANTS.LOCAL_STORAGE.AUTH);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

/**
 * @returns {import('./users').IUser}
 */
export function getCurrentUser() {
  try {
    return getFromLS(CONSTANTS.LOCAL_STORAGE.USER);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export function canLoadUserFromLS() {
  const now = moment().format();
  const lastCheck = getFromLS(LOCAL_STORAGE.LAST_GET_ME, false);
  const diffInSeconds = moment(now).diff(lastCheck, 'seconds');
  const thereIsAUser = !!getCurrentUser();
  return diffInSeconds < 30 && thereIsAUser;
}

export function clearLocalstorage() {
  // localStorage.clear();
  removeFromLS(LOCAL_STORAGE.LAST_GET_ME);
  removeFromLS(LOCAL_STORAGE.AUTH);
  removeFromLS(LOCAL_STORAGE.USER);
}
