import { USER_ROLES } from '.';
export const ManageEntitiesRoles = [
  USER_ROLES().OWNER.value,
  USER_ROLES().SUPER_MANAGER.value,
  USER_ROLES().MANAGER_LEVEL_1.value,
  USER_ROLES().MANAGER_LEVEL_2.value
];

export const ManageUsersRoles = [
  USER_ROLES().OWNER.value,
  USER_ROLES().SUPER_MANAGER.value,
  USER_ROLES().MANAGER_LEVEL_1.value,
  USER_ROLES().MANAGER_LEVEL_2.value
];

export const ManageFamiliesRoles = [
  USER_ROLES().OWNER.value,
  USER_ROLES().SUPER_MANAGER.value
];

export const COMPANY_MANAGERS = [
  USER_ROLES().SUPER_MANAGER.value,
  USER_ROLES().OWNER.value
];

export const SIMPLE_USERS = [
  USER_ROLES().GUEST.value,
  USER_ROLES().CONTROLLER.value
];

export const SUPER_ADMINS = [USER_ROLES().SUPER_ADMIN.value];
export const NO_ROLES_REQUIRD = [];
