import { ITEMS_PER_PAGE } from '../constants';
import moment from 'moment';
/**
 * The complete state object shape.
 * @typedef {Object} IInitialState
 *
 * @property {Object} ui - ui stuff .
 * @property {Object} permissions - Permissions by entity
 * @property {Object} shared -
 * @property {Object} invitations -
 * @property {Object} auth -
 * @property {Object} entities -
 * @property {Object} families -
 * @property {Object} users -
 * @property {Object} statuses -
 * @property {Object} equipments -
 */

const media = document.body.clientWidth;
const initialState = {
  ui: {
    sidebar: media > 1240,
    isChecking: true,
    itemPerPage: ITEMS_PER_PAGE
  },
  translations: {
    isLoading: false,
    isSaving: false,
    data: {}
  },
  permissions: {
    isLoading: false,
    error: false,
    data: {
      raw: {},
      byEntities: {
        canSee: {},
        canEdit: {}
      }
    }
  },
  shared: {
    countries: {
      isLoading: false,
      data: null
    }
  },
  invitations: {
    isLoading: false,
    isSaving: false,
    error: false,
    meta: {},
    data: {
      list: []
    },
    message: null
  },
  auth: {
    isLoggingIn: false,
    isGettingMe: false,
    isUpdating: false,
    user: null,
    message: '',
    redirectTo: ''
  },
  entities: {
    isLoading: false,
    isSaving: false,
    error: false,
    data: {
      normalized: {},
      byLevels: {},
      list: [],
      ids: []
    },
    byChildren: {
      list: [],
      flat: [],
      normalized: {},
      ids: []
    }
  },
  families: {
    isLoading: false,
    isSaving: false,
    isFetching: false,
    error: false,
    meta: {},
    data: {
      list: [],
      normalized: {},
      all: [],
      allNormalized: {}
    },
    equipmentTypes: {
      isLoading: false,
      isSaving: false,
      isChecking: false,
      canUseName: null,
      newETName: null,
      error: false,
      data: {
        list: []
      }
    }
  },
  controlTypes: {
    isLoading: false,
    isSaving: false,
    isChecking: false,
    canUseName: null,
    newCTName: null,
    error: false,
    meta: {},
    data: {
      list: [],
      all: [],
      byEquipments: []
    }
  },
  users: {
    isLoading: false,
    isSaving: false,
    error: false,
    isCheckingEmail: false,
    meta: {},
    data: {
      list: [],
      canUseEmail: null
    },
    byId: {
      isLoading: false,
      data: null,
      error: false
    }
  },
  statuses: {
    statuses: {
      isLoading: false,
      isDeleting: false,
      isSaving: false,
      error: false,
      data: {
        list: []
      }
    },
    lists: {
      isLoading: false,
      isDeleting: false,
      isSaving: false,
      error: false,
      data: {
        list: []
      }
    }
  },
  equipments: {
    error: null,
    refresh: null,
    isLoading: false,
    isDeleting: false,
    isSaving: false,
    isChecking: false,
    meta: {},
    data: {
      byId: {},
      list: [],
      normalized: {},
      referencedTypes: {},
      canUse: {
        tagId: null,
        inventoryNumber: null
      }
    }
  },
  controls: {
    error: null,
    isLoading: false,
    isDeleting: false,
    isSaving: false,
    meta: {},
    data: {
      byId: {},
      list: [],
      normalized: {},
      referencedTypes: {}
    }
  },
  dashboard: {
    initialized: false,
    showImportModal: false,
    refreshNotifications: null,
    statuses: {
      isLoading: false,
      refreshChart: null,
      raw: null,
      dataset: undefined
    },
    equipments: {
      isLoading: false,
      refreshChart: null,
      raw: null,
      dataset: undefined
    }
  },
  filters: {
    initials: {
      level1: [],
      level2: [],
      level3: [],
      family: [],
      type: [],
      query: []
    },
    sort: {
      property: 'tagId',
      order: 'DESC'
    }
  },
  barChartFilters: {
    initials: {
      to: moment().format()
    }
  }
};

export default initialState;
