import * as types from '../../constants/actionTypes';
import initialState from '../initialState';
import {
  mapIntoEquipmentsChart,
  mapIntoStatusesChart
} from '../../services/dashboard';
export default (state = initialState.dashboard, { type, payload }) => {
  switch (type) {
    case types.INITIALIZE_FILTERS:
      return {
        ...state,
        initialized: true
      };
    case types.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        refreshNotifications: false
      };
    case types.GET_STATUS_DATA_REQUEST:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          refreshChart: false,
          isLoading: true
        }
      };
    case types.GET_STATUS_DATA_SUCCESS:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: false,
          raw: payload,
          dataset: mapIntoStatusesChart(payload)
        }
      };
    case types.GET_STATUS_DATA_FAILURE:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: false
        }
      };
    case types.GET_EQUIPMENTS_CHART_DATA_REQUEST:
      return {
        ...state,
        equipments: {
          ...state.equipments,
          refreshChart: false,
          isLoading: true
        }
      };
    case types.GET_EQUIPMENTS_CHART_DATA_SUCCESS: {
      return {
        ...state,
        equipments: {
          ...state.equipments,
          isLoading: false,
          raw: payload,
          dataset: mapIntoEquipmentsChart(payload)
        }
      };
    }
    case types.GET_EQUIPMENTS_CHART_DATA_FAILURE:
      return {
        ...state,
        equipments: {
          ...state.equipments,
          isLoading: false
        }
      };
    case types.TOGGLE_ARCHIVE_SUCCESS:
    case types.SAVE_CONTROL_SUCCESS:
    case types.UPDATE_CONTROL_SUCCESS:
    case types.SAVE_EQUIPMENT_SUCCESS:
    case types.UPDATE_EQUIPMENT_SUCCESS:
    case types.DELETE_EQUIPMENT_SUCCESS:
    case types.REFRESH_DASHBOARD:
      return {
        ...state,
        refreshNotifications: true,
        equipments: {
          ...state.equipments,
          refreshChart: true
        },
        statuses: {
          ...state.statuses,
          refreshChart: true
        }
      };
    case types.TOGGLE_IMPORT_MODAL:
      return {
        ...state,
        showImportModal: state.showImportModal ? false : payload.onSuccess,
        importFor: payload.company
      };
    case types.TOGGLE_EXPORT_MODAL:
      return {
        ...state,
        showExportModal: state.showExportModal ? false : payload.onSuccess,
        modalType: payload.exportType
      };

    default:
      return state;
  }
};
