import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { guid, initSelectPicker, areDifferentBy } from '../../helpers';
import ValidationError from './ValidationError';
import '../../assets/plugins/bootstrap-select/css/bootstrap-select.css';

class SelectInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      id: `${props.id || props.name || ''}-${guid()}`
    };
  }

  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    labelClass: PropTypes.string,
    withValidation: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    width: PropTypes.number
  };

  static defaultProps = {
    options: [],
    disabled: false,
    multiple: false,
    required: false,
    withValidation: false,
    wrapperClass: 'form-group',
    labelClass: '',
    withLine: true,
    width: 12
  };

  async componentDidMount() {
    await import('../../assets/plugins/bootstrap-select/js/bootstrap-select');
    this.setState({ ready: true }, this.refresh);
  }

  async componentDidUpdate(prevProps) {
    const { options, value, defaultValue, disabled } = this.props;
    if (
      defaultValue !== prevProps.defaultValue ||
      value !== prevProps.value ||
      disabled !== prevProps.disabled ||
      areDifferentBy(prevProps.options, options, 'value') ||
      areDifferentBy(prevProps.options, options, 'disabled')
    ) {
      await import('../../assets/plugins/bootstrap-select/js/bootstrap-select');

      this.refresh();
    }
  }

  refresh = () => {
    const { value, defaultValue, multiple } = this.props;
    const currValue = defaultValue
      ? defaultValue
      : value
      ? value
      : !multiple
      ? ''
      : [];
    initSelectPicker(`#${this.state.id}`, currValue);
  };

  render() {
    if (!this.state.ready) {
      return null;
    }
    const {
      forwardedRef,
      label,
      options,
      id,
      focused,
      hasError,
      required,
      disabled,
      message,
      hint,
      withValidation,
      wrapperClass,
      withLine,
      isReadOnly,
      allLabel,
      labelClass,
      ...rest
    } = this.props;
    const subWrapperClass = classNames({
      'form-line': withLine,
      error: hasError,
      focused: hasError || focused
    });
    const selectClass = classNames('btn-group form-control show-tick');
    let content = null;
    if (isReadOnly) {
      let readOnlyValue = null;
      if (rest.multiple) {
        const selectedOptions = options
          .filter(option => rest.value.includes(option.value))
          .map(option => option.label);
        readOnlyValue = selectedOptions.length
          ? selectedOptions.join(', ')
          : '-';
      } else {
        const selectedOption = options.find(
          option => option.value === rest.value
        );
        readOnlyValue = selectedOption ? selectedOption.label : '-';
      }

      content = <p>{readOnlyValue}</p>;
    } else {
      content = (
        <select
          {...rest}
          ref={forwardedRef}
          id={this.state.id}
          className={selectClass}
          data-live-search="true"
          required={required}
          disabled={disabled}
        >
          {!required && (
            <option key={-1} value={allLabel}>
              {allLabel}
            </option>
          )}
          {options.map(option => (
            <option
              key={option.key + guid()}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      );
    }

    return (
      <div className={wrapperClass}>
        <div className={subWrapperClass}>
          {label && (
            <label htmlFor={this.state.id} className={labelClass}>
              {label}
            </label>
          )}
          {content}
        </div>
        {message && (
          <label
            id={`${this.state.id}-error`}
            className="error"
            htmlFor={this.state.id}
          >
            {message}.
          </label>
        )}
        {hint && (
          <label
            id={`${this.state.id}-warning`}
            className="warning"
            htmlFor={this.state.id}
          >
            {hint}.
          </label>
        )}
        {withValidation && (
          <label
            id={`${this.state.id}-error`}
            className="error"
            htmlFor={this.state.id}
          >
            <ValidationError name={rest.name} />
          </label>
        )}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <SelectInput {...props} forwardedRef={ref} />;
});
