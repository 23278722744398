import * as types from '../../constants/actionTypes';
import { getAxiosInstance } from '../../helpers/interceptors';
import { BASE_API_URL } from '../../constants';
import { Schemas } from '../../constants/schema';
import { t } from '../../services/i18n';
import has from 'lodash/has';
const axios = getAxiosInstance();

export const newEntity = (entity, parent = null) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SAVE_ENTITY_REQUEST
    });
    return axios
      .post(`${BASE_API_URL}/entity/new${parent ? '/' + parent : ''}`, entity)
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: types.SAVE_ENTITY_SUCCESS,
            payload: response.data
          });
        } else {
          const { message } = response.data;
          dispatch({
            type: types.SAVE_ENTITY_FAILURE,
            message
          });
        }
      })
      .catch(error => {
        console.error(error);
        dispatch({
          type: types.SAVE_ENTITY_FAILURE,
          message:
            error.response &&
            error.response.data &&
            t(error.response.data.content)
        });
      });
  };
};

export const fetchEntities = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.FETCH_ENTITIES_REQUEST
    });
    return axios
      .get(`${BASE_API_URL}/entity/list`)
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: types.FETCH_ENTITIES_SUCCESS,
            payload: response.data,
            meta: {
              schema: Schemas.ENTITIES
            }
          });
        } else {
          const { message } = response.data;
          dispatch({
            type: types.FETCH_ENTITIES_FAILURE,
            message
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        dispatch({
          type: types.FETCH_ENTITIES_FAILURE
        });
      });
  };
};

export const fetchChildrenByEntity = (id, noRedux = false) => {
  if (noRedux === true) {
    return axios
      .get(`${BASE_API_URL}/entity/${id}/getChildren`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log('error', error);
        return [];
      });
  }
  return (dispatch, getState) => {
    if (!id) {
      if (has(getState(), 'auth.user.company.entity')) {
        id = getState().auth.user.company.entity.id;
      } else {
        return Promise.resolve();
      }
    }

    dispatch({
      type: types.FETCH_CHILDREN_REQUEST
    });

    return axios
      .get(`${BASE_API_URL}/entity/${id}/getChildren`)
      .then(response => {
        dispatch({
          type: types.FETCH_CHILDREN_SUCCESS,
          payload: response.data,
          meta: {
            schema: Schemas.ENTITIES
          }
        });
        return response.data;
      })
      .catch(error => {
        console.log('error', error);
        dispatch({
          type: types.FETCH_CHILDREN_FAILURE
        });
      });
  };
};

export const updateEntity = entity => {
  return (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_ENTITY_REQUEST
    });
    return axios
      .put(`${BASE_API_URL}/entity/${entity.id}/update`, entity)
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: types.UPDATE_ENTITY_SUCCESS,
            payload: response.data
          });
        } else {
          const { message } = response.data;
          dispatch({
            type: types.UPDATE_ENTITY_FAILURE,
            message
          });
        }
      })
      .catch(error => {
        console.error(error);
        dispatch({
          type: types.UPDATE_ENTITY_FAILURE,
          message: 'Error'
        });
      });
  };
};

export const deleteEntity = id => {
  return (dispatch, getState) => {
    dispatch({
      type: types.DELETE_ENTITY_REQUEST
    });
    return axios
      .delete(`${BASE_API_URL}/entity/${id}/delete`)
      .then(response => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          dispatch({
            type: types.DELETE_ENTITY_SUCCESS,
            payload: id
          });
        } else {
          const { message } = response.data;
          dispatch({
            type: types.DELETE_ENTITY_FAILURE,
            message
          });
        }
      })
      .catch(error => {
        console.error(error);
        dispatch({
          type: types.DELETE_ENTITY_FAILURE,
          message: error.message
        });
      });
  };
};
