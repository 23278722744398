import { USER_ROLES, LAST_ENTITY_LEVEL, ENTITY_LEVELS } from '../constants';
import { COMPANY_MANAGERS } from '../constants/componentRoles';
import { isCompanyManager } from './users';
import { allExceptRoot } from './entities';

/**
 *
 * @typedef {"OWNER"|"SUPER_MANAGER"|"MANAGER_LEVEL_1"|"MANAGER_LEVEL_2"|"CONTROLLER"|"GUEST"} RoleName
 */

/**
 * Role interface.
 * @typedef {Object} IRole
 *
 * @property {String} id -  .
 * @property {RoleName} name -  .
 * @property {import('./entities').IEntity} entity -  .
 */

/**
 * Definition of a Role interface.
 * @typedef {Object} IRoleDefinition
 *
 * @property {String} label
 * @property {RoleName} value
 * @property {Number} level
 * @property {String} levelLabel
 * @property {Boolean} hidden
 * @property {Boolean} anywhere
 */

/**
 *
 * @param {import('./entities').IEntity[]} rules
 *
 * @returns {Object}
 */
export function normalizePermissions(rules, user) {
  const isSuper = user && isCompanyManager(user.userRoles);
  return rules.reduce(
    (permissions, entity) => {
      permissions.canEdit[entity.id] = isSuper || entity.editable;
      permissions.canSee[entity.id] = isSuper || entity.viewable;
      if (entity.level !== ENTITY_LEVELS.LEVEL_3.value) {
        const myChildren = rules.filter(
          ent => ent.viewable && ent.path.includes(entity.id)
        );
        permissions.canSelect[entity.id] = myChildren.some(ent => ent.editable);
      } else {
        permissions.canSelect[entity.id] = permissions.canEdit[entity.id];
      }
      return permissions;
    },
    {
      canSee: {},
      canEdit: {},
      canSelect: {}
    }
  );
}

/**
 *
 * @param {"canSee"|"canEdit"|"canSelect"} rule
 * @param {import('./entities').IEntity} entity
 * @param {Object} permissions
 */
export function canI(rule, entity, permissions) {
  return (
    !entity || // in case of creating new role that doesnt any entity yet
    (entity && entity.id && permissions.byEntities[rule][entity.id] === true)
  );
}

/**
 * return all roles except OWNER AND SUPER MANAGER
 * @param {IRole} role
 *
 * @returns {Boolean}
 */
export const filterCompanyManagers = role =>
  !COMPANY_MANAGERS.includes(role.name);

/**
 *
 * @param {RoleName} value
 *
 * @returns {IRoleDefinition}
 */
export function getRoleDefinitation(value) {
  return USER_ROLES()[value];
}

/**
 *
 * @param {IRole[]} userRoles
 */
export function getHighestRoleLevel(userRoles = []) {
  return userRoles.reduce((acc, currRole) => {
    const roleDef = getRoleDefinitation(currRole.name);
    if (roleDef.level < acc) {
      return roleDef.level;
    } else {
      return acc;
    }
  }, LAST_ENTITY_LEVEL);
}

// export function getTargetEntityByRole(role) {}

export function getLastSelectedEntity(role) {
  let id = null;
  Object.values(ENTITY_LEVELS)
    .filter(allExceptRoot)
    .some(entityLevel => {
      if (
        role[entityLevel.label] && // current is selected
        !role[entityLevel.label + 1] // next in empty
      ) {
        id = role[entityLevel.label];
        return true;
      }
      return false;
    });
  return id;
}

export function hasNotSelectedEntityYet(role) {
  return Object.values(ENTITY_LEVELS)
    .filter(allExceptRoot)
    .every(entityLevel => {
      return !role[entityLevel.label];
    });
}
