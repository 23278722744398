export function setToLS(key, data, stringify = true) {
  try {
    const serializedState = stringify === true ? JSON.stringify(data) : data;
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Ignore write errors.
    localStorage.clear();
  }
}

export function removeFromLS(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log('Error Removing from local storage');
  }
}

export function getFromLS(key, parse = true) {
  try {
    const data = localStorage.getItem(key);
    if (data === null) {
      return undefined;
    }
    if (parse) {
      return JSON.parse(data);
    }
    return data;
  } catch (err) {
    localStorage.clear();

    return undefined;
  }
}
