import { domNodesToArray } from '.';

/**
 * @param {(HTMLInputElement|HTMLSelectElement)} target
 */
export function getFormValue(target, multipleFile = true) {
  return target.type === 'checkbox'
    ? target.checked
    : target.files
    ? multipleFile
      ? target.files
      : target.files[0]
    : target.selectedOptions && target.multiple
    ? domNodesToArray(target.selectedOptions)
    : target.value;
}
