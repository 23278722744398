import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ui from './ui/reducer';
import auth from './auth/reducer';
import entities from './entities/reducer';
import families from './families/reducer';
import invitations from './invitations/reducer';
import users from './users/reducer';
import shared from './shared/reducer';
import permissions from './permissions/reducer';
import statuses from './statuses/reducer';
import translations from './translations/reducer';
import equipments from './equipments/reducer';
import controlTypes from './controlType/reducer';
import dashboard from './dashboard/reducer';
import filters from './filters/reducer';
import controls from './controls/reducer';
import barChartFilters from './barChartFilters/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    ui,
    entities,
    families,
    users,
    invitations,
    shared,
    permissions,
    statuses,
    translations,
    equipments,
    controlTypes,
    dashboard,
    controls,
    filters,
    barChartFilters,
  });
