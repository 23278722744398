import { cleanObject, getFirstBrowserLanguage } from '../helpers';
import { USER_ROLES } from '../constants';
import { toUserShape } from './invitations';
import { filterCompanyManagers } from './userAcl';

/**
 * Company interface.
 * @typedef {Object} ICompany
 *
 * @property {String} id -
 * @property {String} name -
 * @property {Number} equipmentsLimit -
 * @property {Boolean} hasFamilies -
 * @property {Boolean} hasEquipmentTypes -
 * @property {Boolean} hasControlTypes -
 * @property {StrBooleaning} isEnabled -
 * @property {Boolean} isEnabledArchive -
 * @property {Boolean} isEnabledDataExport -
 * @property {Boolean} isEnabledMultiSelection -
 * @property {Boolean} isEnabledPDFExport -
 * @property {import('./entities').IEntity} entity -
 * @property {Array} statusLists -
 * @property {Array} statuses -
 */

/**
 * User interface.
 * @typedef {Object} IUser
 *
 * @property {String} id -  .
 * @property {String} firstName -
 * @property {String} lastName -
 * @property {Array} userRoles -
 * @property {Array} families -
 * @property {String} email -
 * @property {String} language -
 * @property {Boolean} active -
 */

/**
 * Invitation interface.
 * @typedef {Object} IInvitation
 *
 * @property {String} id -  .
 * @property {String} firstName -
 * @property {String} lastName -
 * @property {Array} roles -
 * @property {Array} families -
 * @property {String} email -
 */

export function createNewUser(user, inInviting = true) {
  const cleanUser = cleanObject(
    {
      ...user,
      language:
        user.language === 'auto' ? getFirstBrowserLanguage() : user.language,
      username: user.email,
      userRoles: inInviting ? user.roles : user.userRoles,
      initialized: true,
      active: true
    },
    ['company', 'oldPassword', inInviting && 'id', 'roles']
  );
  if (!inInviting) {
    return cleanUser;
  }
  const body = {
    invitation: {
      id: user.id
    },
    user: cleanUser
  };
  return body;
}

export function createNewOwner(user, captachaToken) {
  return {
    company: { name: user.companyName },
    user: {
      ...user,
      username: user.email,
      active: true,
      userRoles: [{ name: USER_ROLES().OWNER.value }]
    },
    recaptchaResponse: captachaToken
  };
}

export function getRoleLabelByValue(value) {
  try {
    return Object.values(USER_ROLES()).find(role => role.value === value).label;
  } catch (error) {
    return null;
  }
}

/**
 *
 * @param {import('./userAcl').IRole[]} userRoles
 * @param {import('./userAcl').RoleName} expectedRole
 *
 * @returns {Boolean}
 */
export function hasRole(userRoles = [], expectedRole) {
  return userRoles && userRoles.some(role => role.name === expectedRole);
}

/**
 *
 * @param {import('./userAcl').IRole[]} userRoles
 *
 * @returns {Boolean}
 */
export function isCompanyManager(userRoles = []) {
  return hasRole(userRoles, 'SUPER_MANAGER') || hasRole(userRoles, 'OWNER');
}

/**
 *
 * @param {IUser} user
 * @param {import('./userAcl').IRole[]} roles
 * @param {Object} company
 *
 * @returns {IUser}
 */
export function demoteUser(user, roles, company) {
  const newRoles = toUserShape(roles, company).filter(filterCompanyManagers);
  return {
    id: user.id,
    userRoles: newRoles
  };
}
