import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchFamilies } from '../../modules/families/action';
import { fetchChildrenByEntity } from '../../modules/entities/action';

export default function WithFamiliesAndEntities(WrappedComponent) {
  class WithFamiliesAndEntitiesHOC extends PureComponent {
    static displayName = `WithFamiliesAndEntities(${WrappedComponent.displayName ||
      WrappedComponent.name})`;
    state = { done: false };
    componentDidMount = async () => {
      await this.props.fetchFamilies();
      await this.props.fetchChildrenByEntity();
      this.setState({ done: true });
    };

    render() {
      const { done } = this.state;
      if (!done) {
        return null;
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    null,
    { fetchFamilies, fetchChildrenByEntity }
  )(WithFamiliesAndEntitiesHOC);
}
