import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from '../../modules/auth/action';
// // import { getQueryStringFromUrl } from '../helpers/index';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';
import AuthLayout from './AuthLayout';
// import { showNotification } from '../../helpers/notification';
import { LocalizerContext } from '../../components/hoc/Localizer';

class ResetPassword extends React.Component {
  static contextType = LocalizerContext;

  state = {
    isRedirecting: false
  };

  onSubmit = credentials => {
    this.props.resetPassword(credentials).then(this.handleOnResolve);
  };

  toggleLoader = () => {
    this.setState((state, props) => {
      return { isRedirecting: !state.isRedirecting };
    });
  };

  handleOnResolve = () => {
    const { auth } = this.props;
    if (!auth.error) {
      // showNotification(auth.message, { error: auth.error });
      const locale = this.context;
      this.toggleLoader();
      setTimeout(() => {
        this.toggleLoader();
        this.props.history.push(locale.localizePath('/login'));
      }, 5500);
    }
  };

  render() {
    const { auth } = this.props;
    const { isRedirecting } = this.state;
    return (
      <AuthLayout>
        <ResetPasswordForm
          onSubmit={this.onSubmit}
          message={auth.message}
          hasError={auth.error}
          isSubmitting={auth.isUpdating || isRedirecting}
        />
      </AuthLayout>
    );
  }
}

ResetPassword.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ resetPassword }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
