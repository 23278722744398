import { cleanObject, isFile, isNilValue } from '../helpers';
import { FIELD_TYPES } from '../constants/formBuilder';
import deepmerge from 'deepmerge';
import {
  FILE,
  FILE_TYPES,
  TEXT_INPUT,
  RADIO_BOX,
  TEXTAREA,
  CHECKBOX,
  SELECT_INPUT,
  MULTI_CHECKBOX,
  DATE_INPUT
} from '../components/builder/builderUtils';
import moment from 'moment';
import FormFieldError from './FormFieldError';

/**
 * IFormDefinition interface.
 * @typedef {Object} IFormDefinition
 *
 * @property {String} id -  .
 * @property {String} label -
 * @property {String} type -
 * @property {Boolean} required -
 * @property {Boolean} multiple -
 * @property {Array} options -
 */

/**
 * IFormValue interface.
 * @typedef {Object} IFormValue
 *
 * @property {String} id -  .
 * @property {String} singleValue -
 * @property {String} multiValue -
 * @property {Boolean} fileValue -
 * @property {IFormDefinition} template -
 */

/**
 *
 * @param {Array} rawSchema
 *
 * @returns {Array}
 */
export function createFormDefinition(rawSchema) {
  return rawSchema.reduce((formSchema, rawField, index) => {
    const formFieldDefinition = createFormFieldDefinition(rawField.uiSchema);
    formSchema.push({ ...formFieldDefinition, order: index });
    return formSchema;
  }, []);
}

function createFormFieldDefinition(uiSchema) {
  const FormFieldDefinition = cleanObject(uiSchema, [
    'fieldClassname',
    'error',
    'defaultValue',
    'placeholder',
    'validator',
    'uniqueItems',
    'metered'
  ]);
  if (FormFieldDefinition.type === FILE) {
    const acceptedExtention = FILE_TYPES().find(
      type => type.id === FormFieldDefinition.fileType
    );
    FormFieldDefinition.accept = acceptedExtention.value.join(',');
  }
  return FormFieldDefinition;
}

export function resolveBuilderSchema(formDefinitions) {
  return formDefinitions.reduce((builderSchema, definition) => {
    const formFieldDefinition = createBuilderFieldFromDefinition(definition);
    builderSchema.push(formFieldDefinition);
    return builderSchema;
  }, []);
}

function createBuilderFieldFromDefinition(definition) {
  const BASE_FIELD = FIELD_TYPES()[definition.type];
  delete BASE_FIELD.uiSchema.options;
  return deepmerge(BASE_FIELD, {
    fieldKey: definition.id,
    uiSchema: definition,
    isOld: true
  });
}

/**
 *
 * @param {IFormDefinition[]} formDefinitions
 *
 * @returns {Object}
 */
export function buildFormSchema(formDefinitions) {
  //i need to merge form definition with uischema
  return formDefinitions.reduce((formSchema, formDef) => {
    const BASE_FIELD = FIELD_TYPES()[formDef.type];
    delete BASE_FIELD.uiSchema.options;
    formSchema[formDef.id] = deepmerge(BASE_FIELD.uiSchema, formDef);
    // delete formSchema[fieldSlug].validator;
    return formSchema;
  }, {});
}

/**
 *
 * @param {Array} formValues
 * @param {Boolean} isReadOnly
 */
export function buildFormSchemaWithValues(formValues, isReadOnly = false) {
  // i need to merge form definition with uischema
  return formValues
    .sort((a, b) => a.template.order - b.template.order)
    .reduce((formSchema, formVal) => {
      const BASE_FIELD = FIELD_TYPES()[formVal.template.type];
      delete BASE_FIELD.uiSchema.options;
      const value = getDefaultValueByType(formVal);
      formSchema[formVal.template.id] = Object.assign(
        deepmerge(BASE_FIELD.uiSchema, formVal.template),
        {
          isReadOnly,
          defaultValue: value
        }
      );
      return formSchema;
    }, {});
}

/**
 *
 * @param {IFormValue} formVal
 */
function getDefaultValueByType(formVal) {
  switch (formVal.template.type) {
    case TEXT_INPUT:
    case RADIO_BOX:
    case TEXTAREA:
    case CHECKBOX:
      return isNilValue(formVal.singleValue) ? '' : formVal.singleValue;
    case MULTI_CHECKBOX:
      return isNilValue(formVal.multiValue) ? [] : formVal.multiValue;
    case SELECT_INPUT:
      if (!formVal.template.multiple) {
        return isNilValue(formVal.singleValue) ? '' : formVal.singleValue;
      } else {
        return isNilValue(formVal.multiValue) ? [] : formVal.multiValue;
      }
    case DATE_INPUT:
      return formVal.singleValue ? moment(formVal.singleValue) : null;
    case FILE:
      return formVal.fileValue;
    default:
      return formVal.singleValue;
  }
}

export function initializeFormValues(schema, initialValues = {}) {
  return Object.keys(schema).reduce((acc, curr) => {
    acc[curr] = schema[curr].defaultValue;
    return acc;
  }, initialValues);
}

export function createBuilderErrors(errors) {
  return errors.inner.reduce((acc, currentError) => {
    const path = currentError.path.split('.');
    if (path.length > 1) {
      path.splice(1, 0, 'uiSchema');
    }
    acc[path.join('.')] = currentError.message;
    return acc;
  }, {});
}

export function resolveFormFieldValues(templates, custom, upload) {
  return templates.map(async ({ id: FFDid, label }) => {
    let value = custom[FFDid];
    if (typeof value === 'undefined') {
      return {
        template: { id: FFDid }
      };
    }
    const valueType = getFieldValueType(custom, FFDid);
    if (isNewFileValueNeededToBeUploaded(valueType, custom, FFDid)) {
      value = await upload(custom[FFDid].file);
      if (value === null) {
        throw new FormFieldError(label, 'Big Files');
      }
    }
    return {
      template: { id: FFDid },
      [valueType]: value
    };
  });
}

function getFieldValueType(custom, FFDid) {
  return Array.isArray(custom[FFDid])
    ? 'multiValue'
    : typeof custom[FFDid] === 'object' && !moment.isMoment(custom[FFDid])
    ? 'fileValue'
    : 'singleValue';
}

function isNewFileValueNeededToBeUploaded(valueType, custom, FFDid) {
  return (
    valueType === 'fileValue' &&
    custom[FFDid] &&
    custom[FFDid].file &&
    isFile(custom[FFDid].file)
  );
}

export function mergeValuesWithTemplates(values, templates) {
  const exisitngTemplates = values.map(value => value.template.id);
  const finalValues = [...values];
  templates.forEach(template => {
    if (!exisitngTemplates.includes(template.id)) {
      finalValues.push({ template });
    }
  });
  return finalValues;
}
