import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

export default function withCurrentUser(WrappedComponent) {
  class WithCurrentUserHOC extends PureComponent {
    static displayName = `WithCurrentUser(${WrappedComponent.displayName ||
      WrappedComponent.name})`;

    render() {
      const { authUser } = this.props;
      if (!authUser) {
        return null;
      }
      return <WrappedComponent {...this.props} currentUser={authUser} />;
    }
  }
  const mapStateToProps = state => ({ authUser: state.auth.user });

  return connect(
    mapStateToProps,
    null
  )(WithCurrentUserHOC);
}
